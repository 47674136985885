import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { SignTypeService } from '../../../services/sign-control/sign-type.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SignTypeInfo, ReqAddSignType } from 'src/app/models/sign-control/sign-type';
import { permission } from 'src/app/views/sign-control/_menu';
@Component({
  selector: 'app-sign-type',
  templateUrl: './sign-type.component.html',
  styleUrls: ['./sign-type.component.scss']
})
export class SignTypeComponent implements OnInit {
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;

  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: SignTypeInfo[] = [];


  is_loading: boolean = false;
  allowCustom: boolean = false;
  res_message: any;

  sign_type_list = [
    { id: 1, name: "ATIS" },
    { id: 2, name: "LMS" }
  ];

  ratio_list:string[] = [];
  data_ratio:string[] = [];

  search_sign_type: any;
  search_ratio: any;

  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status: any;
  selectItem: SignTypeInfo = {
    name: "",
    width: null,
    height: null,
    pit : null
  };

  status_modal: any;
  search_company_name: any;
  search_hashtag: any;

  isAdd:boolean;
  isEdit:boolean;
  constructor(private commonService: CommonService, private signTypeService: SignTypeService) { }

  async ngOnInit() {
    this.is_loading = true;
    this.getSignType();
    this.getRatio();
    this.isAdd = await this.commonService.getPermission(permission.sign_type.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.sign_type.edit_id).toPromise();
  }

  handleValueSignType(value) {
    if (value) {
      this.search_sign_type = value;
    } else {
      this.search_sign_type = null;
    }
  }

  getRatio() {
    this.signTypeService.getSignTypeRatio().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.ratio_list = _.cloneDeep(res.data);
              this.data_ratio = _.cloneDeep(res.data);
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }


  handleFilterRatio(value) {
    if (value.length > 0) {
      this.data_ratio = this.ratio_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_ratio = _.cloneDeep(this.ratio_list);
    } else {
      this.data_ratio = [];
    }
  }
  
  handleValueRatio(value) {
    if (value) {
      this.search_ratio = value;
    } else {
      this.search_ratio = null;
    }
  }

  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getSignType();
    }
  }

  search() {
    this.currentPage = 1;
    this.getSignType();
  }


  getSignType() {
    this.data_list = [];
    let ratio = null;
    if (this.search_ratio)
      ratio = this.search_ratio;
    this.signTypeService.getSignType(this.currentPage, this.itemsPerPage, null, this.search_sign_type, ratio).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = new SignTypeInfo();
    this.selectItem.width = null;
    this.selectItem.height = null;
    this.selectItem.pit = null;
    this.selectItem.name = "";
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModal.show();
  }
  openEditModal(item?: any) {
    console.log(item);
    
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new ReqAddSignType();
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  addSignType() {
    this.is_loading = true;
    let req = new ReqAddSignType();
    req.name = this.selectItem.name;
    req.width = parseInt(this.selectItem.width.toString());
    req.aspect_ratio = this.btAspectRatio;
    req.height = parseInt(this.selectItem.height.toString());
    req.pit = parseInt(this.selectItem.pit.toString());
    req.status = this.status_modal.id;
    this.res_message = "";
    this.signTypeService.addSignType(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มชนิดป้ายสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSignType();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editSignType() {
    this.is_loading = true;
    let req = new ReqAddSignType();
    req.name = this.selectItem.name;
    req.width = parseInt(this.selectItem.width.toString());
    req.aspect_ratio = this.btAspectRatio;
    req.height = parseInt(this.selectItem.height.toString());
    req.pit = parseInt(this.selectItem.pit.toString());
    req.status = this.status_modal.id;
    this.res_message = "";
    console.log(req);
    
    this.signTypeService.editSignType(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไขชนิดป้ายสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSignType();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteSignType() {
    this.is_loading = true;
    let req = new ReqAddSignType();
    req.name = this.selectItem.name;
    req.width = this.selectItem.width;
    req.height = this.selectItem.height;
    req.pit = this.selectItem.pit;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.signTypeService.deleteSignTypeEditStatus(req, this.selectItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบชนิดป้ายสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSignType();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  get btAspectRatio() {
    if (this.selectItem.height && this.selectItem.width) {
      let w = +this.selectItem.width;
      let h = +this.selectItem.height;
      let r = this.gcd(w, h);
      const ratio = `${w / r}:${h / r}`;
      return ratio;
    } else {
      return null;
    }
  }
  gcd(a, b) {
    if (b == 0) {
      return a
    } else {
      return this.gcd(b, a % b)
    }
  }
}
