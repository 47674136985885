export const traffic_url = "traffictool";
export const id = 1100;
// Mockup id,Nodata in Doc
export const menuItems = [
    {
        id: 1101,
        name: 'Smart Micro',
        url: `/${traffic_url}/smartmicro`,
        icon: 'fa fa-sun-o',
        parent_id: id
    },
    {
        id: 1104,
        name: 'Reversible',
        url: `/${traffic_url}/reversible`,
        icon: 'icon-camera',
        parent_id: id
    },
    // {
    //     id: 1107,
    //     name: 'CCTV',
    //     url: `/${traffic_url}/cctv`,
    //     icon: 'icon-camera',
    //     parent_id: id
    // },
    // {
    //     id: 1108,
    //     name: 'Speed enforcement',
    //     url: `/${traffic_url}/speed`,
    //     icon: 'icon-speedometer',
    //     parent_id: id
    // },{
    //     id: 1109,
    //     name: 'Report Traveltime',
    //     url: `/${traffic_url}/traveltime`,
    //     icon: 'fa fa-road',
    //     parent_id: id
    // },{ 
    //     id: 1110,
    //     name: 'ตรวจจับความเร็ว/ สำรวจข้อมูลสภาพอากาศ',
    //     url: `/${traffic_url}/weather-station`,
    //     icon: 'fa fa-cloud',
    //     parent_id: id
    // },{
    //     id: 1111,
    //     name: 'จัดการสี่แยกจราจร',
    //     url: `/${traffic_url}/traffic-signal`,
    //     icon: 'fa fa-plus',
    //     parent_id: id
    // },
];
export const permission = {
    smartmicro: {
        add_id: 1102,
        edit_id: 1103,
    },
    reversible: {
        add_id: 1105,
        edit_id: 1106,
    },
};