import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Station, ReqAddStation } from '../../models/assets-management/station';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StationService {
  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_assets;
  }
  getStation( page?: number, limit?: number, short?:number, route?: string, st_km?: number, en_km?: number, st_m?: number,en_m?: number,
    status?:number, station_id?:number, owner_id?:number, vender_id?:number, device_group_id?:number, device_type_id?:number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(route)) {
      params = params.append('route', route.toString());
    }
    if (!_.isNil(st_km)) {
      params = params.append('st_km', st_km.toString());
    }
    if (!_.isNil(en_km)) {
      params = params.append('en_km', en_km.toString());
    }
    if (!_.isNil(st_m)) {
      params = params.append('st_m', st_m.toString());
    }
    if (!_.isNil(en_m)) {
      params = params.append('en_m', en_m.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(station_id)) {
      params = params.append('station_id', station_id.toString());
    }
    if (!_.isNil(owner_id)) {
      params = params.append('owner_id', owner_id.toString());
    }
    if (!_.isNil(vender_id)) {
      params = params.append('vender_id', vender_id.toString());
    }
    if (!_.isNil(device_group_id)) {
      params = params.append('device_group_id', device_group_id.toString());
    }
    if (!_.isNil(device_type_id)) {
      params = params.append('device_type_id', device_type_id.toString());
    }

    const uri = `station`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<Station[]>>>(url, httpOptions);
    //let url = 'assets/data/device_group.json';
    //return this.http.get<ApiResponse<PagableData<DeviceGroup[]>>>(url);
  }
  getStationById(station_id?: number) {
    const uri = `station/${station_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<Station[]>>(url, httpOptions);
  }
  addStation(req: ReqAddStation): Observable<ApiResponse<any>> {
    const uri = `station`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editStation(req: ReqAddStation, station_id?: number): Observable<ApiResponse<any>> {
    const uri = `station/${station_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteStation(station_id: number): Observable<ApiResponse<any>> {
    const uri = `station/${station_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteStationEditStatus(req: ReqAddStation, station_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `station/${station_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}