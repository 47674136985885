import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-traveltime',
  templateUrl: './traveltime.component.html',
  styleUrls: ['./traveltime.component.scss']
})
export class TraveltimeComponent implements OnInit {
  url: any;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    const token = this.authService.user_token;
    const username = this.authService.user_name;
    if (username && token) {
      this.url = `http://www.ntdcloud.com/traveltime/?client_id=${username}&token=${token}&from_web=${environment.from_web}`;
      // const url1  = `http://www.ntdcloud.com/speeddetect/?client_id=${username}&token=${token}`;
      // this.url = url1 + "&output=embed";
      // window.parent.location.replace(this.url);
    }
    console.log(token);
    console.log(username);
    console.log(this.url);
  }

}
