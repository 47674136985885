import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { DeviceTypeService } from '../../../services/assets-management/device-type.service';
import { DeviceType, ReqAddDeviceType, DeviceSubType, ReqAddDeviceSubType } from '../../../models/assets-management/device-type';
import { StatusEnum, VendorContactTypeEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Img } from 'src/app/models/common';
import { permission } from 'src/app/views/assets-management/_menu';
@Component({
  selector: 'app-device-type',
  templateUrl: './device-type.component.html',
  styleUrls: ['./device-type.component.scss']
})
export class DeviceTypeComponent implements OnInit {
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('addModalSub', { static: false }) public addModalSub: ModalDirective;
  @ViewChild('deleteModalSub', { static: false }) public deleteModalSub: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  view: number = 1; //1= device type,2=device sub type
  statusEnum = StatusEnum;
  vendorContactTypeEnum = VendorContactTypeEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  totalSubItems: any = 0;
  data_list: DeviceType[];
  data_sub_list: DeviceSubType[];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status: any;
  selectItem: any = {
    type_id: null,
    name: "",
    desc: "",
    status: null
  };

  public allowCustom: boolean = false;
  res_message: any;
  status_modal: any;
  status_modal_sub: any;
  search_device_type: any;

  selectSubItem: any = {
    subtype_id: null,
    name: "",
    desc: "",
    code: "",
    status: null,
    type_id: null
  };

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private deviceTypeService: DeviceTypeService) { }

  async ngOnInit(){
    this.is_loading = true;
    this.getDeviceType();
    this.isAdd = await this.commonService.getPermission(permission.device_type.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.device_type.edit_id).toPromise();
  }

  handleValueStatus(value) {
    if (value) {
      //console.log(value);
      this.status = value;
    } else {
      this.status = null;
    }
  }
  is_loading: boolean = false;

  changeView(view_type?: number, item?: DeviceType) {
    if (this.view != view_type) {
      this.view = view_type;
      if (view_type == 1) {
        this.getDeviceType();
        this.data_sub_list = [];
      } else {
        this.selectItem = item;
        this.status_modal = this.status_list.find(x => x.id == item.status);
        this.getDeviceSubType(this.selectItem.type_id);
      }
    }

  }
  getDeviceType() {
    this.data_list = [];
    let sta = null;
    if (this.status)
      sta = this.status.id;
    this.deviceTypeService.getDeviceType(this.search_device_type, sta, this.currentPage, this.itemsPerPage).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }

  getDeviceTypeById(id) {
    this.data_list = [];
    this.deviceTypeService.getDeviceTypeById(id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.selectItem = res.data;
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  openAddModal() {
    this.selectItem = new DeviceType();
    this.selectItem.brand_name = "";
    this.selectItem.status = null;
    this.status_modal = null;
    this.addModal.show();
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModal.show();
  }
  openEditModal(item?: any) {
    this.selectItem = item;
    console.log(item);

    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.addModal.show();
  }
  openDeleteModal(item?: any) {
    this.selectItem = item;
    this.deleteModal.show();
  }
  clearData() {
    if (this.view == 1) {
      this.selectItem = new ReqAddDeviceType();
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      //console.log(value);
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  pageChanged(event: any): void {
    this.currentPage = event.page;
    this.getDeviceType();
  }
  search() {
    this.currentPage = 1;
    this.getDeviceType();
  }
  addDeviceType() {
    this.is_loading = true;
    let req = new ReqAddDeviceType();
    req.name = this.selectItem.name;
    req.desc = this.selectItem.desc;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.deviceTypeService.addDeviceType(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่ม ประเภทอุปกรณ์ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDeviceType();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editDeviceType() {
    this.is_loading = true;
    let req = new ReqAddDeviceType();
    req.name = this.selectItem.name;
    req.desc = this.selectItem.desc;
    req.status = this.status_modal.id;
    this.res_message = "";
    this.deviceTypeService.editDeviceType(req, this.selectItem.type_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไข ประเภทอุปกรณ์ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDeviceType();
              if (this.view == 2) {
                this.getDeviceTypeById(this.selectItem.type_id);
              }
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteDeviceType() {
    this.is_loading = true;
    let req = new ReqAddDeviceType();
    req.name = this.selectItem.name;
    req.desc = this.selectItem.desc;
    this.res_message = "";
    this.deviceTypeService.deleteDeviceTypeEditStatus(req, this.selectItem.type_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบ ประเภทอุปกรณ์ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDeviceType();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  //------------------------------------------------------------//

  handleValueStatusModalSub(value) {
    if (value) {
      //console.log(value);
      this.status_modal_sub = value;
    } else {
      this.status_modal_sub = null;
    }
  }

  getDeviceSubType(device_type_id) {
    this.data_sub_list = [];
    this.totalSubItems = 0;
    this.deviceTypeService.getDeviceSubType(null, null, null, device_type_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_sub_list = res.data.data;
              this.totalSubItems = res.data.data.length;
            }
          }
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  openAddModalSub() {
    this.selectSubItem = new DeviceSubType();
    this.selectSubItem.name = "";
    this.selectSubItem.desc = "";
    this.selectSubItem.code = "";
    this.selectSubItem.status = null;
    this.sub_type_images = [];
    this.status_modal_sub = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addModalSub.show();
  }
  openEditModalSub(item?: DeviceSubType) {
    this.selectSubItem = item;
    this.sub_type_images = [];
    if (this.selectSubItem.image) {
      let im = new Img();
      im.delete = false;
      im.url = this.selectSubItem.image;
      this.sub_type_images.push(im);
    }
    this.status_modal_sub = this.status_list.find(x => x.id == item.status);
    this.addModalSub.show();
  }
  openDeleteModalSub(item?: any) {
    this.selectSubItem = item;
    this.deleteModalSub.show();
  }
  clearDataSub() {
    this.selectSubItem = new ReqAddDeviceSubType();
  }

  addDeviceSubType(url?: string) {
    this.is_loading = true;
    let req = new ReqAddDeviceSubType();
    req.name = this.selectSubItem.name;
    req.desc = this.selectSubItem.desc;
    req.code = this.selectSubItem.code;
    req.status = this.status_modal_sub.id;
    if (url) {
      req.image = url;
    }
    this.res_message = "";
    this.deviceTypeService.addDeviceSubType(req, this.selectItem.type_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่ม ประเภทย่อยอุปกรณ์ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDeviceSubType(this.selectItem.type_id);
            }, 100);
            this.addModalSub.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editDeviceSubType(url?: string) {
    this.is_loading = true;
    let req = new ReqAddDeviceSubType();
    req.name = this.selectSubItem.name;
    req.desc = this.selectSubItem.desc;
    req.code = this.selectSubItem.code;
    req.status = this.status_modal_sub.id;
    if (url) {
      req.image = url;
    }
    this.res_message = "";
    this.deviceTypeService.editDeviceSubType(req, this.selectSubItem.subtype_id, this.selectItem.type_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไข ประเภทย่อยอุปกรณ์ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDeviceSubType(this.selectItem.type_id);
            }, 100);
            this.addModalSub.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteDeviceSubType() {
    this.is_loading = true;
    let req = new ReqAddDeviceSubType();
    req.name = this.selectSubItem.name;
    req.desc = this.selectSubItem.desc;
    req.code = this.selectSubItem.code;
    this.res_message = "";
    this.deviceTypeService.deleteDeviceSubTypeEditStatus(req, this.selectSubItem.subtype_id, this.selectItem.type_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบ ประเภทย่อยอุปกรณ์ สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDeviceSubType(this.selectItem.type_id);
            }, 100);
            this.deleteModalSub.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  bucket_name = 'asset';
  path = 'device/type';
  async addDeviceSubTypeAsyn() {
    let add_img = this.sub_type_images.find(x => x.delete != true && x.file != null && x.dataurl != null);
    let url = null;
    if (add_img) {
      let imgs = await this.commonService.uploadImages([add_img.file], this.bucket_name, this.path).toPromise();
      if (imgs) {
        console.log(imgs);

        if (imgs.data) {
          this.addDeviceSubType(imgs.data.img_urls[0]);
        } else {
          this.addDeviceSubType();
        }
      } else {
        this.addDeviceSubType();
      }
    } else {
      this.addDeviceSubType();
    }
  }

  async editDeviceSubTypeAsyn() {
    let add_img = this.sub_type_images.find(x => x.delete != true && x.file != null && x.dataurl != null);
    let url = "";
    let delete_img = this.sub_type_images.find(x => x.delete == true && x.url != null);
    if (delete_img) {
      let del = await this.commonService.deleteImage(delete_img.url).toPromise();
    }
    let url1 = this.sub_type_images.find(x => x.delete != true && x.url != null);
    if(url1){
      url = url1.url;
    }
    if (add_img) {
      let imgs = await this.commonService.uploadImages([add_img.file], this.bucket_name, this.path).toPromise();
      if (imgs) {
        if (imgs.data) {
          this.editDeviceSubType(imgs.data.img_urls[0]);
        } else {
          this.editDeviceSubType(url);
        }
      } else {
        this.editDeviceSubType(url);
      }
    } else {
      this.editDeviceSubType(url);
    }
  }

  sub_type_images: Img[] = [];
  deleteImageFiles(item: any) {
    const index: number = this.sub_type_images.indexOf(item);
    if (item.url) {
      item.delete = true;
    } else {
      if (index !== -1) {
        this.sub_type_images.splice(index, 1);
      }
    }
  }
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if(acceptedType.includes(file.type)){
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let im = new Img();
          im.file = file;
          im.dataurl = e.target.result;
          if (this.sub_type_images.length > 0) {
            let delete_img = this.sub_type_images.find(x => x.url != null);
            if (delete_img) {
              const index_del: number = this.sub_type_images.indexOf(delete_img);
              this.sub_type_images[index_del].delete = true;
            }
            let a_img = this.sub_type_images.find(x => x.delete != true && x.file != null &&x.dataurl != null);
            if (a_img) {
              const index_a: number = this.sub_type_images.indexOf(a_img);
              if (index_a !== -1) {
                this.sub_type_images.splice(index_a, 1);
              }
            }
            this.sub_type_images.push(im);

          } else {
            this.sub_type_images.push(im);
          }
          console.log(this.sub_type_images);
        }
        reader.readAsDataURL(file);
      } else {
        const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
        this.res_message = error_text;
        this.is_loading = false
        setTimeout(() => { this.swalError.fire() }, 500);
      }
      }
    }
  }

}