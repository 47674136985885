import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private subject = new Subject<any>();

  constructor(
    private api : ApiService,
  ) { }
  async getEvt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvt(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtCount(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtCount(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addevt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvt(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmEvt(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmEvt(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtConfirm(req:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtConfirm(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  clearMessages() {
    this.subject.next();
  }
  getMessage(): Observable<any> {
      return this.subject.asObservable();
  }
  async getEvtType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtType(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtSubType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtSubType(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtGroup(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtGroup(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtImg(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtImg(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getEvtEff(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtEff(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addevtEff(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvtEff(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmevtEff(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmevtEff(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateEvtApprove(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateEvtApprove(req).subscribe((res:any)=>{
        if(res.code == 1){
          this.subject.next({ chk: true });
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateEvtSignature(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateEvtSignature(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  // RM VVV
  async addIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncident(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentById(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentById(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncident(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentName(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentName(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async getIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIncident(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIncident(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmIncidentType(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmIncidentType(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async uploadImages(image: File, event_id: number){
    return new Promise((reslove,reject)=>{
      this.api.uploadImages(image,event_id).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmImages(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmImages(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addIncidentAct(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addIncidentAct(req).subscribe((res:any)=>{
        if(res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  // async getRainConfig(){
  //   return new Promise((reslove,reject)=>{
  //     this.api.getRainConfig().subscribe((res:any)=>{
  //       if(res && res.code == 1){
  //         return reslove(res.data);
  //       }else{
  //         return reslove(false);
  //       }
  //     })
  //   })
  // }
  // async updateRainConfig(req?:any){
  //   return new Promise((reslove,reject)=>{
  //     this.api.updateRainConfig(req).subscribe((res:any)=>{
  //       if(res.code == 1){
  //         this.subject.next({ chk: true });
  //         return reslove(true);
  //       }else{
  //         return reslove(false);
  //       }
  //     })
  //   })
  // }
  async getEvtMsg(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getEvtMsg(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addEvtMsg(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addEvtMsg(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
}
