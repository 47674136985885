<div class="panel-item">
  <!--Search Panel-->
  <!-- <div>
        <h5>เลือกรูปภาพ</h5>
    </div> -->

  <!--Content-->

  <div *ngIf="isLoading" class="d-flex align-items-center justify-content-center media-list-panel">
    <h5>... Loading ...</h5>
  </div>

  <div *ngIf="!isLoading" class="d-flex align-content-start flex-wrap media-list-panel scrollbar-style">
    <div *ngFor="let media of mediaList">
      <div [ngClass]="{ 'bg-gray-900': media?.id == selectedMedia?.id }">
        <div class="p-2">
          <!-- <img class="pointer" (click)="select(media)" [src]="media?.path" [style.height.px]="sign_height"
            [style.width.px]="media_sign_width(media?.sign_type_obj?.aspect_ratio,sign_height)" /> -->
          <!-- <div class="pointer" [style.height.px]="sign_height" (click)="select(media)"
            style="background-color: black;position: relative;"
            [style.width.px]="media_sign_width(media?.sign_type_obj?.aspect_ratio,sign_height)">
            <img *ngIf="media?.path" [src]="media?.path" width="100%" height="100%"
              style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;" />
            <div style="position: absolute;left: 0px;top: 0px;width: 100%;height: 100%;"
              *ngFor="let item of media?.special_property?.text_items;index as i;"
              [ngClass]="(item?.is_center)?'d-flex align-items-center justify-content-center':''">
              <span *ngIf="item?.is_center && item?.text_type == specialTextType.FreeText"
                [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" [style.color]="item?.color"
                [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
              <span *ngIf="!item?.is_center && item?.text_type == specialTextType.FreeText"
                [ngClass]="(item?.font_weight == 'Bold')?'font-Bold':''" style="position: absolute"
                [style.top.px]="item?.y" [style.left.px]="item?.x" [style.color]="item?.color"
                [style.font-size.px]="item?.font_size" [style.family]="item?.font_family">{{item?.text}}</span>
            </div>
          </div> -->
          <app-sign-image-item class="pointer" (click)="select(media)" [media]="media" [sign_height]="sign_height"
            [ratio]="media?.sign_type_obj?.aspect_ratio" [resize]="(medie_sign_height/media?.sign_type_obj?.height)"
            *ngIf="media?.path && (media?.media_type == mediaType.Image || media?.media_type == mediaType.Gif)">
          </app-sign-image-item>
          <div class="clearfix m0 text-center"
            [ngStyle]="getSignNameTextStyles(media_sign_width(media?.sign_type_obj?.aspect_ratio,sign_height))">
            <small title="{{media?.name}}">{{media?.name}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Decision Panel-->
<div class="pull-right mt-3">
  <button type="button" class="btn btn-secondary" (click)="closeComponent(null)">ยกเลิก</button>
  <button type="button" class="btn btn-primary text-light ml-2" (click)="closeComponent(selectedMedia)"
    [disabled]="!selectedMedia">เลือกรูปภาพ</button>
</div>
