<div class="modal-header aH-BG">
    <h4 *ngIf="!header">Warning</h4>
    <h4 *ngIf="header" class="modal-title pull-left" [innerHTML]="header"></h4>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true" class="x-BT">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" [innerHTML]="body" style="    margin-top: 27px;">
  </div>
  <div class="modal-footer aF-BG">
    <button *ngIf="!footer" type="button" class="btn btn-danger" (click)="close()">Close</button>
    <button *ngIf="footer == 1" type="button" class="btn btn-secondary" (click)="cancel()">ยกเลิก</button>
    <button *ngIf="footer == 1" type="button" class="btn btn-primary text-white ml-2" (click)="confirm()">ยืนยัน</button>
  </div>