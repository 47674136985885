import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { navItems } from './../../_nav';
import { menuItems as menuItems1 } from 'src/app/views/assets-management/_menu';
import { menuItems as menuItems2 } from 'src/app/views/user-management/menu';
import { menuItems as menuItems3 } from 'src/app/views/sign-control/_menu';
import { menuItems as menuItems4 } from 'src/app/views/traffic-tool/menu';
import { menuItems as menuItems5 } from 'src/app/views/event/menu';
import { menuItems as menuItems6 } from 'src/app/views/information/_menu';
import { menuItems as menuItems7 } from 'src/app/views/corridor-control/_menu';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanLoad {
  menuItems = [];
  constructor(
    private router: Router,
    private authService: AuthService) {
    this.menuItems = _.concat(menuItems1, menuItems2, menuItems3, menuItems4, menuItems5, menuItems6, menuItems7);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const u = localStorage.getItem(this.authService.user_key);
    const u_session = sessionStorage.getItem(this.authService.user_key);
    let user = null;
    let user_session = null;

    if (u) {
      user = JSON.parse(u.toString());
    }
    if (u_session) {
      user_session = JSON.parse(u_session.toString());
    }
    const path = next.routeConfig.path;
    //console.log("next:", next);
    // console.log("state:", state);
    //console.log(user);
    if (user != null && user_session != null) {
      const nav = navItems.find(x => x.url == `/${path}`);
      if (nav) {
        if (user.permission.length > 0) {
          const find = user.permission.find(x => x == nav.id);
          if (find) {
            const menu = this.menuItems.find(x => state.url.includes(x.url));
            if (menu) {
              const find_p = user.permission.find(x => x == menu.id);
              if (find_p) {
                // logged in so return true
                return true;
              }
            }
          }
        }
      }
    }
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
