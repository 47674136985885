import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { isEmpty } from 'lodash';
import { FuncService } from './func.service';
import { CommonService } from '../common.service';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = {
    route: `${environment.api.domain_assets}/route`,
    login: `${environment.api.domain_auth}/login`,
    // user management path
    user: `${environment.api.domain_auth}`,
    users: `${environment.api.domain_auth}/users`,

    // Institution
    ins: `${environment.api.domain_auth}/institution`,

    // Group
    devicepermissionG: `${environment.api.domain_assets}/users/devicepermission`,
    userpermissionG: `${environment.api.domain_auth}/users/permission`,
    permissionG: `${environment.api.domain_auth}/permission`,
    group: `${environment.api.domain_auth}/group`,
    ugroup: `${environment.api.domain_auth}/usergroup`,
    event: `${environment.api.domain_event}/event`,
    
    info: `/info`,
    type: `/type`,
    image: `/image`,
    subtype: `/subtype`,
    effect: `/effect`,
    approve: `/approve`,
    grp: `/group`,
    signature: `/signature`,
    count: '/count',
    confirm: '/confirm',
    msg: '/message',
    incident: `${environment.api.domain_incident}/incident`,
    incident_type: `${environment.api.domain_incident}/incident/type`,
    uploadImg: `${environment.api.domain_incident}/upload/image`,
    
    // SMC
    configRoad: `${environment.api.domain_assets}/device`,
    devSmc: `${environment.api.domain_assets}/device/smc`,
    reversible: `${environment.api.domain_assets}/reversible`,
    // configRoad: `${environment.api.domain_traffic_tool}/device`,
    smc: `/smc-config-road`,

  }
  // `device/${device_id}/smc-config-road`;
  constructor(
    private http: HttpClient,
    private func:FuncService,
    private authService: AuthService,
    private commonService: CommonService
  ) { }
  public static token: string;
  public static setToken(access_token) {
    this.token = "Bearer " + access_token
  }
  public static getToken() {
    if(!this.token) this.setToken(localStorage.currentUserTokenWebAppShw)
    return (this.token) ? this.token : null;
  }
  setheader() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };;
  }

  getRoute() {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.route, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getRoute', []))
      );
  }
  getDevicePermission(params?:any) {
    
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.devicepermissionG+ txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getDevicePermission', []))
      );
  }
  getDeviceSmc(params?:any) {
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.devSmc+ txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getDeviceSmc', []))
      );
  }
  addPermissionGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.userpermissionG, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateUser', []))
      );
  }
  getPermissionGroup(g_id?: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.permissionG + `/${g_id}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getPermissionGroup', []))
      );
  }
  getUsers(params?: any): Observable<any[]> {
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.users + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getUsers', []))
      );
  }
  addUser(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
      delete data.username
      return this.http.put<any[]>(this.api.users + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateUser', []))
        );
    }
    return this.http.post<any[]>(this.api.users + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addUser', []))
      );
  }
  rmUser(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
    }
    return this.http.delete<any[]>(this.api.users + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getUsers', []))
      );
  }

  // Institution =====================
  getIns(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    return this.http.get<any[]>(this.api.ins + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIns', []))
      );
  }
  addIns(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    let method: any = this.http.post
    if (data.id) {
      method = this.http.put
      txt = '/' + data.id
      delete data.id
      delete data.username
      return this.http.put<any[]>(this.api.ins + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateIns', []))
        );
    }
    return this.http.post<any[]>(this.api.ins + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIns', []))
      );
  }
  rmIns(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
    }
    return this.http.delete<any[]>(this.api.ins + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmIns', []))
      );
  }

  // Group =====================
  getGroup(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    return this.http.get<any[]>(this.api.group + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getGroup', []))
      );
  }
  addGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    let method: any = this.http.post
    if (data.id) {
      method = this.http.put
      txt = '/' + data.id
      delete data.id
      delete data.username
      return this.http.put<any[]>(this.api.group + txt, data, httpOptions)
        .pipe(
          catchError(this.commonService.errorHandler('updateGroup', []))
        );
    }
    return this.http.post<any[]>(this.api.group + txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addGroup', []))
      );
  }
  rmGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
    }
    return this.http.delete<any[]>(this.api.group + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmGroup', []))
      );
  }

  addUGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.ugroup, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addUGroup', []))
      );
  }
  rmUGroup(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.user_id) {
      txt = '/' + data.user_id
      delete data.user_id
    }
    if (data.group_id) {
      txt += '/' + data.group_id
      delete data.group_id
    }
    return this.http.delete<any[]>(this.api.ugroup + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addUGroup', []))
      );
  }

  // Incident ==========================
  getIncident(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    return this.http.get<any[]>(this.api.incident + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  getIncidentById(id:number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.incident +'/'+ id, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  getIncidentName(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = "/lane/name";
    return this.http.get<any[]>(this.api.incident + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  addIncident(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
      return this.http.put<any[]>(this.api.incident+txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncident', []))
      );
    }
    return this.http.post<any[]>(this.api.incident+txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncident', []))
      );
  }
  getIncidentType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    return this.http.get<any[]>(this.api.incident_type + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getIncidentType', []))
      );
  }
  addIncidentType(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
      return this.http.put<any[]>(this.api.incident_type+txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncidentType', []))
      );
    }
    return this.http.post<any[]>(this.api.incident_type+txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncidentType', []))
      );
  }
  rmIncident(id: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.incident+'/'+id,{status: 0},  httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmIncidentType', []))
      );
  }
  
  rmIncidentType(id: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.incident_type+'/'+id,  httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmIncidentType', []))
      );
  }
  // rmIncidentType(id: any): Observable<any[]> {
  //   return this.http.put<any[]>(this.api.incident_type+'/'+id,{status: 0},  httpOptions)
  //     .pipe(
  //       catchError(this.commonService.errorHandler('rmIncidentType', []))
  //     );
  // }
  addIncidentAct(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt = '/'+data.incident_id+'/action'
    delete data.incident_id
    return this.http.put<any[]>(this.api.incident+txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addIncidentAct', []))
      );
  }
  // Event==========================
  
  getEvtCount(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.info+this.api.count + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvt(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.info + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  addEvt(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    if (data.id) {
      return this.http.put<any[]>(this.api.event+this.api.info, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
    }
    return this.http.post<any[]>(this.api.event+this.api.info, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addEvt', []))
      );
  }
  rmEvt(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.event+this.api.info+`/${id}`, httpOptions)
    .pipe(
      catchError(this.commonService.errorHandler('updateEvt', []))
    );
  }
  getEvtConfirm(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.event+this.api.confirm+`/${id}`, httpOptions)
    .pipe(
      catchError(this.commonService.errorHandler('updateEvt', []))
    );
  }
  getEvtMsg(id: number): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.event+this.api.msg+`/${id}`, httpOptions)
    .pipe(
      catchError(this.commonService.errorHandler('updateEvt', []))
    );
  }
  addEvtMsg(data:any): Observable<any[]> {
    const httpOptions = this.setheader();
    let event_id = (data.evt_id)? data.evt_id : null
    if(data.evt_id) delete data.evt_id
    return this.http.post<any[]>(this.api.event+this.api.msg+`/${event_id}`,data, httpOptions)
    .pipe(
      catchError(this.commonService.errorHandler('updateEvt', []))
    );
  }
  getEvtType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.type + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvtSubType(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.subtype + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvtGroup(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.grp + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  getEvtImg(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.image + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  
  getEvtEff(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = this.setParams(params)
    return this.http.get<any[]>(this.api.event+this.api.effect + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getEvt', []))
      );
  }
  addEvtEff(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.event_id) {
      txt = '/' + data.event_id
      delete data.event_id
    }
    return this.http.put<any[]>(this.api.event+this.api.effect+txt, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  rmevtEff(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let txt: string = ''
    if (data.id) {
      txt = '/' + data.id
      delete data.id
    }
    return this.http.delete<any[]>(this.api.event+this.api.effect+txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  updateEvtApprove(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.event+this.api.approve, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  updateEvtSignature(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.put<any[]>(this.api.event+this.api.signature, data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateEvt', []))
      );
  }
  // Upload image incident==========================

  // uploadImages(image: File, bucket_name: string, path: string, image_name?: string): Observable<any[]> {
  //   let formData = new FormData();
  //   formData.append('bucket_name', bucket_name.toString());
  //   formData.append('path', path.toString());
  //   if(image_name)
  //     formData.append('image_name', image_name.toString());
  //   // image.forEach(item => {
  //     formData.append('image', image);
  //   // });
  //   return this.http.post<any[]>(this.api.uploadImg, formData)
  //     .pipe(
  //       catchError(this.commonService.errorHandler('uploadImages', []))
  //     );
  // }
  // rmImages(path : string): Observable<any[]> {
  //   let txt = `?link=${path}`
  //   return this.http.delete<any[]>(this.api.uploadImg+txt)
  //     .pipe(
  //       catchError(this.commonService.errorHandler('rmImages', []))
  //     );
  // }

  // Upload image Event==========================

  uploadImages(image: File, event_id: number): Observable<any[]> {
    let formData = new FormData();
    formData.append('event_id', event_id.toString());
    // image.forEach(item => {
      formData.append('image', image);
    // });
    return this.http.post<any[]>(this.api.event+this.api.image, formData)
      .pipe(
        catchError(this.commonService.errorHandler('uploadImages', []))
      );
  }
  rmImages(path : string): Observable<any[]> {
    let txt = `?link=${path}`
    return this.http.delete<any[]>(this.api.uploadImg+txt)
      .pipe(
        catchError(this.commonService.errorHandler('rmImages', []))
      );
  }
  
  // ConfigRoad =====================
  getSmc(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    // let txt : any = `${(data.route)? '?page=1&route='+data.route:''}`
    
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    return this.http.get<any[]>(this.api.configRoad + `/smc`+txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getSmc', []))
      );
  }
  getSmcConfigRoad(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.get<any[]>(this.api.configRoad + `/${data.device_id+this.api.smc}`, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getSmcConfigRoad', []))
      );
  }
  addSmcConfigRoad(device_id,data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.configRoad + `/${device_id+this.api.smc}`,data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addSmcConfigRoad', []))
      );
  }

  // Reversible =====================

  getReversible(params?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    console.log(params)
    let txt: string = "";
    if (!isEmpty(params)) {
      txt = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          if (txt.length > 1) txt += '&'
          const element = params[key];
          txt += `${key}=${element}`
        }
      }
    }
    return this.http.get<any[]>(this.api.reversible + txt, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('getReversible', []))
      );
  }
  addReversible(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.reversible ,data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addReversible', []))
      );
  }
  updateReversible(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    let id = data.rv_id
    delete data.rv_id
    return this.http.put<any[]>(this.api.reversible+'/'+id ,data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('updateReversible', []))
      );
  }
  rmRv(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.reversible+`/${data}` , httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmRv', []))
      );
  }
  
  addReversibleSensor(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.post<any[]>(this.api.reversible+'/sensor',data, httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('addReversibleSensor', []))
      );
  }
  rmRvSs(data?: any): Observable<any[]> {
    const httpOptions = this.setheader();
    return this.http.delete<any[]>(this.api.reversible+`/sensor/${data}` , httpOptions)
      .pipe(
        catchError(this.commonService.errorHandler('rmRvSs', []))
      );
  }

  setParams(vals:any){
    let txt: string = "";
    if (!isEmpty(vals)) {
      txt = '?'
      for (const key in vals) {
        if (Object.prototype.hasOwnProperty.call(vals, key)) {
          if (txt.length > 1) txt += '&'
          const element = vals[key];
          txt += `${key}=${element}`
        }
      }
    }
    return txt
  }

}

