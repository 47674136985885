import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-speed-enforcement',
  templateUrl: './speed-enforcement.component.html',
  styleUrls: ['./speed-enforcement.component.scss']
})
export class SpeedEnforcementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
