import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SmartmicroComponent } from './smartmicro/smartmicro.component';
import { TraveltimeComponent } from './traveltime/traveltime.component';
import { WeatherStationComponent } from './weather-station/weather-station.component';
import { TrafficSignalComponent } from './traffic-signal/traffic-signal.component';
import { ReversibleLaneComponent } from './reversible-lane/reversible-lane.component';
import { CCTVComponent } from './cctv/cctv.component';
import { SpeedEnforcementComponent } from './speed-enforcement/speed-enforcement.component';
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'smartmicro',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'smartmicro',
        component: SmartmicroComponent,
      },
      {
        path: 'cctv',
        component: CCTVComponent,
      },
      {
        path: 'speed',
        component: SpeedEnforcementComponent,
      },
      {
        path: 'traveltime',
        component: TraveltimeComponent,
      },
      {
        path: 'weather-station',
        component: WeatherStationComponent,
      },
      {
        path: 'traffic-signal',
        component: TrafficSignalComponent,
      },
      {
        path: 'reversible',
        component: ReversibleLaneComponent,
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrafficToolRoutingModule { }
