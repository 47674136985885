import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { DeviceGroup, ReqAddDeviceGroup } from '../../models/assets-management/device-group';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DeviceGroupService {
  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_assets;
  }
  getDeviceGroup(name?: string, status?: number, page?: number, limit?: number,short?:number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(name)) {
      params = params.append('name', name.toString());
    }
    if (!_.isNil(status)) {
      params = params.append('status', status.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    const uri = `device/group`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<DeviceGroup[]>>>(url, httpOptions);
    //let url = 'assets/data/device_group.json';
    //return this.http.get<ApiResponse<PagableData<DeviceGroup[]>>>(url);
  }
  getDeviceGroupById(device_group_id?: number) {
    const uri = `device/group/${device_group_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<DeviceGroup[]>>(url, httpOptions);
  }
  addDeviceGroup(req: ReqAddDeviceGroup): Observable<ApiResponse<any>> {
    const uri = `device/group`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editDeviceGroup(req: ReqAddDeviceGroup, device_group_id?: number): Observable<ApiResponse<any>> {
    const uri = `device/group/${device_group_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteDeviceGroup(device_group_id: number): Observable<ApiResponse<any>> {
    const uri = `device/group/${device_group_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteDeviceGroupEditStatus(req: ReqAddDeviceGroup, device_group_id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `device/group/${device_group_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
