export enum StatusEnum {
    Delete = 0,
    Active = 1,
    Inactive = 2,
    Ma = 3
}
export enum DeviceStatusEnum {
    Delete = 0,
    Active = 1,
    Inactive = 2,
    Ma = 3
}

export enum StationTypeEnum {
    Static = 0,
    Mobility = 1
}

export enum MonitorTypeEnum {
    Hardware = 1,
    Software = 2,
    Boardsign = 3
}

export enum VendorContactTypeEnum {
    Install = 1,
    Ma = 2,
    InstallMa = 3
}
export enum MaStatusEnum {
    Create = 1,
    Operate = 2,
    Close = 3
}

export enum RouteDirectionEnum {
    IN = 1,
    OUT = 2,
    INOUT = 3
}

export enum ConnectionStatusEnum {
    Online = 1,
    Offline = 0,
    TmpOffline = 2
}

export enum DayOfWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7
}