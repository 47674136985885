import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
declare let videojs: any;

@Component({
  selector: 'app-display-rtsp',
  templateUrl: './display-rtsp.component.html',
  styleUrls: ['./display-rtsp.component.scss']
})
export class DisplayRtspComponent implements OnInit, OnChanges, OnDestroy {
  @Input() item: any;
  @Input() width: any;
  //poster:any = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
  //src:any = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
  poster: any;
  src: any;
  player: any;
  nn: boolean = false;
  idx: number;
  is_item_change: boolean = false;
  constructor() { }

  ngOnInit() {

  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(this.width);
    //this.is_item_change = true;
    if (this.item) {
      //this.src = 'http://toc.highwaytraffic.go.th:20105/0002-0077250-2/cam.m3u8';
      if (this.item.rtsp_link) {
        this.src = this.item.rtsp_link;
      }
      this.idx = this.item.id;
      //this.poster = 'http://api.toc.highwaytraffic.go.th/cctv/?ip=10.2.0.157&port=11621&username=admin&password=Tr@nsc0de';
      // if (this.item.cctv_link) {
      //   if (this.item.cctv_link.link_type == 0 && this.item.cctv_link.link) {
      //     this.poster = this.item.cctv_link.link;
      //     let n = this.item.cctv_link.link.includes('?');
      //     if (n == false) {
      //       this.poster = this.item.cctv_link.link + '?t=' + new Date().getTime();
      //     } else {
      //       this.poster = this.item.cctv_link.link + '&t=' + new Date().getTime();
      //     }
      //   }
      // }
      setTimeout(() => {
        this.ngAfterViewInit2()
      }, 1000);
    }
  }
  ngOnDestroy(): void {
    if (this.player) {
      this.player.pause();
      this.player.dispose();
    }
  }
  ngAfterViewInit2() {
    //this.is_item_change = false;
    // ID with which to access the template's video element
    let el = 'video_x_' + this.idx;

    // setup the player via the unique element ID
    this.player = videojs(document.getElementById(el), {
      html5: {
        hls: {
          withCredentials: false
        }
      }
    }, function () {

      // Store the video object
      var myPlayer = this, id = myPlayer.id();

      // Make up an aspect ratio
      var aspectRatio = 264 / 640;

      // internal method to handle a window resize event to adjust the video player
      function resizeVideoJS() {
        var width = document.getElementById(id).parentElement.offsetWidth;
        myPlayer.width(width);
        myPlayer.height(width * aspectRatio);
      }

      // Initialize resizeVideoJS()
      resizeVideoJS();

      // Then on resize call resizeVideoJS()
      window.onresize = resizeVideoJS;

    });
  }

}