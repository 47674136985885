import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FuncService } from '../../../services/user/func.service'
import { InstitutionModalComponent } from './institution-modal/institution-modal.component';
import { InstitutionService } from '../../../services/user/institution.service'
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { CommonService } from '../../../services/common.service';
import { permission } from 'src/app/views/user-management/menu';
@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./institution.component.scss']
})
export class InstitutionComponent implements OnInit {

  modalRef: BsModalRef;
  constructor(
    private func : FuncService,
    private modalService: BsModalService,
    private insS : InstitutionService,
    private commonService: CommonService
  ) { }
  list : any = [];
  total : number = 0;
  currentPage : number = 1;
  itemsPerPage : number = 10;
  name : string = ''
  hashtag : string = ''
  ins_id : number

  selected: string;
  ins_name: string;
  selectedOption: any;

  isAdd: boolean = false;
  isEdit: boolean = false;
  async ngOnInit() {
    this.getData();
    this.isAdd = await this.commonService.getPermission(permission.institution.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.institution.edit_id).toPromise();
  } 
  openModal(data?:any) {
    const initialState = {
      values: data
    };
    this.modalRef = this.modalService.show(InstitutionModalComponent, {initialState});
    this.modalRef.content.event.subscribe(data => {
      if(data) this.getData()
      // return resolve(data);
   });
  }
  async getData(data?:any){
    this.list = await this.insS.getIns(data);
    this.total = this.list.length
  }
  search(){
    let params : any = {}
    if(this.ins_name && !this.ins_id) params.ins_name = this.ins_name
    if(this.ins_id) params.ins_id = this.ins_id
    if(this.hashtag) params.hashtag = this.hashtag
    console.log(params)
    this.getData(params)
  }
  pageChanged(evt?){
    this.currentPage = evt.page
  }
 
  setPage(pageNo: number): void {
    this.currentPage = pageNo;
  }
  async rmlist(id){
    
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>',`<i class="icon-info mr-2"></i>ลบหน่วยงาน`,1);
    if(result){
      let res : any = await this.insS.rmIns({id:id})
      if(res.id) this.getData()
    }
  }

  onSelect(event: TypeaheadMatch): void {
    if(this.ins_name){
      this.ins_id = event.item.id;
    }else{
      this.ins_id = null
    }
  }

  
  blur(evt?:any){
    
    this.ins_id = null
  }

}
