<div class="row p-3">
  <div class="col-lg-3 col-md-3 col-sm-6" *ngFor="let s of cctv_list">
    <div class="p-1">
      <div class="pt-1 pb-1 pl-3 pr-3 d-flex justify-content-between align-items-center">
        <div>
          <div>{{s?.id}} </div>
          <div *ngIf="s?.station?.route_id">ทล.{{s?.station?.route_id}}
            ( {{s?.station?.km}}+{{s?.station?.m | number:'3.0-0'}} ) </div>
        </div>
        <i *ngIf="(s?.cctv_link?.link_type ==0) && (s?.cctv_link?.link && s?.cctv_link?.link.includes('http')) && !(s?.rtsp_link) && !(s?.cctv_flv)"
          title="+" class="fa fa-image pointer text-light" style="font-size: 18px"></i>
        <i *ngIf="s?.rtsp_link" title="+" style="font-size: 22px;" class="fa fa-play-circle-o pointer text-light"></i>
        <i *ngIf="s?.cctv_flv" title="+" style="font-size: 22px;" class="fa fa-play-circle-o pointer text-light"></i>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <div
          *ngIf="(s?.cctv_link?.link_type == 0) && (s?.cctv_link?.link && s?.cctv_link?.link.includes('http')) && !(s?.rtsp_link) && !(s?.cctv_flv)">
          <app-display-img [item]="s"></app-display-img>
        </div>
        <div *ngIf="s?.rtsp_link">
          <app-display-rtsp [item]="s"></app-display-rtsp>
        </div>
        <div *ngIf="s?.cctv_flv">
          <app-display-flv [item]="s"></app-display-flv>
        </div>
      </div>
    </div>
  </div>
</div>
