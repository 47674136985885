<div class="row p-3">
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ทางหลวง :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_route" [allowCustom]="allowCustom" [textField]="" [valueField]=""
        [value]="search_route" (valueChange)="handleValueRoute($event)" (filterChange)="handleFilterRoute($event)"
        [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ช่วงกิโลเมตร :</div>
    <div class="d-flex align-items-center">
      <div class="flex-fill"><span class="nowrap mr-1">เริ่มต้น :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_st" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill"><span class="nowrap ml-1 mr-1">สิ้นสุด :</span></div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="กม." numbersOnly maxlength="4"
          [(ngModel)]="search_km_en" [ngModelOptions]="{standalone: true}"></div>
      <div class="flex-fill ml-1 mr-1"> + </div>
      <div class="flex-fill"><input type="text" class="form-control w-100" placeholder="ม." numbersOnly maxlength="3"
          [(ngModel)]="search_m_en" [ngModelOptions]="{standalone: true}"></div>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>สถานะ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'" [filterable]="true"
        [valueField]="'id'" [value]="search_status" (valueChange)="handleValueStatus($event)" [placeholder]="'ทั้งหมด'">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Station :</div>
    <div>
      <kendo-combobox class="w-100" [data]="station_search_list" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_station" (valueChange)="handleValueStation($event)"
        (filterChange)="handleFilterStation($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Owner :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_owner" [allowCustom]="allowCustom" [textField]="'company_name'"
        [valueField]="'id'" [value]="search_owner" (valueChange)="handleValueOwner($event)"
        (filterChange)="handleFilterOwner($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>Vendor :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_vendor" [allowCustom]="allowCustom" [textField]="'company_name'"
        [valueField]="'vendor_id'" [value]="search_vendor" (valueChange)="handleValueVendor($event)"
        (filterChange)="handleFilterVendor($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>กลุ่มอุปกรณ์ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_device_group" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'id'" [value]="search_device_group" (valueChange)="handleValueDeviceGroup($event)"
        (filterChange)="handleFilterDeviceGroup($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>
  <div class="col-lg-4 col-md-4 col-sm-6">
    <div>ประเภทอุปกรณ์ :</div>
    <div>
      <kendo-combobox class="w-100" [data]="data_device_type" [allowCustom]="allowCustom" [textField]="'name'"
        [valueField]="'type_id'" [value]="search_device_type" (valueChange)="handleValueDeviceType($event)"
        (filterChange)="handleFilterDeviceType($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
      </kendo-combobox>
    </div>
  </div>

  <div class="col-lg-4 col-md-4 d-flex align-items-end justify-content-end">
    <div class="mt-1">
      <button type="button" (click)="search()" class="btn btn-primary pull-right text-white ml-1"> <i
          class="fa fa-search"></i>
        ค้นหา </button>
    </div>
  </div>

  <div class="col-12 mt-3 table-header pl-3 pr-3 pt-2 pb-2 d-flex align-items-center justify-content-between">
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
      <label class="btn text-white pointer" [ngClass]="(viewType == 1)?'btn-primary':'btn-dark'" (click)="changeView(1)">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="1" [(ngModel)]="viewType"> <i
          class="fa fa-bars mr-1"></i>List
      </label>
      <label class="btn text-white pointer" [ngClass]="(viewType == 2)?'btn-primary':'btn-dark'" (click)="changeView(2)">
        <input type="radio" name="options" id="option1" autocomplete="off" [value]="2" [(ngModel)]="viewType"><i
          class="fa fa-map mr-1"></i> Map
      </label>
    </div>
    <button *ngIf="isAdd" (click)="openAddModal()" type="button" class="btn btn-primary text-white"> <i class="fa fa-plus"></i>
      เพิ่ม Station</button>
  </div>
  <div *ngIf="viewType == 1" class="col-12 mt-3">
    <table class="table table-outline mb-0">
      <thead class="thead-dark" style="background-color: black;">
        <tr>
          <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
          <th class="text-center text-light table-header">สถานะ</th>
          <th class="text-center text-light table-header">Station</th>
          <th class="text-center text-light table-header">ตำแหน่ง</th>
          <th class="text-center text-light table-header">จังหวัดที่รับผิดชอบ</th>
          <th class="text-center text-light table-header">Create date</th>
          <th *ngIf="isEdit" class="text-center text-light table-header">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!this.is_loading && data_list?.length == 0">
          <td colspan="10" style="height: 50px;">
            ไม่พบข้อมูล
          </td>
        </tr>
        <tr *ngIf="this.is_loading">
          <td colspan="10" style="height: 50px;">
            กำลังโหลด ..
          </td>
        </tr>
        <tr *ngFor="let item of data_list;index as i;">
          <td class="text-center">
            <div>
              {{(i+1)+((this.currentPage - 1)*itemsPerPage)}}
            </div>
          </td>
          <td class="text-center">
            <i tooltip="Active" placement="top" *ngIf="item?.status == statusEnum.Active"
              class="fa fa-circle text-success"></i>
            <i tooltip="Inctive" placement="top" *ngIf="item?.status == statusEnum.Inactive"
              class="fa fa-circle text-danger"></i>
          </td>
          <td class="text-center">
            {{item?.name}}
          </td>
          <td class="text-center">
            ทล. {{item?.route | number:'1.0-0'}} ({{item?.km}}+{{item?.m | number:'3.0-0'}})
          </td>
          <td class="text-center">
            {{item?.prov_str}}
          </td>
          <td class="text-center">
            <div style="font-size: 12px;">{{item?.created_datetime | date: 'dd/MM/yyyy'}}</div>
          </td>

          <td *ngIf="isEdit" class="text-center">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="openEditModal(item)" title="แก้ไข" type="button"
                class="btn btn-sm btn-warning mr-1 text-light">
                <i class="cui-note icons"></i>
              </button>
              <button (click)="openDeleteModal(item)" title="ลบ" type="button" class="btn btn-sm btn-danger ml-1">
                <i class="cui-trash icons"></i>
              </button>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="12" class="table-footer">
            <div class="table-footer-inside d-flex align-items-center justify-content-between">
              <div class="ml-3 pt-3">
                <pagination [totalItems]="totalItems" [maxSize]="10" [itemsPerPage]="itemsPerPage"
                  [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" class="pagination"
                  previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                  lastText="&raquo;"></pagination>
              </div>
              <div></div>
              <div class="mr-3 ml-2">total: {{totalItems}}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-12 mt-3">
    <div class="map" id="map-station" [ngClass]="(viewType == 2) ? 'map' : 'map-hide'"></div>
  </div>
</div>


<div bsModal #addModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i><span *ngIf="!selectItem?.id">เพิ่ม Station</span><span
            *ngIf="selectItem?.id">แก้ไข Station</span></h4>
        <button type="button" class="close text-light" (click)="addModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form-horizontal mt-2 mb-2">
          <div class="form-group row border-bottom pb-3">
            <div class="col-md-4 col-lg-3 col-form-label"> <span class="float-sm-left float-md-right">ประเภท
                Station<span class="text-danger">*</span>
                :</span></div>
            <div class="col-md-8 col-lg-9">
              <kendo-combobox class="hide-clear-bt w-100" [data]="station_type_list" [allowCustom]="allowCustom"
                [textField]="'name'" [valueField]="'id'" [value]="station_type_modal" [filterable]="true"
                (valueChange)="handleValueStationTypeModal($event)" [placeholder]="'โปรดระบุ'">
              </kendo-combobox>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-12" *ngIf="station_type_modal?.id ==  stationTypeEnum.Static">
              <div class="row">
                <div class="col-md-4">
                  <div> <span>ทางหลวง<span class="text-danger">*</span>:</span></div>
                  <div>
                    <kendo-combobox class="w-100" [data]="data_route_modal" [allowCustom]="allowCustom" [textField]=""
                      [valueField]="" [value]="route_modal" (valueChange)="handleValueRouteModal($event)"
                      (filterChange)="handleFilterRouteModal($event)" [placeholder]="'ทั้งหมด'" [filterable]="true">
                    </kendo-combobox>
                  </div>

                </div>
                <div class="col-md-4">
                  <div> <span>กิโลเมตร<span class="text-danger">*</span>:</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" mask="00000" class="form-control w-100"
                      [(ngModel)]="selectItem.km" [ngModelOptions]="{standalone: true}" />
                  </div>

                </div>
                <div class="col-md-4">
                  <div> <span>เมตร<span class="text-danger">*</span>:</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" mask="00000" class="form-control w-100"
                      [(ngModel)]="selectItem.m" [ngModelOptions]="{standalone: true}" />
                  </div>

                </div>
                <div class="col-md-4">
                  <div> <span>จังหวัดที่รับผิดชอบ :</span></div>
                  <div>
                    <kendo-combobox class="w-100" [data]="data_prov_modal" [allowCustom]="allowCustom" [textField]="'province_name'"
                      [valueField]="'province_id'" [value]="prov_modal" (valueChange)="handleValueProvModal($event)"
                      (filterChange)="handleFilterProvModal($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
                    </kendo-combobox>
                  </div>

                </div>
                <div class="col-md-4">
                  <div> <span>เขตที่รับผิดชอบ :</span></div>
                  <div>
                    <kendo-combobox class="w-100" [data]="data_dist_modal" [allowCustom]="allowCustom" [textField]="'dist_name'"
                      [valueField]="'dist_id'" [value]="dist_modal" (valueChange)="handleValueDistModal($event)"
                      (filterChange)="handleFilterDistModal($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
                    </kendo-combobox>
                  </div>
                </div>
                <div class="col-md-4">
                  <div> <span>ทิศทาง :</span></div>
                  <div>
                    <kendo-combobox class="w-100" [data]="dir_list" [allowCustom]="allowCustom" [textField]="'name'"
                      [valueField]="'id'" [value]="dir_modal" (valueChange)="handleValueDirModal($event)"
                      [placeholder]="'โปรดระบุ'" [filterable]="true">
                    </kendo-combobox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div><span>ชื่อสถานี<span class="text-danger">*</span>:</span></div>
              <div>
                <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                  [(ngModel)]="selectItem.name" [ngModelOptions]="{standalone: true}" />
              </div>
            </div>
            <div class="col-md-4">
              <div> <span>สถานะ<span class="text-danger">*</span>:</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="status_list" [allowCustom]="allowCustom" [textField]="'name'"
                  [valueField]="'id'" [value]="status_modal" (valueChange)="handleValueStatusModal($event)"
                  [placeholder]="'โปรดระบุ'" [filterable]="true">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-md-4">
              <div><span>Owner :</span></div>
              <div>
                <kendo-combobox class="w-100" [data]="data_owner_modal" [allowCustom]="allowCustom" [textField]="'company_name'"
                  [valueField]="'id'" [value]="owner_modal" (valueChange)="handleValueOwnerModal($event)"
                  (filterChange)="handleFilterOwnerModal($event)" [placeholder]="'โปรดระบุ'" [filterable]="true">
                </kendo-combobox>
              </div>
            </div>
            <div class="col-12" *ngIf="station_type_modal?.id ==  stationTypeEnum.Static">
              <div class="row">
                <div class="col-md-4">
                  <div><span>Latitude<span class="text-danger">*</span>:</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                      [(ngModel)]="selectItem.lat" [ngModelOptions]="{standalone: true}" numeric decimals="18" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div><span>Longitude<span class="text-danger">*</span>:</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                      [(ngModel)]="selectItem.lng" [ngModelOptions]="{standalone: true}" numeric decimals="18" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div style="height: 20px;"> </div>
                  <div>
                    <button type="button" (click)="openMapModal()" class="btn btn-primary text-white">ระบุพิกัดจากแผนที่</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12" *ngIf="station_type_modal?.id ==  stationTypeEnum.Mobility">
              <div class="row">
                <div class="col-md-4">
                  <div><span>ป้ายทะเบียน :</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                      [(ngModel)]="selectItem.license_plate_number" [ngModelOptions]="{standalone: true}" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div><span>จังหวัด :</span></div>
                  <div>
                    <kendo-combobox class="w-100" [data]="data_lic_prov_modal" [allowCustom]="allowCustom"
                      [textField]="'province_name'" [valueField]="'province_id'" [value]="lic_prov_modal"
                      (valueChange)="handleValueLicProvModal($event)" (filterChange)="handleFilterLicProvModal($event)"
                      [placeholder]="'โปรดระบุ'" [filterable]="true">
                    </kendo-combobox>
                  </div>
                </div>
                <div class="col-md-4">
                  <div><span>เลขตัวถัง :</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                      [(ngModel)]="selectItem.chassis_no" [ngModelOptions]="{standalone: true}" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div><span>MDVR serial no :</span></div>
                  <div>
                    <input autocomplete="nope" type="text" placeholder="" maxlength="100" class="form-control w-100"
                      [(ngModel)]="selectItem.mdvr_no" [ngModelOptions]="{standalone: true}" />
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="addModal.hide()">ยกเลิก</button>
          <button type="button"
            [disabled]="!(selectItem.name && status_modal != null && station_type_modal != null) || !(station_type_modal?.id == stationTypeEnum.Static && route_modal != null && (selectItem?.km || selectItem?.km === 0) && (selectItem?.m || selectItem?.m === 0) && selectItem?.lat && selectItem?.lng)"
            *ngIf="selectItem?.id && station_type_modal?.id == stationTypeEnum.Static"
            class="btn btn-primary text-white ml-2" (click)="editStation()">แก้ไข</button>
          <button type="button" [disabled]="!(selectItem.name && status_modal != null && station_type_modal != null)"
            *ngIf="selectItem?.id && station_type_modal?.id != stationTypeEnum.Static"
            class="btn btn-primary text-white ml-2" (click)="editStation()">แก้ไข</button>
          <button
            [disabled]="!(selectItem.name && status_modal != null && station_type_modal != null) || !(station_type_modal?.id == stationTypeEnum.Static && route_modal != null && (selectItem?.km || selectItem?.km === 0) && (selectItem?.m || selectItem?.m === 0) && selectItem?.lat && selectItem?.lng)"
            *ngIf="!selectItem?.id && station_type_modal?.id == stationTypeEnum.Static" type="button"
            class="btn btn-primary text-white ml-2" (click)="addStation()">เพิ่ม</button>
          <button [disabled]="!(selectItem.name && status_modal != null && station_type_modal != null)"
            *ngIf="!selectItem?.id && station_type_modal?.id != stationTypeEnum.Static" type="button"
            class="btn btn-primary text-white ml-2" (click)="addStation()">เพิ่ม</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>
<div bsModal #deleteModal="bs-modal" class="modal fade modal-md" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" (onHide)="clearData()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="icon-info mr-2"></i>ลบ Station</h4>
        <button type="button" class="close text-light" (click)="deleteModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="mt-2 text-center">
          <h4>คุณต้องการลบใช่หรือไม่</h4>
        </div>
        <div class="mt-2 mb-4 text-center">
          <!-- <h4>{{newsTypeItem?.news_type_name}}</h4> -->
        </div>
        <div class="d-flex justify-content-end mt-2 mb-2">
          <button type="button" class="btn btn-secondary" (click)="deleteModal.hide()">ยกเลิก</button>
          <!-- <button type="button" class="btn btn-primary ml-2" (click)="deleteType(newsTypeItem.id)">ยืนยัน</button> -->
          <button type="button" class="btn btn-primary text-white ml-2" (click)="deleteStation()">ยืนยัน</button>
        </div>

      </div>
      <!-- <div class="modal-footer">
            
          </div> -->
    </div>
  </div>
</div>

<div bsModal #mapModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
aria-hidden="true"(onHide)="clearMap()" [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-dark bg-gray-900">
        <h4 class="modal-title"> <i class="fa fa-map-marker mr-2"></i>คลิกที่แผนที่เพื่อระบุตำแหน่ง</h4>
        
        <button type="button" class="close text-light" (click)="mapModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="map" id="map-modal"></div>
      </div>
      <div class="modal-footer bg-dark bg-gray-900">
        <div class="d-flex justify-content-between mt-2 mb-2 align-items-center">
          <div *ngIf="map_lat">{{this.map_lat}},{{this.map_lng}}</div>
          <div>
            <button type="button" class="btn btn-secondary ml-2" (click)="mapModal.hide()">ยกเลิก</button>
            <button type="button" [disabled]="!(this.map_lat && this.map_lng)" class="btn btn-primary text-white ml-2" (click)="setLatLng()">ยืนยัน</button>
          </div>
          
        </div> 
      </div>
    </div>
  </div>
</div>
<swal #swalSuccess title="<div class='text-light'>สำเร็จ</div>" type="success" text="" icon="success"></swal>
<swal #swalError title="<div class='text-light'>ไม่สำเร็จ</div>" html="<div class='text-light'>{{res_message}}</div>"
  type="error" icon="error"></swal>