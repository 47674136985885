import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { SignTypeInfo, ReqAddSignType } from '../../models/sign-control/sign-type';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignTypeService {

  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_sign_control;
  }
  getSignType(page?: number, limit?: number,short?:number, type?: number, ratio?: number) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(type)) {
      params = params.append('type', type.toString());
    }
    if (!_.isNil(ratio)) {
      params = params.append('ratio', ratio.toString());
    }
    const uri = `sign/type`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<SignTypeInfo[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<Owner[]>>>(url);
  }

  getSignTypeRatio() {
    const uri = `sign/type/ratio`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<string[]>>(url, httpOptions);
    // let url1 = 'assets/data/ratio.json';
    // return this.http.get<ApiResponse<string[]>>(url1);
  }

  getSignTypeById(id?: number) {
    const uri = `sign/type/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<SignTypeInfo[]>>(url, httpOptions);
  }
  addSignType(req: ReqAddSignType): Observable<ApiResponse<any>> {
    const uri = `sign/type`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editSignType(req: ReqAddSignType, id?: number): Observable<ApiResponse<any>> {
    const uri = `sign/type/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteSignType(id: number): Observable<ApiResponse<any>> {
    const uri = `sign/type/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteSignTypeEditStatus(req: ReqAddSignType, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `sign/type/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
