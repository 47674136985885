import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiResponse, PagableData } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Owner, ReqAddOwner } from '../../models/assets-management/owner';
import { StatusEnum } from '../../models/common-enum';
import { BehaviorSubject, Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  domain: string;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_assets;
  }
  getOwner(page?: number, limit?: number,short?:number, company_name?: string, hashtag?: string) {
    let params = new HttpParams();
    if (!_.isNil(page)) {
      params = params.append('page', page.toString());
    }
    if (!_.isNil(limit)) {
      params = params.append('limit', limit.toString());
    }
    if (!_.isNil(short)) {
      params = params.append('short', short.toString());
    }
    if (!_.isNil(company_name)) {
      params = params.append('company_name', company_name.toString());
    }
    if (!_.isNil(hashtag)) {
      params = params.append('hashtag', hashtag.toString());
    }
    const uri = `owner`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<PagableData<Owner[]>>>(url, httpOptions);
    // let url = 'assets/data/owner.json';
    // return this.http.get<ApiResponse<PagableData<Owner[]>>>(url);
  }
  getOwnerById(id?: number) {
    const uri = `owner/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.get<ApiResponse<Owner[]>>(url, httpOptions);
  }
  addOwner(req: ReqAddOwner): Observable<ApiResponse<any>> {
    const uri = `owner`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }

  editOwner(req: ReqAddOwner, id?: number): Observable<ApiResponse<any>> {
    const uri = `owner/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteOwner(id: number): Observable<ApiResponse<any>> {
    const uri = `owner/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteOwnerEditStatus(req: ReqAddOwner, id?: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `owner/${id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}