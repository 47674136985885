<app-header [fixed]="true" [navbarBrandFull]="{src: 'assets/img/lms-m7-logo.png', width: 'auto', height: '40', alt: 'Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/lms-m7-logo.png', width: 40, height: 40, alt: 'Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false">

  <ul class="nav navbar-nav d-md-down-none">
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <span>{{currentUser?.firstname}} {{currentUser?.lastname}}</span>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button"
        aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <!-- <img title="{{user_info?.username}}" src="assets/img/avatar.png" class="img-avatar"/> -->
        <img *ngIf="!currentUser?.img_url || currentUser?.img_url ==''" src="assets/img/avatar.png" class="img-avatar" />
        <img *ngIf="currentUser?.img_url && currentUser?.img_url !=''" [src]="currentUser?.img_url" class="img-avatar" />
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>

        <div class="divider"></div>
        <a class="dropdown-item pointer" (click)="logout()"><i class="fa fa-sign-out"></i> Logout</a>
      </div>
    </li>
    <li class="nav-item" [hidden]="is_hidden_toggle_bt">
      <a class="nav-link pointer" (click)="clickToggle()"><i class="icon-menu hover-toggle"
          style="font-size:18px"></i></a>
    </li>
    <!-- end heading -->
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="menuItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <div class="main">
    <router-outlet></router-outlet>
  </div>
  <app-aside [fixed]="true" [display]="false"></app-aside>
</div>
