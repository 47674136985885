import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { colors } from './colors';
import { days } from './days';
import { Subject } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { startOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addWeeks, subWeeks, addMonths, subMonths, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarDateFormatter, CalendarA11y } from 'angular-calendar'; // import should be from `angular-calendar` in your app
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { DOCUMENT } from '@angular/common';
import { BoardsignService } from '../../../services/sign-control/boardsign.service';
import { CommonService } from '../../../services/common.service';
import { BoardsignInfo, StructureLayerInfo } from 'src/app/models/sign-control/boardsign';
import { AddBoardsignSchedule, AddSchedule, AddScheduleItem, ScheduleInfo } from 'src/app/models/sign-control/schedule';
import * as _ from 'lodash';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MediaType, ScheduleMode, SignDirection } from 'src/app/models/sign-control/enum';
import { DateInputCustomFormatPlaceholder } from '@progress/kendo-angular-dateinputs';
import { ConnectionStatusEnum, RouteDirectionEnum, StationTypeEnum, StatusEnum } from 'src/app/models/common-enum';
import { MediaInfo } from 'src/app/models/sign-control/media';
import { ScheduleService } from 'src/app/services/sign-control/schedule.service';
import { getDate, firstDayOfMonth, lastDayOfMonth, durationInMonths } from '@progress/kendo-date-math';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Day } from '@progress/kendo-date-math';
import { permission } from 'src/app/views/sign-control/_menu';
// import { registerLocaleData } from '@angular/common';
// import localeTh from '@angular/common/locales/en';
// registerLocaleData(localeTh);
const colors1: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  },
  green: {
    primary: '#1eff00',
    secondary: '#a5dd9e'
  },
  purple: {
    primary: '#f651fc',
    secondary: '#f8c3f8'
  }
};

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ]
})
export class ScheduleComponent implements OnInit, OnDestroy {
  @ViewChild('searchBoardsignModal') public searchBoardsignModal: ModalDirective;
  @ViewChild('mediaListModal') public mediaListModal: ModalDirective;
  @ViewChild('infoModal', { static: false }) public infoModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  private readonly darkThemeClass = 'dark-theme';
  constructor(@Inject(DOCUMENT) private document, private boardsignService: BoardsignService,
    private commonService: CommonService, private scheduleService: ScheduleService,
    private route: ActivatedRoute) { }
  scheduleMode = ScheduleMode;
  allowCustom: boolean = false;
  board_list: any[] = [];
  data_board_list: any[] = [];
  select_board: any;
  sign_select: any;
  boardsign_select: any;
  input_name: any;
  color_list = colors;
  color_modal: any;
  mode_list = [
    { id: ScheduleMode.NotRepeat, name: "ไม่ทำซ้ำ" },
    { id: ScheduleMode.EveryDay, name: "ทุกวัน" },
    { id: ScheduleMode.EveryWeek, name: "ทุกสัปดาห์" },
    { id: ScheduleMode.EveryWeekDay, name: "ทุกวันธรรมดา (จ.-ศ.)" },
    { id: ScheduleMode.EveryWeekEnd, name: "ทุกวันหยุด (ส.-อา.)" }
  ];
  mode_modal: any = { id: ScheduleMode.NotRepeat, name: "ไม่ทำซ้ำ" };
  schedule_select: ScheduleInfo;
  schedule_list: ScheduleInfo[] = [];
  res_message: any;
  start_datetime: any;
  stop_datetime: any;
  start_time: any;
  stop_time: any;
  start_day: any = 1;
  stop_day: any = 7;
  days1 = days;

  search_name: any;
  search_km_st: any;
  search_km_en: any;
  search_m_st: any;
  search_m_en: any;
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" },
    { id: StatusEnum.Ma, name: "MA" }
  ];
  search_status: any;
  search_route: string;
  data_route: Array<string> = [];
  route_list: string[] = [];
  boardAddList: any[] = [];
  public formatPlaceholder: DateInputCustomFormatPlaceholder = {
    day: 'วัน',
    month: 'เดือน',
    year: 'ปี',
    hour: 'ชั่วโมง',
    minute: 'นาที',
    second: 'วินาที'
  };
  is_loading_search: any;
  is_loading: any;
  set_sign_height_small: number = 48;
  set_sign_height: number = 60;
  route_params: any;
  public disabledDates: Day[] = [Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday, Day.Sunday];
  public disabledDatesStart: Day[] = [];
  public disabledDatesStop: Day[] = [];
  isAdd: boolean;
  async ngOnInit() {
    this.getRoute();

    this.document.body.classList.add(this.darkThemeClass);
    this.route.params.subscribe(params => this.route_params = params);
    if (this.route_params.boardsign_id) {
      this.getBoardsign(+this.route_params.boardsign_id);
      this.getBoardsignShedule(+this.route_params.boardsign_id);
    } else {
      this.getBoardsign();
      this.getBoardsignShedule();
    }
    this.isAdd = await this.commonService.getPermission(permission.calendar.add_id).toPromise();
  }
  ngOnDestroy(): void {
    this.document.body.classList.remove(this.darkThemeClass);
  }

  startDayOnChange(day?: number) {
    this.start_day = day;
    let num = day
    if (day == 7)
      num = 0;

    this.disabledDatesStart = this.disabledDates.filter(x => x != num);
    this.start_datetime = null;
  }
  stopDayOnChange(day?: number) {
    this.stop_day = day;
    let num = day
    if (day == 7)
      num = 0;

    this.disabledDatesStop = this.disabledDates.filter(x => x != num);
    this.stop_datetime = null;
  }
  locale: string = 'en-EN';

  view: string = 'month';

  viewDate: Date = new Date();
  actions: CalendarEventAction[] = [
    {
      label: '<i title="แก้ไข" class="fa fa-fw fa-pencil"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.openActionModal(event.meta, 1);
      },
    },
    {
      label: '<i title="ลบ" class="fa fa-fw fa-trash text-danger"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.openActionModal(event.meta, 2)
      },
    },
  ];
  start_date: any = new Date('2019-06-05 10:15');
  end_date: any = new Date('2019-07-08 10:15');
  events: Array<CalendarEvent<any>> = [];

  activeDayIsOpen: boolean = true;
  refresh: Subject<any> = new Subject();
  increment(): void {

    const addFn: any = {
      day: addDays,
      week: addWeeks,
      month: addMonths
    }[this.view];

    this.viewDate = addFn(this.viewDate, 1);
    if (this.route_params.boardsign_id) {
      this.getBoardsignShedule(+this.route_params.boardsign_id);
    } else {
      this.getBoardsignShedule();
    }
  }

  decrement(): void {

    const subFn: any = {
      day: subDays,
      week: subWeeks,
      month: subMonths
    }[this.view];

    this.viewDate = subFn(this.viewDate, 1);
    this.getBoardsignShedule();

  }

  today(): void {
    this.viewDate = new Date();
  }

  dayClicked({ date, events }: { date: Date, events: CalendarEvent[] }): void {

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }

    this.getBoardsignShedule();
  }
  search() {
    this.getBoardsignShedule();
  }

  async eventClicked(e) {
    this.schedule_select = e.event.meta;
    this.openActionModal(e.event.meta, 1);
  }
  getBoardsignShedule(boardsign_id?: number) {
    this.schedule_list = [];
    this.is_loading = true;
    let b_id = null;
    if (this.select_board) {
      b_id = this.select_board.id;
    } else {
      if (boardsign_id) {
        b_id = boardsign_id;
      }
    }
    let st_date = null;
    let en_date = null;
    if (this.viewDate) {
      const firstDay = firstDayOfMonth(this.viewDate);
      const lastDay = lastDayOfMonth(this.viewDate);
      st_date = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate(), 0, 0, 0);
      en_date = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate(), 23, 59, 59);
    }
    this.scheduleService.getSchedule(st_date, en_date, b_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.schedule_list = res.data;
            setTimeout(() => {
              this.convertEvent();
            }, 500);
          } else {
            this.schedule_list = [];
            this.convertEvent();
          }
        } else {
          this.schedule_list = [];
          this.convertEvent();
        }
        this.is_loading = false;
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.is_loading = false;
        this.schedule_list = [];
        this.convertEvent();
      });
  }

  convertEvent() {
    this.events = [];
    let ac = [];
    if (this.isAdd) {
      ac = this.actions;
    }
    this.schedule_list.forEach(item => {
      const colors = item.color.split("|");
      if (colors.length < 1) {
        colors.push("Blue");
      } if (colors.length < 2) {
        colors.push("#ebebff");
      }
      if (item.mode == this.scheduleMode.NotRepeat) {
        let event = {
          start: new Date(item.start_datetime),
          end: new Date(item.stop_datetime),
          title: item.name,
          color: {
            primary: colors[0],
            secondary: colors[1]
          },
          actions: ac,
          meta: item
        };
        this.events.push(event);
      }
      else if (item.mode == this.scheduleMode.EveryDay) {
        const st = new Date(item.start_datetime);
        const end = new Date(item.stop_datetime);

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        const diffDays = Math.round(Math.abs((st.getTime() - end.getTime()) / (oneDay)));

        for (let i = 0; i <= diffDays; i++) {
          let d = new Date(st);
          d.setDate(d.getDate() + i);
          const t1 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), st.getHours(), st.getMinutes());
          let t2 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), end.getHours(), end.getMinutes());
          if (t2 > end) {
            t2 = end;
          }
          const event = {
            start: t1,
            end: t2,
            title: item.name,
            color: {
              primary: colors[0],
              secondary: colors[1]
            },
            actions: ac,
            meta: item
          }
          this.events.push(event);
        }
      }
      else if (item.mode == this.scheduleMode.EveryWeek) {
        const day_diff = item.stop_day - item.start_day;
        const st = new Date(item.start_datetime);
        const end = new Date(item.stop_datetime);
        const st2 = new Date(st.getFullYear(), st.getMonth(), st.getDate() + day_diff, end.getHours(), end.getMinutes());
        const oneWeek = 24 * 60 * 60 * 1000 * 7; // hours*minutes*seconds*milliseconds

        //const diffWeeks = Math.round(Math.abs((st.getTime() - end.getTime()) / (oneWeek)));
        const en = new Date(end.getFullYear(), end.getMonth(), end.getDate(), st.getDate(), st.getHours(), st.getMinutes());
        const diffWeeks = Math.round(Math.abs((st.getTime() - en.getTime()) / (oneWeek)));
        for (let i = 0; i < diffWeeks; i++) {
          let d = new Date(st);
          d.setDate(d.getDate() + (i * 7));
          let d2 = new Date(st2);
          d2.setDate(d2.getDate() + (i * 7));
          const t1 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), st.getHours(), st.getMinutes());
          let t2 = new Date(d2.getFullYear(), d2.getMonth(), d2.getDate(), st2.getHours(), st2.getMinutes());
          if (t2 > end) {
            t2 = end;
          }
          let event = {
            start: t1,
            end: t2,
            title: item.name,
            color: {
              primary: colors[0],
              secondary: colors[1]
            },
            actions: ac,
            meta: item
          }
          this.events.push(event);
        }

      }
      else if (item.mode == this.scheduleMode.EveryWeekDay) {
        const st = new Date(item.start_datetime);
        const end = new Date(item.stop_datetime);

        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        const diffDays = Math.round(Math.abs((st.getTime() - end.getTime()) / (oneDay)));

        for (let i = 0; i <= diffDays; i++) {
          let d = new Date(st);
          d.setDate(d.getDate() + i);
          if (d.getDay() != 0 && d.getDay() != 6) {
            const t1 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), st.getHours(), st.getMinutes());
            let t2 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), end.getHours(), end.getMinutes());
            if (t2 > end) {
              t2 = end;
            }
            const event = {
              start: t1,
              end: t2,
              title: item.name,
              color: {
                primary: colors[0],
                secondary: colors[1]
              },
              actions: ac,
              meta: item
            }
            this.events.push(event);
          }
        }
      }
      else if (item.mode == this.scheduleMode.EveryWeekEnd) {
        let st = new Date(item.start_datetime);
        let end = new Date(item.stop_datetime);

        let oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

        let diffDays = Math.round(Math.abs((st.getTime() - end.getTime()) / (oneDay)));

        for (let i = 0; i <= diffDays; i++) {
          let d = new Date(st);
          d.setDate(d.getDate() + i);
          if (d.getDay() == 0 || d.getDay() == 6) {
            const t1 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), st.getHours(), st.getMinutes());
            let t2 = new Date(d.getFullYear(), d.getMonth(), d.getDate(), end.getHours(), end.getMinutes());
            if (t2 > end) {
              t2 = end;
            }
            const event = {
              start: t1,
              end: t2,
              title: item.name,
              color: {
                primary: colors[0],
                secondary: colors[1]
              },
              actions: ac,
              meta: item
            }
            this.events.push(event);
          }
        }
      }
    });

  }

  async genSignsLayerView(boards?: BoardsignInfo[], is_suggest?: boolean, board_list_id?: number[], is_edit?: boolean) {
    boards.forEach(board => {
      if (board_list_id) {
        const find_id = board_list_id.find(x => x == board.id);
        if (find_id) {
          board.check = true;
        }
      }

      if (board.signs) {
        board.signs = board.signs.filter(x => x.status == StatusEnum.Active);
        if (board.signs.length > 0 && board.station.boardsign_structure) {
          // layers
          //const layers_text = board.station.boardsign_structure.split("|");
          const layer_no_list = _.sortedUniq(board.signs.map(a=>a.layer_no));
          let layers = [];
          let layers_new = [];
          let layers_sug = [];
          layer_no_list.forEach((l, index) => {
            // signs
            let signs = [];
            let signs_new = [];
            let find_sign = board.signs.filter(x => x.layer_no == l);

            if (find_sign) {
              find_sign.forEach(s => {
                if (s.media) {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_obj: s.sign_type_object, dir: s.direction, media: s.media };
                  signs.push(d);
                } else {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_obj: s.sign_type_object, dir: s.direction, media: null };
                  signs.push(d);
                }
                signs_new.push({ id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_obj: s.sign_type_object, dir: s.direction, media: null });
              });
              signs = _.sortBy(signs, ['sign_no']);
              layers.push(signs);
              signs_new = _.sortBy(signs_new, ['sign_no']);
              layers_new.push(signs_new);
            }

            if (is_suggest) {
              board.signs_suggest = _.cloneDeep(board.signs);
              let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
              let signs_sug = [];
              if (find_sign_sug) {
                find_sign_sug.forEach(s => {
                  if (s.media) {
                    //let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: s.media };
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                    signs_sug.push(d);
                  } else {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                    signs_sug.push(d);
                  }
                });
                signs_sug = _.sortBy(signs_sug, ['sign_no']);
                layers_sug.push(signs_sug);
              }
            }
          });
          board.layers = layers;
          board.layers_new = _.cloneDeep(layers_new);
          if (is_edit) {
            board.layers_new = _.cloneDeep(layers);
          }
          if (is_suggest) {
            board.layers_suggest = _.cloneDeep(layers_sug);
          }
        } else {
          board.signs = [];
          board.layers = [];
          board.layers_suggest = [];
          board.layers_new = [];
        }
      } else {
        board.signs = [];
        board.layers = [];
        board.layers_suggest = [];
        board.layers_new = [];
      }
    });
    return boards;
  }
  async getBoardsign(boardsign_id?: number) {
    this.boardsignService.getBoardsign(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.board_list = _.cloneDeep(res.data);
              this.data_board_list = _.cloneDeep(res.data);
              this.select_board = this.board_list.find(x => x.id == +this.route_params.boardsign_id);
            }
          }
        }
      },
      error => {
      });
  }
  async searchBoardsignAddSche(ids?: number[]) {
    let res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, ids).toPromise();
    this.boardAddList = [];
    console.log(res);
    if (res) {
      if (res.code == 1) {
        this.boardAddList = await this.genSignsLayerView(res.data.data, false);
      } else { }
    }
  }
  handleFilterBoard(value) {
    if (value.length > 0) {
      this.data_board_list = this.board_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_board_list = _.cloneDeep(this.board_list);
    } else {
      this.data_board_list = [];
    }
  }
  handleValueBoard(value) {
    if (value) {
      this.select_board = value;
    } else {
      this.select_board = null;
    }
  }
  handleValueModeModal(value) {
    if (value) {
      this.mode_modal = value;
    } else {
      this.mode_modal = null;
    }
  }
  handleValueColorModal(value) {
    if (value) {
      this.color_modal = value;
    } else {
      this.color_modal = null;
    }
  }
  openAddModal() {
    this.boardAddList = [];
    if (this.select_board) {
      this.searchBoardsignAddSche([this.select_board.id]);
    }
    this.schedule_select = null;
    this.mode_modal = this.mode_list.find(x => x.id == this.scheduleMode.NotRepeat);
    this.start_datetime = null;
    this.stop_datetime = null;
    this.start_time = null;
    this.stop_time = null;
    this.color_modal = null;
    this.input_name = null;
    this.addModal.show();
  }
  async openActionModal(item?: ScheduleInfo, action_type?: number) {
    if (this.isAdd) {
      this.schedule_select = _.cloneDeep(item);
      this.input_name = this.schedule_select.name;
      const c = item.color.split("|");
      this.color_modal = this.color_list.find(x => x.primary == c[0]);
      this.mode_modal = this.mode_list.find(x => x.id == this.schedule_select.mode);
      if (this.mode_modal.id == this.scheduleMode.NotRepeat) {
        this.start_datetime = new Date(this.schedule_select.start_datetime);
        this.stop_datetime = new Date(this.schedule_select.stop_datetime);
        let dNow = new Date();
        this.start_time = new Date(dNow.getFullYear(), dNow.getMonth(), dNow.getDate(), 12, 0);
        this.stop_time = new Date(dNow.getFullYear(), dNow.getMonth(), dNow.getDate(), 12, 0);
      } else {
        this.start_datetime = new Date(this.schedule_select.start_datetime);
        this.stop_datetime = new Date(this.schedule_select.stop_datetime);
        this.start_time = new Date(this.schedule_select.start_datetime);
        this.stop_time = new Date(this.schedule_select.stop_datetime);
        if (this.mode_modal.id == this.scheduleMode.EveryWeek) {
          this.start_day = this.schedule_select.start_day;
          let num_start_day = this.schedule_select.start_day;
          if (this.start_day == 7)
            num_start_day = 0;
          this.stop_day = this.schedule_select.stop_day;
          let num_stop_day = this.schedule_select.stop_day;
          if (this.stop_day == 7)
            num_stop_day = 0;
          this.disabledDatesStart = this.disabledDates.filter(x => x != num_start_day);
          this.disabledDatesStop = this.disabledDates.filter(x => x != num_stop_day);
        }
      }

      this.boardAddList = await this.genSignsLayerView(this.schedule_select.board_list, false, null, true);
      if (action_type == 1) { //edit
        this.addModal.show();
      } else { //delete
        this.deleteModal.show();
      }

    }
  }
  clearData() {

  }
  addSchedule() {
    this.is_loading = true;
    let req = new AddSchedule();
    req.color = `${this.color_modal.primary}|${this.color_modal.secondary}`;
    req.mode = +this.mode_modal.id;
    req.name = this.input_name;

    if (req.mode == this.scheduleMode.NotRepeat) {
      req.start_datetime = this.start_datetime;
      req.stop_datetime = this.stop_datetime;
    } else {
      let d1 = new Date(this.start_datetime.getFullYear(), this.start_datetime.getMonth(), this.start_datetime.getDate(), this.start_time.getHours(), this.start_time.getMinutes());
      let d2 = new Date(this.stop_datetime.getFullYear(), this.stop_datetime.getMonth(), this.stop_datetime.getDate(), this.stop_time.getHours(), this.stop_time.getMinutes());
      req.start_datetime = d1;
      req.stop_datetime = d2;
      if (req.mode == this.scheduleMode.EveryWeek) {
        req.start_day = this.start_day;
        req.stop_day = this.stop_day;
      }
    }
    let board_list = [];

    console.log(this.boardAddList)
    this.boardAddList.forEach(b => {
      let board = new AddBoardsignSchedule();
      board.boardsign_structure = b.station.boardsign_structure;
      board.boardsign_id = b.id;
      let items = [];
      b.layers_new.forEach(l => {
        l.forEach(s => {
          let sign = new AddScheduleItem();
          sign.sign_id = s.id;
          sign.sign_no = s.sign_no;
          if (s.media) {
            sign.media_id = s.media.id;
            sign.media_type = s.media.media_type;
          }
          items.push(sign);
        });
      });
      board.items = items;
      board_list.push(board);
    });
    req.board_list = board_list;
    console.log(req);

    this.scheduleService.addSchedule(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่ม Schedule สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBoardsignShedule();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editSchedule() {
    this.is_loading = true;
    let req = new AddSchedule();
    req.color = `${this.color_modal.primary}|${this.color_modal.secondary}`;
    req.mode = +this.mode_modal.id;
    req.name = this.input_name;

    if (req.mode == this.scheduleMode.NotRepeat) {
      req.start_datetime = this.start_datetime;
      req.stop_datetime = this.stop_datetime;
    } else {
      let d1 = new Date(this.start_datetime.getFullYear(), this.start_datetime.getMonth(), this.start_datetime.getDate(), this.start_time.getHours(), this.start_time.getMinutes());
      let d2 = new Date(this.stop_datetime.getFullYear(), this.stop_datetime.getMonth(), this.stop_datetime.getDate(), this.stop_time.getHours(), this.stop_time.getMinutes());
      req.start_datetime = d1;
      req.stop_datetime = d2;
      if (req.mode == this.scheduleMode.EveryWeek) {
        req.start_day = this.start_day;
        req.stop_day = this.stop_day;
      }
    }
    let board_list = [];
    this.boardAddList.forEach(b => {
      let board = new AddBoardsignSchedule();
      board.boardsign_structure = b.station.boardsign_structure;
      board.boardsign_id = b.id;
      let items = [];
      b.layers_new.forEach(l => {
        l.forEach(s => {
          let sign = new AddScheduleItem();
          sign.sign_no = s.sign_no;
          sign.sign_id = s.id;
          if (s.media) {
            sign.media_id = s.media.id;
            sign.media_type = s.media.media_type;
          }
          items.push(sign);
        });
      });
      board.items = items;
      board_list.push(board);
    });
    req.board_list = board_list;
    this.scheduleService.editSchedule(req, this.schedule_select.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไข Schedule สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBoardsignShedule();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  deleteSchedule() {
    this.is_loading = true;
    let req = new AddSchedule();
    req.color = `${this.color_modal.primary}|${this.color_modal.secondary}`;
    req.mode = +this.mode_modal.id;
    req.name = this.input_name;
    if (req.mode == this.scheduleMode.NotRepeat) {
      req.start_datetime = this.start_datetime;
      req.stop_datetime = this.stop_datetime;
    } else {
      let d1 = new Date(this.start_datetime.getFullYear(), this.start_datetime.getMonth(), this.start_datetime.getDate(), this.start_time.getHours(), this.start_time.getMinutes());
      let d2 = new Date(this.stop_datetime.getFullYear(), this.stop_datetime.getMonth(), this.stop_datetime.getDate(), this.stop_time.getHours(), this.stop_time.getMinutes());
      req.start_datetime = d1;
      req.stop_datetime = d2;
      if (req.mode == this.scheduleMode.EveryWeek) {
        req.start_day = this.start_day;
        req.stop_day = this.stop_day;
      }
    }
    let board_list = [];
    this.boardAddList.forEach(b => {
      let board = new AddBoardsignSchedule();
      board.boardsign_structure = b.station.boardsign_structure;
      board.boardsign_id = b.id;
      let items = [];
      b.layers_new.forEach(l => {
        l.forEach(s => {
          let sign = new AddScheduleItem();
          sign.sign_id = s.id;
          if (s.media) {
            sign.media_id = s.media.id;
            sign.media_type = s.media.media_type;
          }
          items.push(sign);
        });
      });
      board.items = items;
      board_list.push(board);
    });
    req.board_list = board_list;
    req.status = StatusEnum.Delete;
    this.scheduleService.editSchedule(req, this.schedule_select.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบ Schedule สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBoardsignShedule();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  setColorStyles(c) {
    let styles = {
      'background-color': c,
      'height': '15px',
      'width': '15px',
      'margin-right': '5px',
      'border-radius': '10px'
    };
    return styles;
  }
  clickSign(sign: any, board: any) {
    this.sign_select = sign;
    this.boardsign_select = board;
    this.mediaListModal.show();
  }
  closeMediaModalChangeMediaNew(e) {
    if (e) {
      this.boardAddList.forEach(b => {
        if (b.id == this.boardsign_select.id) {
          b.layers_new.forEach(l => {
            l.forEach(s => {
              if (s.id == this.sign_select.id) {
                s.media = e;
                if(e.media_type != MediaType.Playlist){
                  s.media.media = e;
                }
              }
            });
          });
        }
      });
    }
    this.mediaListModal.hide();
    this.sign_select = null;
  }
  deleteBoardAddList(item: any) {
    const index: number = this.boardAddList.indexOf(item);
    if (index !== -1) {
      this.boardAddList.splice(index, 1);
    }
  }
  //--------------------------------------------------------------------------------------------------------//
  board_select_list_search: any[] = [];
  itemList: BoardsignInfo[] = [];
  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;
  connectionStatusEnum = ConnectionStatusEnum;
  mediaType = MediaType;
  signDir = SignDirection;
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  openSearchBoardModal() {
    this.board_select_list_search = this.boardAddList.map(a => a.id);
    this.searchBoardsignModal.show();
    this.searchBoard();
  }
  checkValue(e: any, id: number) {
    const list = this.itemList.filter(x => x.check == true);
    this.board_select_list_search = list.map(a => a.id);
  }
  searchBoard(): void {
    this.currentPage = 1;
    this.searchBoardsign(this.currentPage, this.itemsPerPage);
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.searchBoardsign(this.currentPage, this.itemsPerPage);
    }
  }
  clickClearBoardsignSelect() {
    this.board_select_list_search = [];
    this.itemList.forEach(item => {
      item.check = false;
    });
  }
  onHideSearchBoardsignModal() {
    this.board_select_list_search = [];
  }
  async addSelectBoard() {
    let filters = {
      id: this.board_select_list_search
    };
    const list = this.multiFilter(this.boardAddList, filters);
    this.boardAddList = _.cloneDeep(list);
    const old_id = this.boardAddList.map(x => x.id);
    const new_id = _.xor(this.board_select_list_search, old_id);
    if (new_id.length > 0) {
      const res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, new_id).toPromise();
      if (res) {
        if (res.code == 1) {
          const n = await this.genSignsLayerView(res.data.data, false);
          n.forEach(item => {
            this.boardAddList.push(item);
          });
        }
      }
    }
    this.searchBoardsignModal.hide();
  }
  multiFilter(array, filters) {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter((item) => {
      // dynamically validate all filter criteria
      return filterKeys.every(key => !!~filters[key].indexOf(item[key]));
    });
  }
  async searchBoardsign(skip?: number, take?: number) {
    this.is_loading_search = true;
    this.itemList = [];
    let name = null;
    let route = null;
    let status = null;
    let st_km = null;
    let en_km = null;
    let st_m = null;
    let en_m = null;
    if (this.search_name)
      name = this.search_name;
    if (this.search_route)
      route = this.search_route;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_km_st)
      st_km = parseInt(this.search_km_st);
    if (this.search_km_en)
      en_km = parseInt(this.search_km_en);
    if (this.search_m_st)
      st_m = parseInt(this.search_m_st);
    if (this.search_m_en)
      en_m = parseInt(this.search_m_en);
    let res = await this.boardsignService.getBoardsign(skip, take, name, route, null, status, st_km, en_km, st_m, en_m).toPromise();
    this.itemList = [];
    if (res) {
      if (res.code == 1) {
        this.itemList = await this.genSignsLayerView(res.data.data, false, this.board_select_list_search);
        this.totalItems = res.data.total_items;
      } else { }
    }
    this.is_loading_search = false;
  }
  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => {
      });
  }
  handleValueStatus(value) {
    if (value) {
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }
  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
}
