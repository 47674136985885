import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CorridorControlComponent } from './corridor-control/corridor-control.component';
import { CameraStreamComponent } from './camera-stream/camera-stream.component';
import { CorridorControlM7Component } from './corridor-control-m7/corridor-control-m7.component';

export let routes: Routes = [
  {
    path: '',
    redirectTo: 'control',
    pathMatch: 'full',
  },
  {
    path: 'control',
    component: CorridorControlM7Component
  },
  {
    path: 'stream',
    component: CameraStreamComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CorridorControlRoutingModule { }
