import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { MonitorComponent } from './monitor/monitor.component';
import { StationComponent } from './station/station.component';
import { DeviceComponent } from './device/device.component';
import { DeviceTypeComponent } from './device-type/device-type.component';
import { DeviceGroupComponent } from './device-group/device-group.component';
import { VendorComponent } from './vendor/vendor.component';
import { OwnerComponent } from './owner/owner.component';
import { BrandComponent } from './brand/brand.component';
import { MaTicketComponent } from './ma-ticket/ma-ticket.component';
export let routes: Routes = [
  {
    path: '',
    redirectTo: 'monitor',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'monitor',
        component: MonitorComponent
      },
      {
        path: 'station',
        component: StationComponent
      },
      {
        path: 'device',
        component: DeviceComponent
      },
      {
        path: 'device-type',
        component: DeviceTypeComponent
      },
      {
        path: 'device-group',
        component: DeviceGroupComponent
      },
      {
        path: 'vendor',
        component: VendorComponent
      },
      {
        path: 'owner',
        component: OwnerComponent
      },
      {
        path: 'brand',
        component: BrandComponent
      },
      {
        path: 'ma',
        component: MaTicketComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AssetsManagementRoutingModule { }
