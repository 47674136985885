import { Directive, Input, HostBinding } from '@angular/core'
@Directive({
    selector: '[imgloadfail]',
    host: {
        '(error)': 'updateUrl()',
        '(load)': 'load()',
        '[src]': 'src'
    }
})

export class ImagePreloadDirective {
    @Input() src: string;
    @Input() default: string;
    @HostBinding('class') className

    updateUrl() {
        this.src = this.default;
    }
    load() {
        this.className = 'rounded';
    }
}