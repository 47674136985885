export const navItems = [
    {
        id: 1200,
        name: 'Corridor Control',
        url: `/corridor-control`,
        icon: 'fa fa-road',
    },
    {
        id : 200,
        name: 'Sign Control',
        url: '/sign-control',
        icon: 'fa fa-clone',
    },
    {
        id : 800,
        name: 'Assets Management',
        url: '/assets-management',
        icon: 'fa fa-cog',
    },
    {
        id : 1000,
        name: 'User Management',
        url: '/user-management',
        icon: 'fa fa-user-circle-o',
    },
    {
        id : 1100,
        name: 'Traffic tool',
        url: '/traffictool',
        icon: 'fa fa-road',
    },{
        id: 100,
        name: 'Event ',
        url: `/event`,
        icon: 'fa fa-exclamation-triangle',
    }
    // {
    //     id: 900,
    //     name: 'Information',
    //     url: `/information`,
    //     icon: 'icon-info',
    // },
];
