import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { SignDirection, MediaType } from 'src/app/models/sign-control/enum';
import { MediaInfo } from 'src/app/models/sign-control/media';
import { FavoriteInfo } from 'src/app/models/sign-control/favorite';
import { FavoriteService } from 'src/app/services/sign-control/favorite.service';
import * as _ from 'lodash';
import { StatusEnum } from 'src/app/models/common-enum';

@Component({
  selector: 'app-favorite-selector',
  templateUrl: './favorite-selector.component.html',
  styleUrls: ['./favorite-selector.component.scss']
})
export class FavoriteSelectorComponent implements OnInit , OnChanges {
  public mediaType = MediaType;
  isLoading = false;
  selectedFav: FavoriteInfo;
  totalItems: number;
  countItems: number;
  signDir = SignDirection;
  @Input() sign_height: number;
  @Input() boardsign_id: number;
  @Input() is_show: boolean;
  @Input() structure: string;
  set_sign_height: number = 40;
  @Output() onComponentClosed: EventEmitter<FavoriteInfo> = new EventEmitter<FavoriteInfo>();

  favList: FavoriteInfo[];
  constructor(private favoriteService: FavoriteService) {
  }

  ngOnInit(): void {
    if (this.sign_height) {
      this.set_sign_height = this.sign_height;
    }
  }
  get_sign_width(ratio: string, sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return sign_height * r;
    } else {
      return 0;
    }
  }
  async getFav() {
    this.isLoading = true;
    this.favList = [];
    this.favoriteService.getFavorite(null, null,this.boardsign_id,this.structure).subscribe(
      res => {
        if (res.code == 1) {
          res.data.data.forEach(fav => {
            // layers
            //const layers_text = fav.boardsign_structure.split("|");
            let layers = [];
            fav.items = fav.items.filter(x => x.status == StatusEnum.Active);
            const layer_no_list = _.sortedUniq(fav.items.map(a=>a.layer_no));
            layer_no_list.forEach((l, index) => {
              // signs
              let signs = [];
              let find_sign = fav.items.filter(x => x.layer_no == l);
              if (find_sign) {
                find_sign.forEach(s => {
                  if (s.media) {
                    let d = { sign_no: s.sign_no, sign_type: s.sign_type_id, ratio: s.ratio, dir: s.dir, media: s.media };
                    signs.push(d);
                  } else {
                    let d = { sign_no: s.sign_no, sign_type: s.sign_type_id, ratio: s.ratio, dir: s.dir, media: null };
                    signs.push(d);
                  }

                });
                signs = _.sortBy(signs, ['sign_no']);
                layers.push(signs);
              }
            });
            fav.layers = layers;
          });
          this.favList = res.data.data;
        }
        this.isLoading = false;
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.isLoading = false;
      });
  }
  select(fav: FavoriteInfo) {
    this.selectedFav = fav;
  }

  closeComponent(fav: FavoriteInfo) {
    if (fav) {
      this.onComponentClosed.emit(fav);
    }
    else {
      this.onComponentClosed.emit(null);
    }
    this.selectedFav = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.is_show.currentValue) {
      this.selectedFav = null;
      this.getFav();
    }
  }

  getSignNameTextStyles(w?: number) {
    let styles = {
      'width': w + 'px',
      'max-width': w + 'px',
      'white-space': 'nowrap',
      'overflow': 'hidden',
      'text-overflow': 'ellipsis'
    };
    return styles;
  }

}