import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { EventComponent } from './event/event.component';
// import { IncidentTypeComponent } from './incident-type/incident-type.component';
// import { EventMapComponent } from './event-map/event-map.component';
export const routes: Routes = [{
  path: '',
  redirectTo: 'map',
  pathMatch: 'full',
},
{
  path: '',
  component: HomeComponent,
  children: [
    {
      path: 'list',
      component: EventComponent,
    },
    // {
    //   path: 'map',
    //   component: EventMapComponent,
    // },
    // {
    //   path: 'incident/type',
    //   component: IncidentTypeComponent,
    // },
    
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventRoutingModule { }
