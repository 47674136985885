export class PagableData<T> {
    total_items: number;
    total_pages: number;
    page_size: number;
    data: T;
}

export class ApiResponse<T> {
    code: number;
    message: string;
    message_friendly: string;
    data: T;
}
export class ItemName {
    id: number;
    name: string;
}

export class Province {
    province_id: number;
    province_name: string;
}

export class District {
    dist_id: number;
    dist_name: string;
}

export class Img {
    url?: string;

    file?: File; // for view
    dataurl?: string; // for view
    delete?: boolean;
}
export class ImgUploadResponse {
    img_urls?: string[];
}
export class FileUploadResponse {
    file_urls?: string[];
}