import { SignTypeInfo } from './sign-type';
import { DeviceGroup } from '../assets-management/device-group';
import { Station } from '../assets-management/station';
import { MediaInfo, SignMediaInfo } from './media';
import { ConfigRoadByLane } from "./sign-setting";

export class BoardsignInfo {
    id?: number;
    station_id?: number;
    group_id?: number;
    mqtt_topic?: string;
    lane_count?: number;
    name?: string;
    status?: number; //0 = Disable, 1 = Enable 
    device_status?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    signs?: SignInfo[];
    signs_suggest?: SignInfo[];
    signs_new?: SignInfo[];
    station?: StationInfo;
    device_group?: DeviceGroup;
    auto_suggestion?: boolean;
    connection_status?: number;
    device?: CctvDevice[];
    sensor_id?: number;
    sensor_name?: number;
    //structure?: string;

    // UI
    layers?: Array<StructureLayerInfo[]>;
    layers_suggest?: Array<StructureLayerInfo[]>;
    layers_new?: Array<StructureLayerInfo[]>;
    check?: boolean;
    icon_map_id?: number;
    icon?: string;
    traffic_info_view?: TrafficInfoLayerView;
    camera?: BoardsignCamera[];
    is_dir_back?:boolean;

    boardsign_structure?:string;

    config_road?: ConfigRoadByLane[];

}
export class FirmwareInfo {
    boardsign_id?: number;
    boardsign_name?: string;
    firmware_id?: number;
    firmware_url?: string;
    version?: string;
    desc?: string;
    ts?: Date;
}

export class BoardsignCamera{
    id?:number;
    name?:string;
    url?:string;
}
export class StationInfo {
    id?: number;
    createdate?: Date;
    lastupdate?: Date;
    station_name?: string;
    route_id?: number;
    km?: number;
    m?: number;
    direction?: number; // 1=IN, 2=OUT, 3=CENTER 
    latitude?: number;
    longitude?: number;
    province_id?: number;
    district_id?: number;
    responsible_id?: number;
    status?: number; // 0 = inactive, 1 = active
    license_plate_no?: string;
    license_plate_prov?: string;
    chassis_no?: number;
    boardsign_structure?: string;
    station_type?: number; // 0 = Static, 1 = Mobility 
}
export class CctvDevice {
    device_id: number;
    link: string;
    view: string;
    rtsp_link: string;
    cctv_flv: string;

    name: string;

}
export class TrafficInfoLayerView {
    front_layer: Array<StructureLayerInfo[]>;
    back_layer: Array<StructureLayerInfo[]>;
}
export class SignInfo {
    id?: number;
    boardsign_id?: number;
    sign_type_id?: number; // sign-type id
    sign_type?: number;
    sign_type_object?: SignTypeInfo;
    position_x?: number;
    position_y?: number;
    cover_lane?: string; //EX. 1|2
    direction?: number; // 0 = Front, 1 = Back 
    ref_id?: number;
    ref_type?: number;
    status?: number; //0 = Disable, 1 = Enable
    create_date?: Date;
    lastupdate_date?: Date;
    layer_no?: number;
    sign_no?: number;
    media?: SignMediaInfo;
}

export class ReqAutoSuggestion {
    boardsign_id?: number;
    auto_suggestion?: boolean;
}

export class AddBoardsign {
    id?: number;
    station_id?: number;
    group_id?: number;
    mqtt_topic?: string;
    lane_count?: number;
    name?: string;
    status?: number; //0 = Disable, 1 = Enable
    //signs?: SignInfo[];

    auto_suggestion?: boolean;
}

export class ReqAddSign {
    id?: number;
    sign_type?: number;
    position_x?: number;
    position_y?: number;
    cover_lane?: string;
    direction?: number; // 0 = Front, 1 = Back 
    status?: number;
}

export class Structure {
    name?: string;
    structure?: string;
}

// View
export class StructureInfo {
    fav_id?: number;
    name?: string;
    boardsign_structure?: string;
    layers?: Array<StructureLayerInfo[]>;
}
export class StructureLayerInfo {
    id?: number;
    sign_no?: number;
    board_type?: number;
    ratio?: string;
    dir?: number;
    data?: SignInfo;
    media?: SignMediaInfo;
    media_id?: number;
    media_type?: number;
}

export class BoardTypeInfo {
    aspect_ratio: string;
    create_date: Date;
    height: number;
    id: number;
    lastupdate_date: Date;
    name: string;
    status: number;
    width: number;
}

export class BoardSignBrightness {
    time_st: string;
    time_en: string;
    percent: number;
    st?: Date;
    en?: Date;
}

export class SensorConfig {
    lane?: number;
    queue_dir?: number;
    queue_length?: number;
    class_list?: SensorConfigClass[];
    is_over_speed?: boolean;
    is_wrong_lane?: boolean;
}
export class SensorConfigClass {
    class?: number;
    class_name?: string;
    is_allow?: boolean;
    suggest_speed?: number;
    over_speed?: number;
    rain_suggest_speed?: number;
    rain_over_speed?: number;
}
// export class SensorMediaEventInfo {
//     id?: number;
//     sign_id?: number;
//     event_name?: string;
//     blink?: boolean;
//     duration?: number;
//     signal?: SensorEvent;
//     media?: MediaEventInfo;
//     sign?: SignEvent;
// }
// export class SignEvent {
//     x?: number;
//     y?: number;
//     width?: number;
//     height?: number;
// }
// export class SensorEvent {
//     event_type?: number; // 1:ความเร็วเกิน 2:ผิดช่องจราจร
//     class?: number;  // ประเภทรถ
//     lane?: number;
//     rain?: number;
// }

// export class MediaEventInfo {
//     url?: string;
//     uri?: string;
//     content_type?: string;
//     content_type_id?: number;
//     behavior_type?: number;
//     special_property?: SpecialPropInfo;
// }
// export class SpecialPropInfo {
//     text_items?: SpecialTextItem[];
// }
// export class SpecialTextItem {
//     text_type: number;
//     text?: string;
//     font_size: number;
//     color?: string;
//     font_family?: string;
//     font_weight?: string;
//     x: number;
//     y: number;
//     is_center?: boolean;
// }
