import { Component, OnInit, EventEmitter, ViewChild, ElementRef,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash'
import * as moment from 'moment'
import { EventService } from 'src/app/services/user/event.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DeviceService } from 'src/app/services/user/device.service';
import { PreventableEvent } from '@progress/kendo-angular-dateinputs';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/event/menu';
import { FuncService } from 'src/app/services/user/func.service';
// import { EventDetailComponent } from '../../event-detail/event-detail.component';
import { evt_status } from '../../values';
import { on_img_base64 } from '../../menu';
// import * as pdfMake from 'pdfmake/build/pdfmake.js';
// declare var pdfFonts: any;
// pdfMake.vfs = pdfFonts.vfs;
import { DatePipe } from '@angular/common';
// import { ActionEnum } from 'src/app/models/common-enum';
import { ApiService } from 'src/app/services/user/api.service';
@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent implements OnInit {
  @ViewChild('blogBG') private scrollBG: ElementRef;
  modalRef_Detail: BsModalRef;
  allowCustom: boolean = false;
  public event: EventEmitter<any> = new EventEmitter();
  values: any;
  list: any;
  inc_type: any;
  incident_type: any;
  changed: boolean = false
  type: any = null
  name: any
  desc: any
  chkMenu: number = 1
  src_type: any
  src_type_etc: any
  occur_type: any
  asp_type: any
  dir: any
  dirBT: any = 1
  veh_dmg_list: any = []
  st_date_in: any
  en_date_in: any
  route: any
  data_route: any
  route_list: any
  search_route: any
  search_route2: any
  km1: number;
  m1: number;
  km2: number;
  m2: number;
  dir1: number;
  dir2: number;
  data_list: string;
  incall_type: any
  act_list: any = []
  images: any = []
  msg: any;
  images_evtdetail: any = []
  lat2: any;
  lng2: any;
  mk_BT: number = 1
  disabled: boolean = false
  vv = {
    edit_detail_id: 104,
    edit_detail: {
      add_data_bank_id: 105,
      add_news_id: 106,
      manage_inc_id: 107
    },
    activity_log_id: 108,
    activity_log: {
      manage_inc_id: 109
    },
    news_id: 111,
    news: {
      add_id: 112,
      info_id: 113,
      edit_id: 114,
      manage_id: 115,
    },
    sign_control_id: 116,
    sign_control: {
      calendar_id: 117,
      change_media_id: 118
    }
  }
  isEditDetail: boolean = false;
  isAddDataBank: boolean = false;
  isAddNews: boolean = false;
  isManageInc: boolean = false;
  isActivityLog: boolean = false;
  isActivityLogManageInc: boolean = false;
  isNews: boolean = false;
  isSignControl: boolean = false;
  isReport: boolean = true;


  _getReport: boolean = false
  statusList: any = evt_status
  evtConfirmList: any = []
  evtConfirm: number = null
  notes: string = ''
  
  group_id: any
  groupList: any
  modalRef?: BsModalRef
  comment : string = ''
  _approved : boolean = false
  
  images_Evtdetail: any = []
  imagesAll_Evtdetail: any = []
  
  size_Img : number = 100
  occur_datetime: any
  constructor(
    // private modalService: BsModalService,
    private evt_S: EventService,
    private bsModalRef: BsModalRef,
    private modalS: BsModalService,
    private router: Router,
    private devS: DeviceService,
    private commonService: CommonService,
    private func: FuncService,
    private datePipe: DatePipe,
    private apiService: ApiService,
  ) {
  }

  async ngOnInit() {
    this.isEditDetail = await this.commonService.getPermission(permission.incident.detail.edit_detail_id).toPromise();
    this.isAddDataBank = await this.commonService.getPermission(permission.incident.detail.edit_detail.add_data_bank_id).toPromise();
    this.isAddNews = await this.commonService.getPermission(permission.incident.detail.edit_detail.add_news_id).toPromise();
    this.isManageInc = await this.commonService.getPermission(permission.incident.detail.edit_detail.manage_inc_id).toPromise();

    this.isActivityLog = await this.commonService.getPermission(permission.incident.detail.activity_log_id).toPromise();
    this.isActivityLogManageInc = await this.commonService.getPermission(permission.incident.detail.activity_log.manage_inc_id).toPromise();

    this.isNews = await this.commonService.getPermission(permission.incident.detail.news_id).toPromise();
    this.isSignControl = await this.commonService.getPermission(permission.incident.detail.sign_control_id).toPromise();
    this.isReport = await this.commonService.getPermission(permission.incident.delete_id).toPromise();

    if (this.values) {
      await this.getDetail()
      this.notes = this.values.notes||''
      this.asp_type = this.values.loc_type||0
      this.src_type = this.values.src_type_id||''
      await this.getGroup()
      this.group_id = this.values.event_group_id
      // this.disabled = true
      this.getImg(this.values.id)
      this.search_route = (this.values.st_route) ? (this.values.st_route + '').padStart(4, '0') : null
      this.km1 = (this.values.st_km||this.values.st_km==0) ? this.values.st_km : null
      this.m1 = (this.values.st_m||this.values.st_m==0) ? this.values.st_m : null
      this.search_route2 = (this.values.en_route) ? (this.values.en_route + '').padStart(4, '0') : null
      this.km2 = (this.values.en_km||this.values.en_km==0) ? this.values.en_km : null
      this.m2 = (this.values.en_m||this.values.en_m==0) ? this.values.en_m : null
      this.evtConfirm = (this.values.event_confirm) ? this.values.event_confirm : null
      this.dir1 = this.values.st_dir
      this.dir2 = this.values.en_dir
      let od : Date = this.values.occur_datetime
      this.occur_datetime = this.values.occur_datetime? new Date(od):null
      // this.imagesAll_Evtdetail = _.cloneDeep(this.values.img_evt)
      // this.images_Evtdetail = _.slice(this.imagesAll_Evtdetail,0,this.size_Img)
      // console.log(this.images_Evtdetail)
      this.getConfirmList()
      this.getMsg()
    }
    this.getData()
  }
  async getImg(id: number) {
    let allImg: any = []
    let res: any = await this.evt_S.getEvtImg({ event_id: id })
    if (res) {
      for (const i of res) {
        allImg.push(i.url)
      }
      this.images_Evtdetail = _.slice(allImg, 0, 35)
      this.values.img_evt = allImg
    }
  }
  async getGroup(){
    this.groupList = await this.evt_S.getEvtGroup()
  }
  async getDetail() {
    let res: any = await this.evt_S.getEvt({ id: this.values.id })
    if (res) {
      res.data[0].status_name = this.values.status ? (evt_status.find(v => v.id == this.values.status)).name : ''
      this.values = res.data[0]
    }
  }
  clickImg(path: string) {
    this.func.alertpopup(`<img src="${path}" alt"event" onerror="this.src='assets/noimage.png'">`, "Image")
  }
  ngAfterViewInit() {

  }
  async getData() {
    this.route_list = await this.devS.getRoute()
    this.route = this.route_list
  }
  async save() {
    let data : any = {
      id:this.values.id,
      loc_type:this.asp_type,
    }
    if(this.occur_datetime) data.occur_datetime=moment(this.occur_datetime).toISOString();
    if(this.group_id) data.event_group_id=Number(this.group_id)
    if(this.src_type) data.src_type_id=Number(this.src_type)
    if(this.search_route) data.st_route=Number(this.search_route)
    if(this.dir1) data.st_dir=this.dir1
    if(this.km1 || this.km1 == 0) data.st_km=Number(this.km1)
    if(this.m1 || this.m1 == 0) data.st_m=Number(this.m1)
    if(this.search_route2) data.en_route=Number(this.search_route2)
    if(this.dir2) data.en_dir=this.dir2
    if(data.st_dir == 'null') data.st_dir = null
    if(data.en_dir == 'null') data.en_dir = null
    if(data.loc_type == 'null') data.loc_type = null
    if(this.km2 || this.km2 == 0) data.en_km=Number(this.km2)
    if(this.m2 || this.m2 == 0) data.en_m=Number(this.m2)
    if(this.notes) data.notes = this.notes||''
    let res : any = await this.evt_S.addevt(data)
    if(res){this.func.alertpopup("Success");this.confirm()}
  }
  async updateEvt(approved?:boolean){
    
    let data : any = {
      comment : this.comment,
    }
    if(this.values.id) data.id=this.values.id
    if(approved) {data.approved = 1;data.status = 3}
    if(!approved) {data.status = 4}
    if(approved){
      let _is: any = await this.evt_S.updateEvtApprove(data)
    }
    let res: any = await this.evt_S.addevt(data)
    if (res) {
      this.changed = true
      if (data.status) this.values.status = data.status;
      // this.func.alertpopup("Success");
      this.modalRef_Detail?.hide()
      this.getDetail()
    }
  }
  close(chk?: any) {
    if (chk && !_.isEmpty(this.values)) {
      this.confirm()
    } else {
      this.bsModalRef.hide();
    }
  }
  openRemark(template: TemplateRef<any>) {
    this.modalRef_Detail = this.modalS.show(template, {id: 2, class: 'second' });
  }
  async chgStatus() {
    this.evtConfirm = Number(this.evtConfirm)
    let data: any = {
      event_id: this.values.id,
      event_confirm: this.evtConfirm,
    }
    switch (this.evtConfirm) {
      case 1:
      case 4:
      case 6:
        data.status = 2; data.approved = 1;
        data.status = 2; data.approved = 1;
        break;
      case 2:
        data.status = 4; data.approved = 1;
        break;
      case 3:
      case 7:
      case 5:
        data.status = 4; data.approved = 0;
        break;
      default:
      // code block
    }
    let res: any = await this.evt_S.updateEvtApprove(data)
    if (res) {
      this.changed = true
      if (data.status) this.values.status = data.status;
      this.func.alertpopup("Success");
      this.getDetail()
    }

  }
  async getConfirmList() {
    this.evtConfirmList = await this.evt_S.getEvtConfirm(this.values.event_type_id)
  }
  async sendMsg() {
    let data = {
      msg: this.msg,
      evt_id: this.values.id,
    }
    const req1 = _.cloneDeep(data);
    let res: any = await this.evt_S.addEvtMsg(data)
    if (res) { 
      if (req1.evt_id) delete req1.evt_id
      const log_req_data = { request: req1, response: res, url: `${this.apiService.api.event}${this.apiService.api.msg}/${data.evt_id}` };
      // this.commonService.activityLog(ActionEnum.Add, 'Event Management ➡ การดำเนินการ').subscribe(res => { }, error => { console.log(error); });
      this.msg = ''; this.getMsg(); this.scrollToBottom() 
    }
  }
  async getMsg() {
    let res = await this.evt_S.getEvtMsg(this.values.id)
    if (res) { this.act_list = res; }
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.scrollBG.nativeElement.scrollTop = this.scrollBG.nativeElement.scrollHeight;
      }, 500);
    } catch (err) { }
  }
  confirm() {
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel() {
    if (this.changed) { this.confirm() }
    else {
      this.event.emit(false);
      this.bsModalRef.hide();
    }
  }
  gotoSign() {
    let data: any = { fromIncident: true }
    if (this.values.route) data.route = (this.values.route + '').padStart(4, '0')
    if (this.values.km) data.st_km = this.values.km
    if (this.values.m) data.st_m = this.values.m
    if (this.values.km) data.en_km = this.values.km
    if (this.values.m) data.en_m = this.values.m
    this.router.navigate([`sign-control/sign`, data]);
    this.bsModalRef.hide();
  }
  FilterType(evt: any) {
    if (evt.length > 0) {
      this.inc_type = this.inc_type.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.inc_type = this.incall_type
    } else {

    }
  }
  FilterRoute(evt: any) {
    if (evt.length > 0) {
      this.route_list = this.route_list.filter(res => res.includes(evt))
    } else if (evt.length == 0) {
      this.route_list = this.route
    } else {

    }
  }
  async ChageRoute(evt) {
    // console.log(evt)
  }
  changeIncType(evt) {
    // console.log(evt)
    this.incident_type = evt
  }
  selectedFile: ImageSnippet;
  async uploadFile(imageInput: any, type?: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    // const test_data1 = this.data
    reader.addEventListener('load', async (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file);
      let res: any = (type == 1) ? await this.evt_S.uploadImages(this.selectedFile.file, this.values.id) : await this.evt_S.uploadImages(this.selectedFile.file, this.values.id)
      if (res && res.img_urls.length) {
        for (const i of res.img_urls) {
          if (type == 1) {
            this.images_evtdetail.push(i)
          } else {
            this.images.push(i)
          }
        }
        // this.data.product_img_url_1 = res.path

        // this.data.ShopOwner.shop_owner_img = res.path;
        // console.log(this.data.ShopOwner.shop_owner_img)
      }

    });

    reader.readAsDataURL(file);
  }
  _selectedFile: ImageSnippet;
  async uploadImg(imageInput: any, type?: any) {
    
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    // const test_data1 = this.data
    reader.addEventListener('load', async (event: any) => {
      this._selectedFile = new ImageSnippet(event.target.result, file);
      let res: any = await this.evt_S.uploadImages(this._selectedFile.file, this.values.id)
      if (res && res.img_urls.length) {
        for (const i of res.img_urls) {
          if (type == 1) {
            this.images_Evtdetail.push(i)
          } else {
            this.images_Evtdetail.push(i)
          }
        }
      }

    });

    reader.readAsDataURL(file);
  }
  // getImg(){
  //   if(this.size_Img < this.imagesAll_Evtdetail.length){
  //     this.size_Img += 100
  //     this.images_Evtdetail = _.slice(this.imagesAll_Evtdetail,0,this.size_Img)
  //   }else{
  //     this.outofImg=true
  //   }
  // }
  
  async rmImage(path: string) {
    let res: any = await this.evt_S.rmImages(path)
    if (res) {
      this.images = _.pull(this.images, path);
    }
  }
  async rmlist() {
    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.evt_S.rmEvt(this.values.id)
      if (res) this.confirm()
    }
  }
  // openDetail() {
  //   const initialState = {
  //     values: this.values,
  //   };
  //   this.modalRef_Detail = this.modalS.show(EventDetailComponent, { initialState, id: 2, class: 'w-80 bd-BG', backdrop: 'static' });
  //   this.modalRef_Detail.content.event.subscribe(data => {
  //     this.getDetail()
  //   });
  // }

  getReport() {
    setTimeout(() => {
      this._getReport = false
    }, 2000);
  }

  //---------------------------------PDF---------------------------------//
  // is_loading_pdf = false;
  // async exportPdf() {
  //   this.is_loading_pdf = true;
  //   let content = [
  //     { text: `เหตุการณ์ : ${this.values.event_type_name || ''}`, style: 'title' },
  //     { text: `เมื่อ : ${this.datePipe.transform(this.values.occur_datetime, 'HH:mm dd/MM/yyyy')}`, style: 'title' },
  //     { text: `ที่มา : ${this.values.src_type_name || ''}`, style: 'title' },
  //     { text: `ตำแหน่งอุปกรณ์ : เส้นทาง ทล. ${this.values.route || ''} ${this.values.km || ''}+${this.values.m || ''}`, style: 'title' },
  //     { text: `การตรวจสอบ : ${this.values.event_confirm_name || ''}`, style: 'title' },
  //     { text: `รูปภาพประกอบ : `, style: 'title' },
  //   ];
  //   let i = 0;
  //   for (const item of this.images_evtdetail) {
  //     let is_last = false;
  //     if (i == this.images_evtdetail.length - 1) is_last = true;
  //     let im: any = {
  //       image: await this.getBase64ImageFromURL(item, is_last),
  //       width: 177,
  //       height: 100,
  //       margin: [20, 5, 20, 0]
  //     };
  //     content.push(im);
  //     i++;
  //   }
  //   content.push({ text: ` `, style: 'title' });
  //   content.push({ text: `การดำเนินการ : `, style: 'title' });
  //   this.act_list.forEach(item => {
  //     content.push({ text: `${this.datePipe.transform(item.created_datetime, 'dd/MM/yyyy HH:mm')} ${item.msg}`, style: 'title' });
  //   });

  //   let docDefinition = {
  //     pageSize: 'A4',
  //     pageOrientation: 'portrait',
  //     title: 'table.pdf',
  //     info: {
  //       title: 'Event Detail',
  //       author: 'DOH',
  //       subject: 'subject of document',
  //       keywords: 'keywords for document',
  //     },
  //     content: content,
  //     styles: {
  //       title: {
  //         fontSize: 16,
  //         bold: true,
  //         alignment: 'left',
  //         margin: [20, 0, 20, 0]
  //       }
  //     },
  //     defaultStyle: {
  //       font: 'THSarabunNew'
  //     }
  //   };
  //   pdfMake.fonts = {
  //     'Roboto': {
  //       normal: 'Roboto-Regular.ttf',
  //       bold: 'Roboto-Medium.ttf',
  //       italics: 'Roboto-Italic.ttf',
  //       bolditalics: 'Roboto-Italic.ttf'
  //     },
  //     'THSarabunNew': {
  //       normal: 'THSarabunNew.ttf',
  //       bold: 'THSarabunNew-Bold.ttf',
  //       italics: 'THSarabunNew-Italic.ttf',
  //       bolditalics: 'THSarabunNew-BoldItalic.ttf'
  //     }
  //   }

  //   await pdfMake.createPdf(docDefinition).open();
  //   if (this.images_evtdetail.length == 0){
  //     this.is_loading_pdf = false;
  //   }
  // }
  
  // getBase64ImageFromURL(url, isLast) {
  //   return new Promise((resolve, reject) => {
  //     var img = new Image();
  //     img.setAttribute("crossOrigin", "anonymous");

  //     img.onload = () => {
  //       var canvas = document.createElement("canvas");
  //       canvas.width = img.width;
  //       canvas.height = img.height;

  //       var ctx = canvas.getContext("2d");
  //       ctx.drawImage(img, 0, 0);

  //       var dataURL = canvas.toDataURL("image/png");

  //       if (isLast) this.is_loading_pdf = false;

  //       resolve(dataURL);
  //     };

  //     img.onerror = error => {
  //       if (isLast) this.is_loading_pdf = false;
  //       resolve(on_img_base64);
  //       //reject(error);
  //     };

  //     img.src = url;
  //   });
  // }
}

class ImageSnippet {
  constructor(public src: string, public file: File) { }
}

