import { BoardTypeInfo } from './boardsign';
import { SignEventMediaSpecial } from './sign-setting';
export class MediaInfo {
    id?: number;
    sign_type_id?: number;
    sign_type_obj?: BoardTypeInfo;
    name?: string;
    path?: string;
    width?: number;
    height?: number;
    use_type?: number;
    is_public?: number;
    status?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    items?: PlaylistItem[]; // playlist
    boardsign_id?: number;

    media_type?: number;

    content_type?: string;
    behavior_type?: number;
    special_property?: SignEventMediaSpecial;
}
export class SignMediaInfo {
    id?: number;
    name?: string;
    media_type?: number;
    media?: MediaInfo;
    items?: PlaylistItem[]; // playlist
}
export class ReqChangeMedia {
    change_type?: number; // image:1, fav:2
    boardsign_id?: number;
    //เปลี่ยนทีละป้าย
    sign_id?: number;
    media_id?: number;
    media_type?: number;
    //เปลี่ยนโดยเลือกจากfav
    fav_id?: number;
}
export class ReqChangeMediaManyBoard {
    change_type?: number; // เปลี่ยนทีละป้าย:1, เปลี่ยนโดยเลือกจากfav:2
    boardsign_id?: number;
    //เปลี่ยนทีละป้าย
    signs?: ReqChangeMediaSign[];
    //เปลี่ยนโดยเลือกจากfav
    fav_id?: number;
}
export class ReqChangeMediaSign {
    //เปลี่ยนทีละป้าย
    sign_id?: number;
    media_id?: number;
    media_type?: number;
}
export class ReqAddImage {
    name?: string;
    sign_type_id?: number;
    img_url?: string;
    use_type?: number;
    width?: number;
    height?: number;
    ratio?: string;
    content_type?: string;
    media_type?: number;
    image_key?: string;
    behavior_type?: number;
    boardsign_id?: number;
    sign_id?: number;
    status?: number;
}
export class ReqAddGif {
    board_type?: number;
    name?: string;
    img_list?: PlaylistItemAdd[];
    is_public?: number;
    status?: number;
}
export class ReqAddPlaylist {
    playlist_id?: number;
    name?: string;
    sign_type_id?: number;
    use_type?: number;
    media_type?: number;
    behavior_type?: number;
    boardsign_id?: number;
    sign_id?: number;
    items?: PlaylistItemAdd[];
    status?: number;
}
export class PlaylistItemAdd {
    id?: number;
    image_id?: number;
    duration?: number;
}

// export class PlaylistInfo {
//     id?: number;
//     board_type?: number;
//     name?: string;
//     status?: number;
//     create_date?: Date;
//     lastupdate_date?: Date;
//     width?: number;
//     height?: number;
//     items?: PlaylistItem[];
// }
export class PlaylistItem {
    id?: number;
    playlist_id?: number;
    boardsign_image_id?: number;
    duration?: number;
    status?: number;
    create_date?: Date;
    lastupdate_date?: Date;
    boardsign_image?: MediaInfo;
}

//-----Media Template Generator------//
export class GenMediaTemplate {
    id?: string;
    width?: number;
    height?: number;
    children?: GenMediaItem[];
}
export class GenMediaItem {
    id?: string;
    width?: number;
    height?: number;
    top?: number;
    left?: number;
    text?: string;
    text_size?: number;
    img_url?: string;
    type?: number; // 0:none, 1:text, 2:image
}

export class SpecialTextItem {
    id?: number;
    idd?: number;
    text_type?: number;
    text?: string;
    font_size?: number;
    color?: string;
    font_family?: string;
    font_weight?: string;
    x?: number;
    y?: number;
    is_center?: boolean;
    class?: number;
    lane?: number;
}