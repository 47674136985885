import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ReversibleService {
  constructor(
    private api : ApiService,
  ) { }
      
  async getReversible(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.getReversible(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(res.data);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addReversible(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addReversible(req).subscribe((res:any)=>{
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async updateReversible(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.updateReversible(req).subscribe((res:any)=>{
        console.log(res)
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmRv(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmRv(req).subscribe((res:any)=>{
        console.log(res)
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async addReversibleSensor(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.addReversibleSensor(req).subscribe((res:any)=>{
        console.log(res)
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  async rmRvSs(req?:any){
    return new Promise((reslove,reject)=>{
      this.api.rmRvSs(req).subscribe((res:any)=>{
        console.log(res)
        if(res && res.code == 1){
          return reslove(true);
        }else{
          return reslove(false);
        }
      })
    })
  }
  // async addSmcConfigRoad(device_id?:any,req?:any){
  //   return new Promise((reslove,reject)=>{
  //     this.api.addSmcConfigRoad(device_id,{config:req}).subscribe((res:any)=>{
  //       if(res && res.code == 1){
  //         return reslove(res.data);
  //       }else{
  //         return reslove(false);
  //       }
  //     })
  //   })
  // }
}