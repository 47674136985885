import { Component, Input } from '@angular/core';
import { navItems } from './../../_nav';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { UserProfile } from '../../models/user';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
    //public menuItems = navItems;
    public menuItems = [];
    public sidebarMinimized = true;
    private changes: MutationObserver;
    public element: HTMLElement = document.body;

    public is_hidden_toggle_bt = true;
    private is_toggle_sidebar: boolean = true;

    user_info: any;

    currentUser: UserProfile;
    currentUserSubscription: Subscription;
    constructor(private authService: AuthService, private router: Router,
        private titleService: Title) {

        this.changes = new MutationObserver((mutations) => {
            this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
            if (this.is_toggle_sidebar) {
                this.is_toggle_sidebar = false;
                //this.commonService.setToggleSidebar(false);
            } else {
                this.is_toggle_sidebar = true;
                //this.commonService.setToggleSidebar(true);
            }
        });

        this.changes.observe(<Element>this.element, {
            attributes: true
        });

        router.events.subscribe((val) => {
            //this.updateRouter();
        });
        this.menuItems = [];
        this.currentUserSubscription = this.authService.currentUser.subscribe(user => {
            this.currentUser = user;
            if (user) {
                if (user.permission.length > 0) {
                    navItems.forEach(item => {
                        const find = user.permission.find(x => x == item.id);
                        if (find)
                        this.menuItems.push(item);
                    });
                }
            }

        });
    }

    logout() {
        if (this.currentUser) {
            this.authService.logout().subscribe(
                data => {
                    if (data.code == 1) {
                        this.router.navigate(['login']);
                    } else {
                        // this.addAlert(data.message);
                        this.router.navigate(['login']);
                    }
                },
                error => {
                    // this.addAlert(error);
                    this.router.navigate(['login']);
                });
        } else {
            this.router.navigate(['login']);
        }
    }
    updateRouter() {
        const route = this.router.url;
    }

    clickToggle() {
        const route = this.router.url;
    }
}
