<div class="modal-header">
    <h4 class="modal-title pull-left">ผู้ใช้งาน</h4>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" >
    <div class="row">
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <p class="input-group-text">Username  <span class="red-txt"> *</span></p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="username" [disabled]="_isDisabled">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <p class="input-group-text">Password  <span class="red-txt"> *</span></p>
                </div>
                <input type="pass"  class="form-control" [(ngModel)]="password" [disabled]="_isDisabled">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <p class="input-group-text">ชื่อ <span class="red-txt"> *</span></p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="firstname">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <p class="input-group-text">นามสกุล <span class="red-txt"> *</span></p>
                </div>
                <input type="text" class="form-control" [(ngModel)]="lastname">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <p class="input-group-text">หน่วยงาน <span class="red-txt"> *</span></p>
                </div>
                <input [typeahead]="ins_list" [(ngModel)]="ins_name" typeaheadOptionField="name" (focusout)="blur($event)" (typeaheadOnSelect)="onSelect($event)" class="form-control">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">ตำแหน่ง</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="rank">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Email</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="email">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">เบอร์โทร</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="tel">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">กลุ่มผู้ใช้งาน</span>
                </div>
                
                <input [typeahead]="group" [(ngModel)]="group_id" typeaheadOptionField="name" (focusout)="g_blur($event)" (typeaheadOnSelect)="onSelectGroup($event)" class="form-control">
              </div>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text">Hashtag</span>
                </div>
                <input type="text" class="form-control" [(ngModel)]="hashtag">
              </div>
        </div>
        <div class="col-12 text-left">
        <p class="mr-2">
          กลุ่มผู้ใช้งาน 
          <span class="mr-1" *ngIf="group_list.length == 0"> ไม่มีค่า </span>
          <span *ngFor="let i of group_list" class="mr-1 badge badge-pill badge-success group-BG" (click)="rmUserGroup(i.group_id)">{{i.group_name}} <span>x</span></span>
        </p>  
            <!-- <button class="btn btn-success">+</button> -->
        </div>
    </div>
  </div>
  <div class="modal-footer">
      <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
    <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
    <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
    <button type="button" class="btn btn-info" (click)="save()" [disabled]="(!username || !this.firstname || !this.lastname || !ins_id || (!this.password && !this.values)) ? 'disabled': null">ยืนยัน</button>
  </div>