import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { BoardsignInfo, SignInfo, SensorConfig, ReqAddSign } from 'src/app/models/sign-control/boardsign';
import * as _ from 'lodash';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MediaType, SignDirection } from 'src/app/models/sign-control/enum';
import { MediaInfo, ReqChangeMedia, ReqChangeMediaManyBoard, ReqChangeMediaSign } from 'src/app/models/sign-control/media';
import { BoardsignService } from 'src/app/services/sign-control/boardsign.service';
import { AddFavoriteItem, ReqAddFavorite, StructureInfo } from 'src/app/models/sign-control/favorite';
import { StatusEnum } from 'src/app/models/common-enum';
import { interval } from 'rxjs';
import { SignSettingService } from 'src/app/services/sign-control/sign-setting.service';
@Component({
  selector: 'app-change-media-panel',
  templateUrl: './change-media-panel.component.html',
  styleUrls: ['./change-media-panel.component.scss']
})
export class ChangeMediaPanelComponent implements OnInit, OnChanges {
  @Input() clickOpen: boolean;
  @Input() board_select_list: any[];
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('mediaListModal') public mediaListModal: ModalDirective;
  @ViewChild('favListModal') public favListModal: ModalDirective;
  @ViewChild('boardsignChangeMediaModal', { static: false }) public boardsignChangeMediaModal: ModalDirective;
  @ViewChild('checkBoardsignChangeMediaModal', { static: false }) public checkBoardsignChangeMediaModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  boardSelect: BoardsignInfo;
  signDir = SignDirection;
  mediaType = MediaType;
  res_message: string;
  is_loading: boolean = false;
  set_sign_height_small: number = 40;
  set_sign_height: number = 60;
  sign_select: any = null;
  control_filter_list: any[] = [];
  check_board_list: any[] = [];
  subscription: any;
  constructor(private boardsignService: BoardsignService, private signSettingService: SignSettingService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clickOpen) {
      if (changes.clickOpen.currentValue) {
        this.openBoardsignChangeMediaModal();
        this.is_change_me = false;
      }
    }
  }
  async openBoardsignChangeMediaModal() {
    this.is_loading = true;
    const res = await this.boardsignService.getBoardsignSuggest(this.board_select_list).toPromise().catch(
      //const res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, this.board_select_list).toPromise().catch(
      err => {
        console.log(JSON.stringify(err.statusText));
        this.is_loading = false;
      }
    );
    this.control_filter_list = [];
    if (res) {
      if (res.code == 1) {
        this.control_filter_list = await this.genSignsLayerView(res.data.data, true);
        this.control_filter_list.forEach(async (item) => {
          if (item.sensor_id && item.signs) {
            const res_config_road = await this.signSettingService.getConfigRoad(item.sensor_id).toPromise();
            item.config_road = res_config_road.data;
          }
        });
      }
    }
    this.is_loading = false;
    this.boardsignChangeMediaModal.show();
  }
  async openCheckBoardsignChangeMediaModal() {
    this.getCamera();
    this.subscription = interval(60000).subscribe((x) => {
      this.getCamera(true);
    });
    this.checkBoardsignChangeMediaModal.show();
  }
  async getCamera(is_update?: boolean) {
    if (!is_update) {
      this.is_loading = true;
    }
    //const res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, this.board_select_list).toPromise().catch(
    const res = await this.boardsignService.getBoardsignCamera(this.board_select_list).toPromise().catch(
      err => {
        console.log(JSON.stringify(err.statusText));
        this.is_loading = false;
      });
    if (!is_update) {
      this.check_board_list = [];
      if (res) {
        if (res.code == 1) {
          this.check_board_list = await this.genSignsLayerView(res.data.data, false, true);
        }
      }
    } else {
      if (res) {
        if (res.code == 1) {
          const boards = await this.genSignsLayerView(res.data.data, false, true);
          if (this.check_board_list.length > 0) {
            this.check_board_list.forEach(item => {
              const find = boards.find(x => x.id == item.id);
              if (find) {
                item.camera = find.camera;
                item.layers = find.layers;
              }
            });
          } else {
            this.check_board_list = boards;
          }
        }
      }
    }
    this.check_board_list.forEach(async (item) => {
      if (item.sensor_id && item.signs) {
        const res_config_road = await this.signSettingService.getConfigRoad(item.sensor_id).toPromise();
        item.config_road = res_config_road.data;
      }
    });
    this.is_loading = false;
  }
  closeBoardsignChangeMediaModal(is_change?: boolean) {
    this.boardsignChangeMediaModal.hide();
    if (is_change || this.is_change_me) {
      this.onClose.emit(true);
    } else {
      this.onClose.emit(null);
    }
  }
  is_change_me = false;
  closeCheckBoardsignChangeMediaModal() {
    this.checkBoardsignChangeMediaModal.hide();
    this.is_change_me = true;
    this.closeBoardsignChangeMediaModal(true);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  changeMediaToNow(board) {
    this.control_filter_list.forEach(b => {
      if (b.id == board.id) {
        b.layers_new = _.cloneDeep(b.layers)
      }
    });
  }
  changeMediaToSuggest(board) {
    this.control_filter_list.forEach(b => {
      if (b.id == board.id) {
        b.layers_new = _.cloneDeep(b.layers_suggest)
      }
    });
  }
  onHide() {

  }

  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }

  clickSign(sign: any, board: any) {
    this.sign_select = sign;
    this.boardSelect = board;
    this.mediaListModal.show();
  }
  clickFavList(board: any) {
    this.boardSelect = board;
    this.favListModal.show();
  }
  closeMediaModalChangeMediaNew(e) {
    if (e) {
      let req = new ReqChangeMedia();
      req.boardsign_id = this.boardSelect.id;
      req.sign_id = this.sign_select.id;
      req.media_type = e.media_type;
      req.media_id = e.id;
      this.control_filter_list.forEach(b => {
        if (b.id == req.boardsign_id) {
          b.layers_new.forEach(l => {
            l.forEach(s => {
              if (s.id == req.sign_id) {
                s.media = e;
                if (e.media_type != MediaType.Playlist) {
                  s.media.media = e;
                }
              }
            });
          });
        }
      });
    }
    this.mediaListModal.hide();
    this.sign_select = null;
  }

  closeFavModalChangeFavNew(e) {
    if (e) {
      let req = new ReqChangeMedia();
      req.boardsign_id = this.boardSelect.id;
      req.fav_id = e.id;
      console.log(e);
      this.control_filter_list.forEach(b => {
        if (b.id == req.boardsign_id) {
          b.layers_new.forEach((l, index) => {
            l.forEach(s => {
              const find_sign = e.items.find(x => x.sign_no == s.sign_no);
              if (find_sign) {
                s.media = find_sign.media;
                if (e.media_type != MediaType.Playlist) {
                  s.media.media = e;
                }
              }
            });
          });
        }
      });
    }
    this.favListModal.hide();
  }

  changeMediaManyBoard() {
    this.is_loading = true;
    let reqs: ReqChangeMediaManyBoard[] = [];
    this.control_filter_list.forEach(b => {
      let req = new ReqChangeMediaManyBoard();
      let signs: ReqChangeMediaSign[] = [];
      b.layers_new.forEach(l => {
        l.forEach(s => {
          let sign = new ReqChangeMediaSign();
          sign.sign_id = s.id;
          if (s.media) {
            sign.media_type = s.media.media_type;
            sign.media_id = s.media.id;
          }
          signs.push(sign);
        });
      });
      req.boardsign_id = b.id;
      req.signs = signs;
      reqs.push(req);
    });
    this.boardsignService.changeMediaManyBoard(reqs).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เปลี่ยนป้าย สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.openCheckBoardsignChangeMediaModal();
            }, 100);
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  async genSignsLayerView(boards?: BoardsignInfo[], is_suggest?: boolean, is_camera?: boolean) {
    boards.forEach(board => {

      if (board.signs) {
        board.signs = board.signs.filter(x => x.status == StatusEnum.Active);
        if (board.signs.length > 0 && board.station.boardsign_structure) {
          // layers
          //const layers_text = board.station.boardsign_structure.split("|");
          let layers = [];
          let layers_new = [];
          let layers_sug = [];
          const layer_no_list = _.sortedUniq(board.signs.map(a=>a.layer_no));
          layer_no_list.forEach((l, index) => {
            // signs
            let signs = [];
            let signs_new = [];
            let find_sign = board.signs.filter(x => x.layer_no == l);

            if (find_sign) {
              find_sign.forEach(s => {
                if (s.media) {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                  signs.push(d);
                } else {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                  signs.push(d);
                }
                if (is_suggest) {
                  signs_new.push({ id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null });
                }
              });
              signs = _.sortBy(signs, ['sign_no']);
              layers.push(signs);
              if (is_suggest) {
                signs_new = _.sortBy(signs_new, ['sign_no']);
                layers_new.push(signs_new);
              }
            }
            if (is_suggest) {
              if (board.signs_suggest) {
                board.signs_suggest = board.signs_suggest.filter(x => x.status == StatusEnum.Active);
                let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
                let signs_sug = [];
                if (find_sign_sug) {
                  find_sign_sug.forEach(s => {
                    if (s.media) {
                      let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: s.media };
                      signs_sug.push(d);
                    } else {
                      let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                      signs_sug.push(d);
                    }
                  });
                  signs_sug = _.sortBy(signs_sug, ['sign_no']);
                  layers_sug.push(signs_sug);
                }
              } else {
                board.signs_suggest = _.cloneDeep(board.signs);
                let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
                let signs_sug = [];
                if (find_sign_sug) {
                  find_sign_sug.forEach(s => {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                    signs_sug.push(d);
                  });
                  signs_sug = _.sortBy(signs_sug, ['sign_no']);
                  layers_sug.push(signs_sug);
                }
              }
            }
          });
          board.layers = layers;
          if (is_suggest) {
            board.layers_suggest = _.cloneDeep(layers_sug);
            board.layers_new = _.cloneDeep(layers_new);
          }
        } else {
          board.signs = [];
          board.layers = [];
          board.layers_suggest = [];
          board.layers_new = [];
        }
      } else {
        board.signs = [];
        board.layers = [];
        board.layers_suggest = [];
        board.layers_new = [];
      }
      if (is_camera) {
        //board.camera = [{ id:1 ,name: "กล้องส่องป้าย", url: 'http://camera.shw.transcodeglobal.com/?code=0002-0019100-2-LMS-CTV-R&1608193554031' }, { id:2,name: "กล้องส่องป้ายซ้าย", url: 'http://camera.shw.transcodeglobal.com/?code=0002-0019100-2-LMS-CTV-L&1608193554032' }];
      } else {
        board.camera = [];
      }
    });
    return boards;
  }
}
