import { Component, OnInit,EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PeopleService } from 'src/app/services/user/people.service';

import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { GroupService } from 'src/app/services/user/group.service';
import * as _ from 'lodash'
import { isEmpty } from 'rxjs/operators';
import { InstitutionService } from 'src/app/services/user/institution.service';

import * as md5 from 'md5'
import { FuncService } from 'src/app/services/user/func.service';
@Component({
  selector: 'app-people-modal',
  templateUrl: './people-modal.component.html',
  styleUrls: ['./people-modal.component.scss']
})
export class PeopleModalComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  values : any;
  ins_list : any;
  username : string
  password : string
  firstname : string
  lastname : string
  tel : string
  rank : string
  email : string
  hashtag : string
  ins_id : number
  ins_name : string
  group : any = []
  group_id : number
  group_list : any = []
  id: number;
  g_id: number;
  _isDisabled : boolean = false
  user_group: any  = [];
  _changed : boolean = false
  constructor(
    private func : FuncService,
    public bsModalRef: BsModalRef,
    private peoS : PeopleService,
    private grpS : GroupService,
    private insS : InstitutionService,
  ) { }

  ngOnInit(): void {
    this.getData();
    if(this.values){
      this._isDisabled = true
      this.id = this.values.id || null
      this.username = this.values.username || ''
      // this.password = this.values.password || ''
      this.firstname = this.values.firstname || ''
      this.lastname = this.values.lastname || ''
      this.tel = this.values.tel || ''
      this.rank = this.values.rank || ''
      this.email = this.values.email || ''
      this.hashtag = this.values.hashtag || ''
      this.ins_id = this.values.ins_id || null
      this.ins_name = this.values.ins_name || null
      if(this.values.group_list.length > 0) this.group_list = this.values.group_list 
    }
  }
  async getData(){
    if(!this.ins_list) this.ins_list = await this.insS.getIns();
    this.group = await this.grpS.getGroup();
    if(this.g_id){
      console.log(this.g_id)
      let ckGroup = _.filter(this.group,{id:this.g_id})
      if(ckGroup) this.group_list.push({
        group_id : ckGroup[0].id,
        group_name : ckGroup[0].name,
      })
    }
  }

  async save(){
    let val : any = {}
    if(this.id) val.id = this.id || ''
    if(this.username) val.username = this.username || ''
    // if(this.password && !this.values) val.password = md5(this.password) || ''
    if(this.password && !this.values) val.password = md5(this.password) || ''
    if(this.firstname) val.firstname = this.firstname || ''
    if(this.lastname) val.lastname = this.lastname || ''
    if(this.tel) {
      if(!this.func._isTelhome(this.tel)) return this.func.alertpopup('กรุณากรอกเบอร์โทรให้ถูกต้อง')
      val.tel = this.tel || ''
    }
    if(this.rank) val.rank = this.rank || ''
    if(this.email) val.email = this.email || ''
    if(this.hashtag) val.hashtag = this.hashtag || ''
    if(this.ins_id) val.ins_id = Number(this.ins_id) || null

    if(!this.id && this.group_list.length>0){
      val.user_group = this.group_list
    }
    let res : any = await this.peoS.addUser(val)
    if(res.id){
      this.confirm()
    }
  }
  close() {
    
    if(this._changed && !_.isEmpty(this.values)){
      this.cancel()
    }else{
      this.bsModalRef.hide();
    }
  } 
  
  confirm(){
    this.event.emit(true);
    this.bsModalRef.hide();
  }
  cancel(){
    console.log(this._changed)
    this.event.emit(this._changed);
    this.bsModalRef.hide();
  }

  onSelect(event: TypeaheadMatch): void {
    this.ins_id = event.item.id
  }
  blur(evt?:any){
    console.log(evt)
    if(!this.ins_id || !this.ins_name){
      this.ins_name = null
      this.ins_id = null
    }else if(this.ins_id && !this.ins_name){
      this.ins_name = null
      this.ins_id = null

    }
  }
  g_blur(evt?:any){

  }
  async rmUserGroup(g_id?:number){
    let res : any = await this.grpS.rmUGroup({
      user_id:this.id,
      group_id : g_id
    })
    if(!_.isEmpty(res)){
      this._changed = true
      this.group_list = _.remove(this.group_list, function (e) {
        return e.group_id !== g_id;
      });

    }

  }
  async onSelectGroup(evt?:any){
    console.log(evt.item)
    let checkHadData = _.filter(this.group_list,{"group_id":evt.item.
  id})
    if(checkHadData.length==0){
      if(this.id){
        let u_group = {
          group_id:evt.item.id,
          user_id : this.id
        }
        let res  : any = await this.grpS.addUGroup(u_group)
        if(_.isEmpty(res)) this._changed = true

      }
      this.group_list.push({
        group_id:evt.item.id,
        group_name:evt.item.name
      })
    }
    this.group_id = null
  }
}
