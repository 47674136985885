import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { BoardsignInfo, SignInfo, SensorConfig, ReqAddSign } from 'src/app/models/sign-control/boardsign';
import * as _ from 'lodash';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MediaType, SignDirection } from 'src/app/models/sign-control/enum';
import { MediaInfo } from 'src/app/models/sign-control/media';
import { FavoriteService } from 'src/app/services/sign-control/favorite.service';
import { AddFavoriteItem, ReqAddFavorite, StructureInfo } from 'src/app/models/sign-control/favorite';
import { StatusEnum } from 'src/app/models/common-enum';
@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {
  @Input() clickOpen: boolean;
  @Input() boardItem: BoardsignInfo;
  @Input() isAdd: boolean;
  @Input() isEdit: BoardsignInfo;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('mediaListModal') public mediaListModal: ModalDirective;
  @ViewChild('infoModal', { static: false }) public infoModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  favList: any = [];
  fav_action_type: any = 1; // 1 = list, 2 = Add, 3 = Edit, 4 = Delete
  selectedFav: any;
  structure_select: any;
  input_fav_name: any;
  sign_select_fav: any;
  res_message: string;
  is_loading: boolean = false;

  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  set_sign_height_fav: number = 50;
  set_sign_height: number = 100;
  signDir = SignDirection;
  mediaType = MediaType;

  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  status_modal: any;
  allowCustom: boolean = false;
  constructor(private favoriteService: FavoriteService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.clickOpen) {
      //console.log(changes);
      this.getFavorite();
      this.infoModal.show();
    }
  }
  onHide() {

  }
  clearData() {

  }

  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  openManageFavLayer(board?: BoardsignInfo) {
    // this.boardItem = board;
    // this.boardsign_toggle_layer = true;
    // this.layerType = this.boardViewType.Favorite;
    // this.getFavorite();
  }
  getstructure() {
    // layers
    const layers_text = this.boardItem.station.boardsign_structure.split("|");
    let layers = [];
    layers_text.forEach(l => {
      // signs
      const signs_text = l.split("_");
      let signs = [];
      signs_text.forEach(s => {
        // data
        const data = s.split("-");
        let d = { sign_no: +data[0], sign_type: +data[1], ratio: data[2], dir: +data[3], media: null };
        signs.push(d);
      });
      signs = _.sortBy(signs, ['sign_no']);
      layers.push(signs);
    });

    this.structure_select = { name: this.boardItem.station.boardsign_structure, boardsign_structure: this.boardItem.station.boardsign_structure, layers: layers };
  }
  closeManageFavLayer() {
    // this.boardsign_toggle_layer = false;
    // this.layerType = this.boardViewType.BoardInfo;
    // this.closeFavModalInfo();
  }

  async getFavorite(skip?: number, take?: number) {
    this.is_loading = true;
    this.favList = [];
    this.favoriteService.getFavorite(skip, take, this.boardItem.id, null).subscribe(
      res => {
        if (res.code == 1) {
          res.data.data.forEach(fav => {
            // layers
            //const layers_text = fav.boardsign_structure.split("|");
            let layers = [];
            fav.items = fav.items.filter(x => x.status == StatusEnum.Active);
            const layer_no_list = _.sortedUniq(fav.items.map(a=>a.layer_no));
            layer_no_list.forEach((l, index) => {
              // signs
              let signs = [];
              let find_sign = fav.items.filter(x => x.layer_no == l);
              if (find_sign) {
                find_sign.forEach(s => {
                  if (s.media) {
                    let d = { sign_no: s.sign_no, sign_type: s.sign_type_id, ratio: s.ratio, dir: s.dir, media: s.media };
                    signs.push(d);
                  } else {
                    let d = { sign_no: s.sign_no, sign_type: s.sign_type_id, ratio: s.ratio, dir: s.dir, media: null };
                    signs.push(d);
                  }

                });
                signs = _.sortBy(signs, ['sign_no']);
                layers.push(signs);
              }
            });
            fav.layers = layers;
          });

          setTimeout(() => {
            this.favList = res.data.data;
            //console.log(this.favList);
          }, 500);
          
          
        }
        this.is_loading = false;
      },
      err => {
        console.log(JSON.stringify(err.statusText));
        this.is_loading = false;
      });
  }
  openAddFavModal() {
    this.getstructure();
    this.fav_action_type = 2;
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.input_fav_name = null;
    this.addModal.show();
  }
  selectFav(data?: any, action?: number) {
    this.input_fav_name = data.name;
    this.status_modal = this.status_list.find(x => x.id == data.status);
    let fav = new StructureInfo();
    fav.name = data.name; // boardsign_structure_name
    fav.boardsign_structure = data.boardsign_structure;
    fav.layers = data.layers;
    fav.fav_id = data.id;
    this.structure_select = _.cloneDeep(fav);
    if (action == 3) {
      this.fav_action_type = action;
      this.addModal.show();
    } else if (action == 4) {
      this.deleteModal.show();
    }
    this.selectedFav = data;

  }

  multiFilter(array, filters) {
    const filterKeys = Object.keys(filters);
    // filters all elements passing the criteria
    return array.filter((item) => {
      // dynamically validate all filter criteria
      return filterKeys.every(key => !!~filters[key].indexOf(item[key]));
    });
  }

  addFavorite() {
    this.is_loading = true;
    let req = new ReqAddFavorite();
    req.boardsign_structure = this.boardItem.station.boardsign_structure;
    req.boardsign_id = this.boardItem.id;
    req.name = this.input_fav_name;
    req.status = this.status_modal.id;
    let items = [];
    this.structure_select.layers.forEach(layer => {
      layer.forEach(s => {
        let sign = new AddFavoriteItem();
        sign.sign_no = s.sign_no;
        if (s.media) {
          sign.media_id = s.media.id;
          sign.media_type = s.media.media_type;
        }
        items.push(sign);
      });
    });
    req.items = items;
    this.favoriteService.addFavorite(req, this.boardItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่ม Favorite สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getFavorite();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  editFavorite() {
    this.is_loading = true;
    let req = new ReqAddFavorite;
    req.boardsign_structure = this.boardItem.station.boardsign_structure;
    req.name = this.input_fav_name;
    req.id = this.structure_select.fav_id;
    req.status = this.status_modal.id;
    let items = [];
    this.structure_select.layers.forEach(layer => {
      layer.forEach(s => {
        let sign = new AddFavoriteItem();
        sign.sign_no = s.sign_no;
        if (s.media) {
          sign.id = s.id;
          sign.media_id = s.media.id;
          sign.media_type = s.media.media_type;
        }
        items.push(sign);
      });
    });
    req.items = items;
    this.favoriteService.editFavorite(req,this.structure_select.fav_id, this.boardItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไข Favorite สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getFavorite();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  async deleteFavorite(id?: number) {
    let req = new ReqAddFavorite;
    req.boardsign_structure = this.boardItem.station.boardsign_structure;
    req.name = this.input_fav_name;
    req.id = this.structure_select.fav_id;
    req.status = StatusEnum.Delete;
    this.is_loading = true;
    this.favoriteService.deleteFavoriteEditStatus(req,this.structure_select.fav_id, this.boardItem.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบ Favorite สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getFavorite();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  closeFavModalInfo() {
    this.fav_action_type = 1;
    this.input_fav_name = null;
    this.infoModal.hide();
    this.onClose.emit(null);
  }

  clickSignFavLayer(sign: any, board: any) {
    this.sign_select_fav = sign;
    this.boardItem = board;
    console.log(this.sign_select_fav);

    this.mediaListModal.show();
  }
  closeMediaModal(e) {
    if (e) {
      this.structure_select.layers.forEach(l => {
        l.forEach(sign => {
          if (sign.sign_no == this.sign_select_fav.sign_no) {
            console.log(e);
            let me = new MediaInfo;
            me = e;
            sign.media = me;
          }
        });
      });
      console.log(this.structure_select);
    }
    this.mediaListModal.hide();
    this.sign_select_fav = null;
  }

}
