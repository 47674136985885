import { Component, OnInit, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GroupService } from 'src/app/services/user/group.service';
import { InstitutionService } from 'src/app/services/user/institution.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { PeopleModalComponent } from '../../people/people-modal/people-modal.component';
import * as _ from 'lodash'
import { FuncService } from 'src/app/services/user/func.service';
import { DeviceService } from 'src/app/services/user/device.service';
import { CommonService } from 'src/app/services/common.service';
import { permission } from 'src/app/views/user-management/menu';
import { TreeviewItem, TreeviewConfig, DownlineTreeviewItem, TreeviewEventParser, OrderDownlineTreeviewEventParser } from 'ngx-treeview';

import { isNil, remove, reverse,isEqual } from 'lodash';
@Component({
  selector: 'app-group-modal',
  templateUrl: './group-modal.component.html',
  styleUrls: ['./group-modal.component.scss'],
  providers : [
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
  ]
})
export class GroupModalComponent implements OnInit {

  items_menu: TreeviewItem[];
  values_tree: number[]
  items_device: TreeviewItem[];
  values_device: number[]
  config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    // maxHeight: 400
  });
  configDevice = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: false,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    // maxHeight: 400
  });
  // bsModalRef_: BsModalRef | null;
  modalRef_user: BsModalRef;
  public event: EventEmitter<any> = new EventEmitter();
  values: any
  ins_list: any
  list: any = []
  total: number = 0;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  name: string
  hashtag: string
  id: number
  ins_id: number
  tel: number
  email: number
  rank: number
  ins_name: string;
  changed:boolean=false
  
  isAddUser: boolean = false;
  route_list: any;
  route_id: any;
  route_name: any;
  s_km: number;
  e_km: number;
  constructor(
    private func: FuncService,
    private bsModalRef_: BsModalRef,
    private modalService: BsModalService,
    private grpS: GroupService,
    private insS: InstitutionService,
    private devS: DeviceService,
    private commonService:CommonService

  ) { }


  async ngOnInit() {
    this.getData();
    this.checkValues();
    this.isAddUser = await this.commonService.getPermission(permission.user_group.add_user_id).toPromise();
    if(this.values) this.getPermission()
  }
  checkValues() {

    if (this.values) {
      this.id = this.values.id || null
      this.ins_id = this.values.ins_id || null
      this.ins_name = this.values.ins_name || null
      this.name = this.values.name || ''
      this.tel = this.values.tel || ''
      this.rank = this.values.rank || ''
      this.email = this.values.email || ''
      this.hashtag = this.values.hashtag || ''
      if (this.values.user_list.length > 0) this.list = this.values.user_list
    }
  }
  async getPermission(){
    this.getDevicePermission({ug_id : this.id})
    let res = await this.grpS.getPermissionGroup(this.id)
    this.items_menu = this.getMenus(res);
  }
  async getDevicePermission(req?:any){
    let res_device = await this.devS.getDevicePermission(req)
    this.items_device = this.getMenus(res_device);
  }
  s_route(){
    let req : any = {ug_id : this.id}
    if(this.route_name) req.route = `${this.route_name}`
    if(this.s_km) req.s_km = this.s_km
    if(this.e_km) req.e_km = this.e_km
    console.log(req)
    this.getDevicePermission(req)
  }
  async getData() {
    this.route_list = await this.devS.getRoute()
    if (!this.ins_list) this.ins_list = await this.insS.getIns();
  }
  async save() {
    let val: any = {}
    if (this.id) val.id = this.id || ''
    if (this.ins_id) val.ins_id = this.ins_id || ''
    // if(this.ins_name) val.ins_name = this.ins_name || ''
    if (this.name) val.name = this.name || ''
    if (!this.name) return this.func.alertpopup('ลืมกรอกชื่อกลุ่มผู้ใช้งาน')
    if (this.tel) {
      if (!this.func._isTelhome(this.tel)) return this.func.alertpopup('กรุณากรอกเบอร์โทรให้ถูกต้อง')
      val.tel = this.tel || ''
    }
    if (this.rank) val.rank = this.rank || ''
    if (this.email) val.email = this.email || ''
    if (this.hashtag) val.hashtag = this.hashtag || ''
    let res: any = await this.grpS.addGroup(val)
    if (res.id) {
      this.confirm()
    }
  }
  close() {
    if(this.changed) this.confirm
    this.cancel()
    // this.bsModalRef_.hide();
  }

  confirm() {
    this.event.emit(true);
    this.bsModalRef_.hide();
  }
  cancel() {
    if(this.changed) this.confirm
    this.event.emit(false);
    this.bsModalRef_.hide();
  }
  blur(evt?: any) {
    if (!this.ins_id || !this.ins_id) {
      this.ins_id = null
      this.ins_name = null
    }
  }
  onSelect(event: TypeaheadMatch): void {
    if (this.ins_name) {
      this.ins_id = event.item.id;
    } else {
      this.ins_id = null
    }
  }
  pageChanged(evt?) {
    this.currentPage = evt.page
  }
  async getList() {
    let res = await this.grpS.getGroup({ id: this.id });
    this.values = res[0]
    this.checkValues()
  }
  addUser() {

    const initialState = {
      g_id: this.id,
      second_page: 2
    };
    this.modalRef_user = this.modalService.show(PeopleModalComponent, { initialState, id: 2 });
    this.modalRef_user.content.event.subscribe(data => {
      // if(data) this.getData()
      if (data) this.getList()

    });
  }
  async rmUserGroup(u_id?: number) {

    let result = await this.func.alertpopup('<h4>คุณต้องการลบใช่หรือไม่</h4>', `<i class="icon-info mr-2"></i>ลบผู้ใช้งาน`, 1);
    if (result) {
      let res: any = await this.grpS.rmUGroup({
        user_id: u_id,
        group_id: this.id
      })
      if (!_.isEmpty(res)) this.getList()
    }
  }
  onFilterChange(value: string) {
    console.log('filter:', value);
  }
  getMenus(list?:any): TreeviewItem[] {
    // const list = items.forEach(item => new TreeviewItem(item));
    if(!list.length) return []
    const Menu1 = []
    for (const i of list) {
      // i.collapsed = true
      Menu1.push(new TreeviewItem(i))
    }

    return Menu1;
  }
  async save_per(){
    let per_checked : any = []

    this.values_tree.forEach((l : any)=>{
      per_checked.push(l.item.value)
      
      let parent = l.parent;
      while (!isNil(parent)) {
        if(parent.item.internalChecked){
          per_checked.push(parent.item.value);

        }
        parent = parent.parent;
      }
    })
    per_checked = _.uniq(per_checked)
    let data : any = {
      group_id : this.id,
      permission_type: 1,
      p_list : []
    }
    for (const i of per_checked) {
      data.p_list.push({
        permission_id:i,
        user_group_id : this.id,
        permission_type: 1,
        status:1
      })
    }
    let res : any = await this.grpS.addPermissionGroup(data)
    if(res && res.length>0){ 
      this.changed = true
      this.func.alertpopup("เพื่ม Permission สำเร็จ <br> กรุุณา  Login ใหม่อีกครั้ง")
    }else{
      this.func.alertpopup("ไม่สามารถเพื่ม Permission ได้")
    }
  }
  onSelectedChange(evt: any): void {
    console.log(evt)
  }
  async save_device(){
    console.log(this.values_device)
    
    let per_checked : any = []

    this.values_tree.forEach((l : any)=>{
      per_checked.push(l.item.value)
    })
    per_checked = _.uniq(per_checked)
    let data : any = {
      group_id : this.id,
      permission_type: 2,
      p_list : []
    }
    for (const i of per_checked) {
      data.p_list.push({
        permission_id:i,
        user_group_id : this.id,
        permission_type: 2,
        status:1
      })
    }
    let res : any = await this.grpS.addPermissionGroup(data)
    if(res && res.length>0){ 
      this.changed = true
      this.func.alertpopup("เพื่ม Permission สำเร็จ <br> กรุุณา  Login ใหม่อีกครั้ง")
    }else{
      this.func.alertpopup("ไม่สามารถเพื่ม Permission ได้")
    }
  }
  onSelectR(evt : TypeaheadMatch): void {
    console.log(evt)
  }
}
