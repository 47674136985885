export const mainRouteUrl = "corridor-control";
export const id = 1200;
export const menuItems = [
    {
        id: 1200,
        name: 'Corridor Control',
        url: `/${mainRouteUrl}/control`,
        icon: 'fa fa-clone',
        parent_id: id
    }
];