export const mainRouteUrl = "sign-control";
export const id = 200;
export const menuItems = [
    {
        id: 201,
        name: 'ป้ายแสดงผล',
        url: `/${mainRouteUrl}/sign`,
        icon: 'fa fa-clone',
        parent_id: id
    },
    // {
    //     id: 300,
    //     name: 'ATIS',
    //     url: `/${mainRouteUrl}/atis`,
    //     icon: 'fa fa-clone',
    //     parent_id: id
    // },
    {
        id: 400,
        name: 'Media',
        url: `/${mainRouteUrl}/media`,
        icon: 'icon-picture',
        parent_id: id
    },
    {
        id: 500,
        name: 'Playlist',
        url: `/${mainRouteUrl}/playlist`,
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 600,
        name: 'ชนิดป้าย',
        url: `/${mainRouteUrl}/sign-type`,
        icon: 'fa fa-clone',
        parent_id: id
    },
    {
        id: 700,
        name: 'ปฏิทิน',
        url: `/${mainRouteUrl}/schedule`,
        icon: 'icon-calendar',
        parent_id: id
    }
];

export const permission = {
    sign: {
        add_sign_id: 202,
        auto_suggest_id: 203,
        firmware_id: 204,
        sign_setting_id: 205,
        favorite_id: 206,
        favorite: {
            add_id: 207,
            edit_id: 208
        },
        calendar_id: 209,
        calendar: {
            add_id: 210
        },
        ma_id: 211,
        edit_sign_id: 212,
        edit_sign: {
            add_id: 213,
            edit_id: 214
        },
        delete_sign_id: 215,
        change_sign_id: 216,
    },
    atis: {
        add_id: 301,
        control_id: 302,
        ma_id: 303,
        edit_id: 304
    },
    media: {
        add_id: 401,
        create_id: 402
    },
    playlist: {
        create_id: 501
    },
    sign_type: {
        add_id: 601,
        edit_id: 602,
    },
    calendar: {
        add_id: 701
    }
};