import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { StationService } from '../../../services/assets-management/station.service';
import { MonitorService } from '../../../services/assets-management/monitor.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { StatusEnum, ConnectionStatusEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Owner } from 'src/app/models/assets-management/owner';
import { Vendor, VendorContact } from 'src/app/models/assets-management/vendor';
import { DeviceGroup } from 'src/app/models/assets-management/device-group';
import { OwnerService } from '../../../services/assets-management/owner.service';
import { VendorService } from '../../../services/assets-management/vendor.service';
import { DeviceGroupService } from '../../../services/assets-management/device-group.service';
import { DeviceTypeService } from '../../../services/assets-management/device-type.service';
import { DeviceService } from '../../../services/assets-management/device.service';
import { DeviceType, DeviceSubType } from 'src/app/models/assets-management/device-type';
import { DeviceInfo, ReqAddDevice, SteamingTypeInfo, DeviceSteaming } from 'src/app/models/assets-management/device';
import { Brand, BrandModel } from 'src/app/models/assets-management/brand';
import { BrandService } from '../../../services/assets-management/brand.service';
import { Img, ItemName } from 'src/app/models/common';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import { MonitorStation } from 'src/app/models/assets-management/monitor';
import { permission } from 'src/app/views/assets-management/_menu';
@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss']
})
export class DeviceComponent implements OnInit {
  @ViewChild('imInput') imInputVariable: ElementRef;
  @ViewChild('addModal', { static: false }) public addModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  map_station: mapboxgl.Map;
  viewType = 1; //1:list,2:map
  statusEnum = StatusEnum;
  connectionStatusEnum = ConnectionStatusEnum;
  myDate = new Date();
  currentPage: any = 1;
  itemsPerPage: any = 10;
  totalItems: any = 0;
  data_list: DeviceInfo[];
  select_images: Img[] = [];
  select_steaming: SteamingTypeInfo[] = [
    {
      link: "",
      type: null
    }, {
      link: "",
      type: null
    }, {
      link: "",
      type: null
    }
  ];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" },
    { id: StatusEnum.Ma, name: "MA" }
  ];
  steaming_type_list = [
    { id: 1, name: "RTSP" },
    { id: 2, name: "FLV" }
  ];
  select_device_map: any[];
  search_status: any;
  selectItem: DeviceInfo = {
    name: "",
    serial_no: "",
    ip: "",
    sim_number: "",
    link_snapshot: "",
    owner_desc: "",
    vendor_desc: "",
    vendor_contact_desc: "",
    install_ts: null,
    ma_expire_ts: null,
    is_monitor: false
  };
  public allowCustom: boolean = false;
  res_message: any;
  search_km_st: any;
  search_km_en: any;
  search_m_st: any;
  search_m_en: any;
  search_station: any;
  data_station_search: Station[] = [];
  station_search_list: Station[] = [];
  search_owner: any;
  data_owner: Owner[] = [];
  owner_list: Owner[] = [];
  search_vendor: any;
  data_vendor: Vendor[] = [];
  vendor_list: Vendor[] = [];
  vendor_contact_list: VendorContact[] = [];
  search_device_group: any;
  data_device_group: DeviceGroup[] = [];
  device_group_list: DeviceGroup[] = [];
  search_device_type: any;
  data_device_type: DeviceType[] = [];
  device_type_list: DeviceType[] = [];
  device_subtype_list: DeviceSubType[] = [];
  device_subtype_list_all: DeviceSubType[] = [];
  brand_list: Brand[] = [];
  brand_model_list: BrandModel[] = [];

  status_modal: any;
  station_modal: Station;
  device_group_modal: DeviceGroup;
  device_type_modal: DeviceType;
  device_subtype_modal: DeviceSubType;
  //steaming_type_modal: any;
  brand_modal: Brand;
  brand_model_modal: BrandModel;
  owner_modal: Owner;
  vendor_modal: Vendor;
  vendor_contact_modal: VendorContact;

  data_station_modal: Station[] = [];
  data_device_group_modal: DeviceGroup[] = [];
  data_device_type_modal: DeviceType[] = [];
  data_device_subtype_modal: DeviceSubType[] = [];
  data_brand_modal: Brand[] = [];
  data_brand_model_modal: BrandModel[] = [];
  data_owner_modal: Owner[] = [];
  data_vendor_modal: Vendor[] = [];
  data_vendor_contact_modal: VendorContact[] = [];

  isAdd: boolean = false;
  isEdit: boolean = false;
  constructor(private commonService: CommonService, private stationService: StationService, private ownerService: OwnerService,
    private vendorService: VendorService, private deviceGroupService: DeviceGroupService, private deviceTypeService: DeviceTypeService,
    private brandService: BrandService, private deviceService: DeviceService, private monitorService: MonitorService) { 
      mapboxgl.accessToken = environment.mapbox.accessToken;
    }

  async ngOnInit() {
    this.getRoute();
    this.is_loading = true;
    this.getDevice();
    this.getStationSearch();
    this.getOwner();
    this.getVendor();
    this.getDeviceGroup();
    this.getDeviceType();
    this.getDeviceSubType2();
    this.getBrand();
    this.initStationMap();
    this.isAdd = await this.commonService.getPermission(permission.device.add_id).toPromise();
    this.isEdit = await this.commonService.getPermission(permission.device.edit_id).toPromise();
  }
  changeView(id) {
    if (id != this.viewType) {
      if (id == 1) {
        this.getDevice();
      } else {
        this.getStationMap();
        setTimeout(() => { this.updateMap(); this.is_loading = false; this.map_station.resize(); }, 1000);

      }
    }
  }
  search() {
    this.getDevice();
    if (this.viewType == 1) {
      this.currentPage = 1;
      this.getDevice();
    } else {
      this.getStationMap();
      setTimeout(() => { this.updateMap(); this.is_loading = false; this.map_station.resize(); }, 1000);
    }
  }
  handleValueStatus(value) {
    if (value) {
      this.search_status = value;
    } else {
      this.search_status = null;
    }
  }
  is_loading: boolean = false;
  search_route: string;
  public data_route: Array<string>;
  route_list: string[];
  getRoute() {
    this.commonService.getRoute().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.route_list = _.cloneDeep(res.data);
              this.data_route = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }

  handleFilterRoute(value) {
    if (value.length > 0) {
      this.data_route = this.route_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_route = _.cloneDeep(this.route_list);
    } else {
      this.data_route = [];
    }
  }
  handleValueRoute(value) {
    if (value) {
      this.search_route = value;
    } else {
      this.search_route = null;
    }
  }

  getStationSearch() {
    this.stationService.getStation(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.station_search_list = _.cloneDeep(res.data.data);
              this.data_station_search = _.cloneDeep(res.data.data);
              this.data_station_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list.filter(x=>x.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {
    if (value) {
      this.search_station = value;
    } else {
      this.search_station = null;
    }
  }

  getOwner() {
    this.ownerService.getOwner(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.owner_list = _.cloneDeep(res.data.data);
              this.data_owner = _.cloneDeep(res.data.data);
              this.data_owner_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  handleFilterOwner(value) {
    if (value.length > 0) {
      this.data_owner = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner = [];
    }
  }
  handleValueOwner(value) {
    if (value) {
      this.search_owner = value;
    } else {
      this.search_owner = null;
    }
  }

  getVendor() {
    this.vendorService.getVendor(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.vendor_list = _.cloneDeep(res.data);
              this.data_vendor = _.cloneDeep(res.data);
              this.data_vendor_modal = _.cloneDeep(res.data);

            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  getVendorContact(vendor_id) {
    this.vendorService.getVendorContact(null, null, 1, vendor_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.vendor_contact_list = _.cloneDeep(res.data.data);
              this.data_vendor_contact_modal = _.cloneDeep(res.data.data);

            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  handleFilterVendor(value) {
    if (value.length > 0) {
      this.data_vendor = this.vendor_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_vendor = _.cloneDeep(this.vendor_list);
    } else {
      this.data_vendor = [];
    }
  }
  handleValueVendor(value) {
    if (value) {
      this.search_vendor = value;
    } else {
      this.search_vendor = null;
    }
  }

  getDeviceGroup() {
    this.deviceGroupService.getDeviceGroup(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_group_list = _.cloneDeep(res.data.data);
              this.data_device_group = _.cloneDeep(res.data.data);
              this.data_device_group_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  handleFilterDeviceGroup(value) {
    if (value.length > 0) {
      this.data_device_group = this.device_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_group = _.cloneDeep(this.device_group_list);
    } else {
      this.data_device_group = [];
    }
  }
  handleValueDeviceGroup(value) {
    if (value) {
      this.search_device_group = value;
    } else {
      this.search_device_group = null;
    }
  }

  getDeviceType() {
    this.deviceTypeService.getDeviceType(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_type_list = _.cloneDeep(res.data);
              this.data_device_type = _.cloneDeep(res.data);
              this.data_device_type_modal = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  getDeviceSubType(device_type_id) {
    return new Promise((reslove,reject)=>{
      this.deviceTypeService.getDeviceSubType(null, null, 1, device_type_id).subscribe(
        res => {
          if (res) {
            if (res.code == 1) {
              if (res.data) {
                this.device_subtype_list = _.cloneDeep(res.data);
                this.data_device_subtype_modal = _.cloneDeep(res.data);
              }
              reslove(true)
            }
          }
          //this.is_loading = false;
          reslove(false)
        },
        error => {
          //this.is_loading = false;
          reslove(false)
        });
    })
    
  }
  getDeviceSubType2() {
    this.deviceTypeService.getDeviceSubType(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.device_subtype_list_all = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  handleFilterDeviceType(value) {
    if (value.length > 0) {
      this.data_device_type = this.device_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_type = _.cloneDeep(this.device_type_list);
    } else {
      this.data_device_type = [];
    }
  }
  handleValueDeviceType(value) {
    if (value) {
      this.search_device_type = value;
    } else {
      this.search_device_type = null;
    }
  }

  getDevice() {
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group) {
      device_group = this.search_device_group.id;
    }
    if (this.search_device_type)
      device_type = this.search_device_type.id;
    this.deviceService.getDevice(this.currentPage, this.itemsPerPage, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            }else{
              this.totalItems = 0;
            }
          }else{
            this.totalItems = 0;
          }
        }else{
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
        this.totalItems = 0;
      });
  }
  openAddModal() {
    this.selectItem = new DeviceInfo();
    this.selectItem = {
      name: "",
      serial_no: "",
      ip: "",
      sim_number: "",
      link_snapshot: "",
      owner_desc: "",
      vendor_desc: "",
      vendor_contact_desc: "",
      install_ts: null,
      ma_expire_ts: null,
      is_monitor: false
    }
    this.select_steaming = [
      {
        link: "",
        type: null
      }, {
        link: "",
        type: null
      }, {
        link: "",
        type: null
      }
    ];
    this.status_modal = this.status_list.find(x => x.id == this.statusEnum.Active);
    this.station_modal = null;
    this.device_group_modal = null;
    this.device_type_modal = null;
    this.device_subtype_modal = null;
    this.brand_modal = null;
    this.brand_model_modal = null;
    this.owner_modal = null;
    this.vendor_modal = null;
    this.vendor_contact_modal = null;
    this.addModal.show();
  }
  async openEditModal(item?: DeviceInfo) {
    console.log(item)
    this.selectItem = item;
    if (item.install_ts) {
      this.selectItem.install_ts = new Date(item.install_ts);
    }
    if (item.ma_expire_ts) {
      this.selectItem.ma_expire_ts = new Date(item.ma_expire_ts);
    }
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.station_id)
      this.station_modal = this.station_search_list.find(x => x.id == item.station_id);
    if (item.device_group_id)
      this.device_group_modal = this.device_group_list.find(x => x.id == item.device_group_id);
    if (item.device_type_id) {
      this.device_type_modal = this.device_type_list.find(x => x.type_id == item.device_type_id);
      await this.getDeviceSubType(item.device_type_id);
    }
    if (item.device_sub_type_id)
      this.device_subtype_modal = this.data_device_subtype_modal.find(x => x.subtype_id == item.device_sub_type_id);
    if (item.brand_id) {
      this.brand_modal = this.brand_list.find(x => x.brand_id == item.brand_id);
      this.getBrandModel(item.brand_id);
    }
    if (item.model_id)
      this.brand_model_modal = this.brand_model_list.find(x => x.model_id == item.model_id);
    if (item.owner_id)
      this.owner_modal = this.owner_list.find(x => x.id == item.owner_id);
    if (item.vendor_id) {
      this.vendor_modal = this.vendor_list.find(x => x.vendor_id == item.vendor_id);
      this.getVendorContact(item.vendor_id);
    }
    if (item.vendor_contact_id)
      this.vendor_contact_modal = this.vendor_contact_list.find(x => x.contact_id == item.vendor_contact_id);
    this.select_steaming = [
      {
        link: "",
        type: null
      }, {
        link: "",
        type: null
      }, {
        link: "",
        type: null
      }
    ];
    if (item.steaming) {
      item.steaming.forEach((item, index) => {
        this.select_steaming[index].link = item.link;
        if (item.type) {
          this.select_steaming[index].type = this.steaming_type_list.find(x => x.id == item.type);
        }
      });
    }
    this.select_images = [];

    if (item.image_url) {
      if (_.isArray(item.image_url)) {
        item.image_url.forEach(item => {
          let im = new Img();
          im.url = item;
          this.select_images.push(im);
        });
      }
    }
    this.addModal.show();
  }
  openDeleteModal(item?: DeviceInfo) {
    this.selectItem = item;
    this.status_modal = this.status_list.find(x => x.id == item.status);
    this.selectItem = item;
    if (item.install_ts) {
      this.selectItem.install_ts = new Date(item.install_ts);
    }
    if (item.ma_expire_ts) {
      this.selectItem.ma_expire_ts = new Date(item.ma_expire_ts);
    }
    this.status_modal = this.status_list.find(x => x.id == item.status);
    if (item.station_id)
      this.station_modal = this.station_search_list.find(x => x.id == item.station_id);
    if (item.device_group_id)
      this.device_group_modal = this.device_group_list.find(x => x.id == item.device_group_id);
    if (item.device_type_id)
      this.device_type_modal = this.device_type_list.find(x => x.type_id == item.device_type_id);
    if (item.device_sub_type_id)
      this.device_subtype_modal = this.device_subtype_list_all.find(x => x.subtype_id == item.device_sub_type_id);
    if (item.brand_id)
      this.brand_modal = this.brand_list.find(x => x.brand_id == item.brand_id);
    if (item.model_id)
      this.brand_model_modal = this.brand_model_list.find(x => x.model_id == item.model_id);
    if (item.owner_id)
      this.owner_modal = this.owner_list.find(x => x.id == item.owner_id);
    if (item.vendor_id)
      this.vendor_modal = this.vendor_list.find(x => x.vendor_id == item.vendor_id);
    if (item.vendor_contact_id)
      this.vendor_contact_modal = this.vendor_contact_list.find(x => x.contact_id == item.vendor_contact_id);
    this.select_steaming = [
      {
        link: "",
        type: null
      }, {
        link: "",
        type: null
      }, {
        link: "",
        type: null
      }
    ];
    if (item.steaming) {
      item.steaming.forEach((item, index) => {
        this.select_steaming[index].link = item.link;
        if (item.type) {
          this.select_steaming[index].type = this.steaming_type_list.find(x => x.id == item.type);
        }
      });
    }
    this.select_images = [];
    if (item.image_url) {
      let u: any = item.image_url
      if (u != "") {
        item.image_url.forEach(item => {
          let im = new Img();
          im.url = item;
          this.select_images.push(im);
        });
      }

    }
    this.deleteModal.show();
  }
  clearData() {
    this.selectItem = new DeviceInfo();
    this.select_images = [];
    if (this.imInputVariable) {
      this.imInputVariable.nativeElement.value = "";
    }
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getDevice();
    }
  }
  addDevice(url?: string[]) {
    this.is_loading = true;
    let req = new ReqAddDevice();
    let d = new DeviceInfo();
    d.name = this.selectItem.name;
    d.ip = this.selectItem.ip;
    d.serial_no = this.selectItem.serial_no;
    d.sim_number = this.selectItem.sim_number;
    d.link_snapshot = this.selectItem.link_snapshot;
    d.owner_desc = this.selectItem.owner_desc;
    d.vendor_desc = this.selectItem.vendor_desc;
    d.vendor_contact_desc = this.selectItem.vendor_contact_desc;
    d.status = this.status_modal.id;
    d.is_monitor = this.selectItem.is_monitor;
    d.image_url = [];
    if (this.selectItem.install_ts) {
      d.install_ts = new Date(this.selectItem.install_ts);;
    }
    if (this.selectItem.ma_expire_ts) {
      d.ma_expire_ts = new Date(this.selectItem.ma_expire_ts);;
    }
    if (url)
      d.image_url = url;
    if (this.station_modal)
      d.station_id = this.station_modal.id;
    if (this.device_group_modal)
      d.device_group_id = this.device_group_modal.id;
    if (this.device_type_modal)
      d.device_type_id = this.device_type_modal.type_id;
    if (this.device_subtype_modal)
      d.device_sub_type_id = this.device_subtype_modal.subtype_id;
    if (this.brand_modal)
      d.brand_id = this.brand_modal.brand_id;
    if (this.brand_model_modal)
      d.model_id = this.brand_model_modal.model_id;
    if (this.owner_modal)
      d.owner_id = this.owner_modal.id;
    if (this.vendor_modal)
      d.vendor_id = this.vendor_modal.vendor_id;
    if (this.vendor_contact_modal)
      d.vendor_contact_id = this.vendor_contact_modal.contact_id;
    req.device = d;
    let steaming = [];
    this.select_steaming.forEach(item => {
      let s = new DeviceSteaming();
      s.link = item.link;
      if (item.type) {
        s.type = item.type.id;
      } else {
        s.type = null
      }
      if (s.type != null || s.link != "") {
        steaming.push(s);
      }
    });
    req.steaming = steaming;
    this.res_message = "";
    this.deviceService.addDevice(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มอุปกรณ์สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDevice();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  bucket_name = 'asset';
  path = 'device';
  async addDeviceAsyn() {
    let add_img = this.select_images.filter(x => x.delete != true && x.file != null && x.dataurl != null);
    if (add_img.length > 0) {
      const f = this.select_images.map(x => x.file)
      let imgs = await this.commonService.uploadImages(f, this.bucket_name, this.path).toPromise();
      if (imgs) {
        if (imgs.data) {
          this.addDevice(imgs.data.img_urls);
        } else {
          this.addDevice();
        }
      } else {
        this.addDevice();
      }
    } else {
      this.addDevice();
    }
  }

  async editDeviceAsyn() {
    let add_img = this.select_images.filter(x => x.delete != true && x.file != null && x.dataurl != null);
    let all_img = this.select_images.filter(x => x.delete != true);
    let all_img_url = this.select_images.filter(x => x.delete != true && x.url != null);
    let urls = [];
    if (all_img_url.length > 0) {
      urls = all_img_url.map(x => x.url);
    }
    try {
      let delete_img = this.select_images.filter(x => x.delete == true && x.url != null);
      if (delete_img.length > 0) {
        delete_img.forEach((item, index) => {
          let del = this.commonService.deleteImage(item.url).toPromise();
        });
      }
    } catch { }

    if (add_img.length > 0) {
      const f = this.select_images.map(x => x.file)
      let imgs = await this.commonService.uploadImages(f, this.bucket_name, this.path).toPromise();
      if (imgs) {
        if (imgs.data) {
          urls = [];
          const ims = imgs.data.img_urls;
          let i = 0;
          all_img.forEach(item => {
            if (item.url) {
              urls.push(item.url);
            } else {
              urls.push(ims[i]);
              i++;
            }
          });
          this.editDevice(urls);
        } else {
          this.editDevice(urls);
        }
      } else {
        this.editDevice(urls);
      }
    } else {
      this.editDevice(urls);
    }
  }
  editDevice(url?: string[]) {
    this.is_loading = true;
    let req = new ReqAddDevice();
    let d = new DeviceInfo();
    d.name = this.selectItem.name;
    d.ip = this.selectItem.ip;
    d.serial_no = this.selectItem.serial_no;
    d.sim_number = this.selectItem.sim_number;
    d.link_snapshot = this.selectItem.link_snapshot;
    d.owner_desc = this.selectItem.owner_desc;
    d.vendor_desc = this.selectItem.vendor_desc;
    d.vendor_contact_desc = this.selectItem.vendor_contact_desc;
    d.status = this.status_modal.id;
    d.is_monitor = this.selectItem.is_monitor;
    d.image_url = [];
    if (this.selectItem.install_ts) {
      d.install_ts = new Date(this.selectItem.install_ts);;
    }
    if (this.selectItem.ma_expire_ts) {
      d.ma_expire_ts = new Date(this.selectItem.ma_expire_ts);;
    }
    if (url)
      d.image_url = url;
    if (this.station_modal)
      d.station_id = this.station_modal.id;
    if (this.device_group_modal)
      d.device_group_id = this.device_group_modal.id;
    if (this.device_type_modal)
      d.device_type_id = this.device_type_modal.type_id;
    if (this.device_subtype_modal)
      d.device_sub_type_id = this.device_subtype_modal.subtype_id;
    if (this.brand_modal)
      d.brand_id = this.brand_modal.brand_id;
    if (this.brand_model_modal)
      d.model_id = this.brand_model_modal.model_id;
    if (this.owner_modal)
      d.owner_id = this.owner_modal.id;
    if (this.vendor_modal)
      d.vendor_id = this.vendor_modal.vendor_id;
    if (this.vendor_contact_modal)
      d.vendor_contact_id = this.vendor_contact_modal.contact_id;
    req.device = d;
    let steaming = [];
    this.select_steaming.forEach(item => {
      let s = new DeviceSteaming();
      s.link = item.link;
      if (item.type) {
        s.type = item.type.id;
      } else {
        s.type = null
      }
      if (s.type || s.link) {
        steaming.push(s);
      }
    });
    req.steaming = steaming;
    this.res_message = "";
    this.deviceService.editDevice(req, this.selectItem.device_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไขอุปกรณ์สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDevice();
            }, 100);
            this.addModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  deleteDevice() {
    this.is_loading = true;
    let req = new ReqAddDevice();
    let d = new DeviceInfo();
    d.status = StatusEnum.Delete;
    req.device = d;
    this.res_message = "";
    this.deviceService.deleteDeviceEditStatus(req, this.selectItem.device_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบอุปกรณ์สำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getDevice();
            }, 100);
            this.deleteModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  //----------------------------------------------------------------------------------------------------//
  deleteImageFiles(item: any) {
    const index: number = this.select_images.indexOf(item);
    if (item.ref_ext_key) {
      item.delete = true;
    } else {
      if (index !== -1) {
        this.select_images.splice(index, 1);
      }
    }
  }
  detectFiles(event) {
    let acceptedType = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        if(acceptedType.includes(file.type)){
        let reader = new FileReader();
        reader.onload = (e: any) => {
          let im = new Img();
          im.file = file;
          im.dataurl = e.target.result;
          this.select_images.push(im);
        }
        reader.readAsDataURL(file);
      } else {
        const error_text = "รองรับไฟล์นามกุล JPEG,PNG,JPG,GIF เท่านั้น";
        this.res_message = error_text;
        this.is_loading = false
        setTimeout(() => { this.swalError.fire() }, 500);
      }
      }
    }
  }

  //---------------------------------------------------------------------------------------------------//
  //Modal

  handleFilterStationModal(value) {
    if (value.length > 0) {
      this.data_station_modal = this.station_search_list.filter(x=>x.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_modal = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_modal = [];
    }
  }
  handleValueStationModal(value) {
    if (value) {
      this.station_modal = value;
    } else {
      this.station_modal = null;
    }
  }

  handleFilterDeviceGroupModal(value) {
    if (value.length > 0) {
      this.data_device_group_modal = this.device_group_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_group_modal = _.cloneDeep(this.device_group_list);
    } else {
      this.data_device_group_modal = [];
    }
  }
  handleValueDeviceGroupModal(value) {
    if (value) {
      this.device_group_modal = value;
    } else {
      this.device_group_modal = null;
    }
  }

  handleFilterDeviceTypeModal(value) {
    if (value.length > 0) {
      this.data_device_type_modal = this.device_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_type_modal = _.cloneDeep(this.device_type_list);
    } else {
      this.data_device_type_modal = [];
    }
  }
  handleValueDeviceTypeModal(value) {
    if (value) {
      this.device_type_modal = value;
      this.getDeviceSubType(value.type_id);
    } else {
      this.device_type_modal = null;
    }
  }

  handleFilterDeviceSubTypeModal(value) {
    if (value.length > 0) {
      this.data_device_subtype_modal = this.device_subtype_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_device_subtype_modal = _.cloneDeep(this.device_subtype_list);
    } else {
      this.data_device_subtype_modal = [];
    }
  }
  handleValueDeviceSubTypeModal(value) {
    if (value) {
      this.device_subtype_modal = value;
    } else {
      this.device_subtype_modal = null;
    }
  }

  getBrand() {
    this.brandService.getBrand(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.brand_list = _.cloneDeep(res.data.data);
              this.data_brand_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  getBrandModel(brand_id) {
    this.brandService.getBrandModel(null, null, 1, brand_id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.brand_model_list = _.cloneDeep(res.data.data);
              this.data_brand_model_modal = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  handleFilterBrandModal(value) {
    if (value.length > 0) {
      this.data_brand_modal = this.brand_list.filter((s) => s.brand_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_brand_modal = _.cloneDeep(this.brand_list);
    } else {
      this.data_brand_modal = [];
    }
  }
  handleValueBrandModal(value) {
    if (value) {
      this.brand_modal = value;
      this.getBrandModel(value.brand_id);
    } else {
      this.brand_modal = null;
    }
  }

  handleFilterBrandModelModal(value) {
    if (value.length > 0) {
      this.data_brand_model_modal = this.brand_model_list.filter((s) => s.model_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_brand_model_modal = _.cloneDeep(this.brand_model_list);
    } else {
      this.data_brand_model_modal = [];
    }
  }
  handleValueBrandModelModal(value) {
    if (value) {
      this.brand_model_modal = value;
    } else {
      this.brand_model_modal = null;
    }
  }

  handleFilterOwnerModal(value) {
    if (value.length > 0) {
      this.data_owner_modal = this.owner_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_owner_modal = _.cloneDeep(this.owner_list);
    } else {
      this.data_owner_modal = [];
    }
  }
  handleValueOwnerModal(value) {
    if (value) {
      this.owner_modal = value;
    } else {
      this.owner_modal = null;
    }
  }

  handleFilterVendorModal(value) {
    if (value.length > 0) {
      this.data_vendor_modal = this.vendor_list.filter((s) => s.company_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_vendor_modal = _.cloneDeep(this.vendor_list);
    } else {
      this.data_vendor_modal = [];
    }
  }
  handleValueVendorModal(value) {
    if (value) {
      this.vendor_modal = value;
      this.getVendorContact(value.vendor_id);
    } else {
      this.vendor_modal = null;
    }
  }

  handleFilterVendorContactModal(value) {
    if (value) {
      if (value.length > 0) {
        this.data_vendor_contact_modal = this.vendor_contact_list.filter((s) => s.contact_name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
      } else if (value.length == 0) {
        this.data_vendor_contact_modal = _.cloneDeep(this.vendor_contact_list);
      } else {
        this.data_vendor_contact_modal = [];
      }
    } else {
      this.data_vendor_contact_modal = _.cloneDeep(this.vendor_contact_list);
    }

  }
  handleValueVendorContactModal(value) {
    if (value) {
      this.vendor_contact_modal = value;
    } else {
      this.vendor_contact_modal = null;
    }
  }
  //-------------------------------------------------------------------------//
  //Map
  station_layer: any = 'WeightStationLayer';
  station_src: any = 'WeightStationSrc';
  station_src_url: any = '/assets/data/station.json';
  stationListFeatures: any[] = [];
  stationList: MonitorStation[] = [];
  getStationMap() {
    this.is_loading = true;
    this.stationList = [];
    this.stationListFeatures = [];
    this.data_list = [];
    let status = null;
    let route = null;
    let station = null;
    let owner = null;
    let vendor = null;
    let device_type = null;
    let device_group = null;
    if (this.search_status)
      status = this.search_status.id;
    if (this.search_route)
      route = this.search_route;
    if (this.search_station)
      station = this.search_station.id;
    if (this.search_owner)
      owner = this.search_owner.id;
    if (this.search_vendor)
      vendor = this.search_vendor.vendor_id;
    if (this.search_device_group)
      device_group = this.search_device_group.id;
    if (this.search_device_type)
      device_type = this.search_device_type.id;
    this.monitorService.getHardwareStation(null, null, null, route, this.search_km_st, this.search_km_en, this.search_m_st, this.search_m_en, status, station, owner, vendor, device_group, device_type).subscribe(
      res => {
        if (res) {
          res.data.data.forEach(item => {
            // item.icon = item.current[0].weather[0].icon;
            // item.desc = item.current[0].weather[0].description;
            if (item.lng && item.lat) {
              const coordinates = [item.lng, item.lat];

              const geometry = { type: 'Point', coordinates: coordinates };
              const feature = { type: 'Feature', geometry: geometry, properties: item };
              this.stationListFeatures.push(feature);
            }
          });
          this.stationList = res.data.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }

  initStationMap() {
    this.map_station = new mapboxgl.Map({
      container: 'map-station-device',
      style: environment.mapbox.style,
      zoom: 7,
      center: [100.523186, 13.736717],
    });
    this.map_station.dragRotate.disable();
    this.map_station.on('load', (event: mapboxgl.MapEvent) => {
      this.map_station.setPaintProperty('water', 'fill-color', '#aad3df');
      this.map_station.loadImage('assets/img/marker/pin-red.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${StatusEnum.Inactive}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-green.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${StatusEnum.Active}`, image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage('pin-null', image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage('pin-', image);
      });
      this.map_station.loadImage('assets/img/marker/pin-gray.png', (error, image) => {
        if (error) { throw error };
        this.map_station.addImage(`pin-${StatusEnum.Delete}`, image);
      });
      //------------------------------------------------------------------------------------------//

      this.map_station.addSource(this.station_src, {
        "type": 'geojson', "data": {
          "type": "FeatureCollection",
          "features": this.stationListFeatures
        }
      });

      //icon w-40 h-40
      this.map_station.addLayer({
        id: this.station_layer,
        source: this.station_src,
        type: 'symbol',
        layout: {
          'icon-allow-overlap': true,
          'icon-image': 'pin-{status}',
          "icon-size": 0.8,
          "icon-offset": [0, 0], //[x,y]
          'text-offset': [0, 0],
          'text-field': '{route}',
          'text-size': 12,
          'text-allow-overlap': true,
          'text-transform': 'uppercase',
        },
        paint: {
          "text-color": "#ffffff"
        },
        "filter": ["==", "$type", "Point"],
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false, anchor: 'top', offset: [0, -52]
      });

      this.map_station.on('click', this.station_layer, (event: mapboxgl.MapEvent) => {
        this.map_station.flyTo({ center: event.features[0].geometry.coordinates, zoom: 15 });
        const p = event.features[0].properties;
        this.clickMapStation(p);
      });
      this.map_station.on('mouseenter', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map_station.getCanvas().style.cursor = 'pointer';
        const p = e.features[0].properties;
        const htmltext = `<div class="font-3" style="padding-top: 10px;padding-left: 10px;padding-right: 10px;"><h6>${p.name}</h6></div>`;
        popup.setLngLat(e.features[0].geometry.coordinates)
          .setHTML(htmltext)
          .addTo(this.map_station);
      });
      this.map_station.on('mouseleave', this.station_layer, (e: mapboxgl.MapEvent) => {
        this.map_station.getCanvas().style.cursor = '';
        popup.remove();
      });
      this.updateMap();
    });

  }
  updateMap() {
    if (this.map_station.getSource(this.station_src) != null) {
      this.map_station.getSource(this.station_src).setData({
        'type': 'FeatureCollection',
        'features': this.stationListFeatures
      });
    }
  }
  clickMapStationList(lat, lng) {
    this.map_station.flyTo({ center: [lng, lat], zoom: 15 });
  }

  clickMapStation(p) {

  }

}

