import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { Station, ReqAddStation } from '../../../models/assets-management/station';
import { StatusEnum, RouteDirectionEnum, StationTypeEnum } from '../../../models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MediaInfo, ReqAddImage, GenMediaTemplate, GenMediaItem } from 'src/app/models/sign-control/media';
import { SignTypeService } from '../../../services/sign-control/sign-type.service';
import { BoardsignService } from '../../../services/sign-control/boardsign.service';
import { MediaService } from '../../../services/sign-control/media.service';
import { MediaType, MediaBehaviorType, MediaSettingType, SpecialTextType } from '../../../models/sign-control/enum';
import { UploadService } from '../../../services/sign-control/upload.service';
declare var domtoimage: any;
import { permission } from 'src/app/views/sign-control/_menu';
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {
  @ViewChild('addImageModal', { static: false }) public addImageModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteModal: ModalDirective;
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  @ViewChild('imInput') imInputVariable: ElementRef;
  @ViewChild('deleteMediaModal') public deleteMediaModal: ModalDirective;
  @ViewChild('confirmDeleteMediaModal') public confirmDeleteMediaModal: ModalDirective;
  @ViewChild('createImageModal') public createImageModal: ModalDirective;
  @ViewChild('mediaListCreateModal') public mediaListCreateModal: ModalDirective;
  specialTextType = SpecialTextType;
  mediaBehaviorType = MediaBehaviorType;
  statusEnum = StatusEnum;
  routeDirectionEnum = RouteDirectionEnum;
  stationTypeEnum = StationTypeEnum;
  mediaType = MediaType;
  speed_text = 90;
  currentPage: any = 1;
  itemsPerPage: any = 20;
  totalItems: any = 0;
  data_list: MediaInfo[] = [];


  is_loading: boolean = false;
  allowCustom: boolean = false;
  res_message: any;

  media_type_list = [
    { id: MediaType.Image, name: "ภาพนิ่ง" },
    { id: MediaType.Gif, name: "ภาพเคลื่อนไหว" }
  ];
  sign_type_list: any[] = [];
  data_sign_type: any[] = [];
  data_sign_type_modal: any[] = [];

  boardsign_list: any[] = [];
  data_boardsign_modal: any[] = [];
  boardsign_modal: any;

  sign_type_list_full: any[] = [];
  data_sign_type_create: any[] = [];
  sign_type_create: any;

  ratio_list: string[] = [];
  data_ratio: string[] = [];

  use_type_list = [
    { id: 1, name: "ประกาศ" },
    { id: 2, name: "แจ้งเตือน" }
  ];
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" }
  ];
  search_name: string;
  search_sign_type: any;
  search_ratio: any;
  search_media_type: any;
  search_use_type: any;
  sign_type_modal: any;
  status_modal: any;
  use_type_modal: any;

  is_loading_add: boolean = false;
  img_file: File;
  img_h: any;
  img_w: any;
  img_type: any;
  img_content_type: any;
  selectItem: any = {
    name: null
  };
  im_select: any;
  medie_sign_height: number = 100;

  is_public = true;

  isAdd: boolean;
  isCreate: boolean;
  constructor(private commonService: CommonService, private signTypeService: SignTypeService,
    private mediaService: MediaService, private uploadService: UploadService,
    private boardsignService: BoardsignService) { }

  async ngOnInit() {
    this.is_loading = true;
    this.getMedia();
    this.getBoardsign();
    this.getSignType();
    this.getSignTypeFull();
    this.getRatio();
    this.isAdd = await this.commonService.getPermission(permission.media.add_id).toPromise();
    this.isCreate = await this.commonService.getPermission(permission.media.create_id).toPromise();
  }

  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  getSignType() {
    this.signTypeService.getSignType(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.sign_type_list = _.cloneDeep(res.data);
              this.data_sign_type = _.cloneDeep(res.data);
              this.data_sign_type_modal = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }
  getSignTypeFull() {
    this.signTypeService.getSignType(null, null).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.sign_type_list_full = _.cloneDeep(res.data.data);
              this.data_sign_type_create = _.cloneDeep(res.data.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }

  handleFilterSignType(value) {
    if (value.length > 0) {
      this.data_sign_type = this.sign_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sign_type = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type = [];
    }
  }
  handleValueSignType(value) {
    if (value) {
      //console.log(value);
      this.search_sign_type = value;
    } else {
      this.search_sign_type = null;
    }
  }
  handleFilterSignTypeModal(value) {
    if (value.length > 0) {
      this.data_sign_type_modal = this.sign_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sign_type_modal = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type_modal = [];
    }
  }
  handleValueSignTypeModal(value) {
    if (value) {
      this.sign_type_modal = value;
    } else {
      this.sign_type_modal = null;
    }
  }
  handleValueMediaType(value) {
    if (value != null) {
      //console.log(value);
      this.search_media_type = value;
    } else {
      this.search_media_type = null;
    }
  }
  handleValueUseType(value) {
    if (value) {
      this.search_use_type = value;
    } else {
      this.search_use_type = null;
    }
  }
  handleValueUseTypeModal(value) {
    if (value) {
      this.use_type_modal = value;
    } else {
      this.use_type_modal = null;
    }
  }

  getBoardsign() {
    this.boardsignService.getBoardsign(null, null, null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.boardsign_list = _.cloneDeep(res.data);
              this.data_boardsign_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }

  handleFilterBoardsignModal(value) {
    if (value.length > 0) {
      this.data_boardsign_modal = this.boardsign_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_boardsign_modal = _.cloneDeep(this.boardsign_list);
    } else {
      this.data_boardsign_modal = [];
    }
  }
  handleValueBoardsignModal(value) {
    if (value) {
      this.boardsign_modal = value;
    } else {
      this.boardsign_modal = null;
    }
  }

  getRatio() {
    this.mediaService.getImageRatio().subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.ratio_list = _.cloneDeep(res.data);
              this.data_ratio = _.cloneDeep(res.data);
            }
          }
        }
        //this.is_loading = false;
      },
      error => {
        //this.is_loading = false;
      });
  }


  handleFilterRatio(value) {
    if (value.length > 0) {
      this.data_ratio = this.ratio_list.filter((s) => s.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_ratio = _.cloneDeep(this.ratio_list);
    } else {
      this.data_ratio = [];
    }
  }

  handleValueRatio(value) {
    if (value) {
      this.search_ratio = value;
    } else {
      this.search_ratio = null;
    }
  }

  pageChanged(event: any): void {
    if (this.currentPage != event.page) {
      this.currentPage = event.page;
      this.getMedia();
    }
  }

  search() {
    this.currentPage = 1;
    this.getMedia();
  }

  getMedia() {
    this.data_list = [];
    let media_type = null;
    let sign_type_id = null;
    let ratio = null;
    let use_type = null;
    if (this.search_media_type != null)
      media_type = this.search_media_type.id;
    if (this.search_sign_type)
      sign_type_id = this.search_sign_type.id;
    if (this.search_ratio)
      ratio = this.search_ratio;
    if (this.search_use_type)
      use_type = this.search_use_type.id;

    this.mediaService.getImage(this.currentPage, this.itemsPerPage, this.search_name, ratio, media_type, use_type, sign_type_id, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.data_list = res.data.data;
              this.totalItems = res.data.total_items;
            } else {
              this.totalItems = 0;
            }
          } else {
            this.totalItems = 0;
          }
        } else {
          this.totalItems = 0;
        }
        this.is_loading = false;
      },
      error => {
        this.is_loading = false;
      });
  }
  openAddImageModal() {
    this.selectItem = {
      name: null
    };
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.addImageModal.show();
  }
  detectFiles(event) {
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        this.img_file = file;
        if (file.type == 'image/gif') {
          this.img_type = MediaType.Gif;
        } else {
          this.img_type = MediaType.Image;
        }
        this.img_content_type = file.type.replace('image/', '.')
        if (this.img_content_type == '.jpeg')
          this.img_content_type = '.jpg';

        let reader = new FileReader();
        reader.onload = (e: any) => {
          let img = new Image();
          img.src = e.target.result;
          img.onload = () => {
            this.img_w = img.width;
            this.img_h = img.height;
          };

        }
        reader.readAsDataURL(file);
      }
    }
  }
  closeAddImage() {
    this.img_h = null;
    this.img_w = null;
    this.img_type = null;
    this.img_content_type = null;
    this.imInputVariable.nativeElement.value = "";
    this.img_file = null;
    this.boardsign_modal = null;
    this.is_public = true;
  }
  addImage(url?: string, sign_type_id?: number, is_create?: boolean) {
    this.is_loading = true;
    let req = new ReqAddImage();
    req.name = this.selectItem.name;
    req.img_url = url;
    req.sign_type_id = sign_type_id;
    if (this.is_public) {
      req.boardsign_id = null;
    } else {
      req.boardsign_id = this.boardsign_modal.id;
    }
    if (this.use_type_modal) {
      req.use_type = this.use_type_modal.id;
    }
    if (!is_create) {
      req.width = parseInt(this.img_w.toString());
      req.height = parseInt(this.img_h.toString());
      req.content_type = this.img_content_type;
      req.media_type = this.img_type;
    } else {
      req.width = parseInt(this.sign_type_create.width);
      req.height = parseInt(this.sign_type_create.height);
      req.content_type = '.png';
      req.media_type = this.mediaType.Image;
    }
    req.status = this.status_modal.id;
    req.behavior_type = MediaBehaviorType.Static;
    this.res_message = "";
    this.mediaService.addImage(req).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มรูปสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getMedia();
            }, 100);
            this.addImageModal.hide();
            this.createImageModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  bucket_name = 'sign-control';
  path = 'media';
  async addImageAsyn(sign_type_id?: number, is_create?: boolean) {
    let f = [this.img_file];
    if (is_create) {
      f = [this.media_file];
    }
    this.res_message = 'เพิ่มรูปไม่สำเร็จ';
    await this.uploadService.uploadImages(f, this.bucket_name, this.path).toPromise().then((res) => {
      if (res) {
        if (res.data) {
          this.addImage(res.data.img_urls[0], sign_type_id, is_create);
        } else {
          setTimeout(() => {
            this.swalError.fire();
            this.is_loading = false;
          }, 1000);
        }
      } else {
        setTimeout(() => {
          this.swalError.fire();
          this.is_loading = false;
        }, 1000);
      }
    }
    ).catch(error => {
      setTimeout(() => {
        this.swalError.fire();
        this.is_loading = false;
      }, 1000);
    });
  }
  openDeleteImageModal(im: MediaInfo) {
    this.im_select = im;
    this.deleteMediaModal.show();
  }

  OpenConfirmDeleteMediaModal() {
    this.confirmDeleteMediaModal.show();
    this.deleteMediaModal.hide();
  }
  medie_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  getSignNameTextStyles(w?: number) {
    let styles = {
      'width': w + 'px',
      'max-width': w + 'px',
      'white-space': 'nowrap',
      'overflow': 'hidden',
      'text-overflow': 'ellipsis'
    };
    return styles;
  }
  deleteImage(img: MediaInfo) {
    this.is_loading = true;
    let req = new ReqAddImage();
    req.status = StatusEnum.Delete;
    this.mediaService.deleteImageEditStatus(req, img.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบรูปสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getMedia();
            }, 100);
            this.confirmDeleteMediaModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }

  //----------------------------Create--------------------------//
  template_list: GenMediaTemplate[] = [];
  select_template: GenMediaTemplate;
  select_template_id: any;
  select_template_chil_id: any;
  mediaSettingType = MediaSettingType;
  select_setting_type: number = this.mediaSettingType.None;
  item_set_text: string = '';
  set_text_size: any = 40; // px;
  text_size: any = null; // px;
  openCreateModal() {
    this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
    this.createImageModal.show();
    if (this.sign_type_list_full.length > 0) {
      this.sign_type_create = this.sign_type_list_full[0];
      this.genTemplate();
      this.select_template = _.cloneDeep(this.template_list[0]);
      this.select_template_chil_id = this.select_template.children[0].id;
    }
  }
  handleFilterSignTypeModalCreate(value) {
    if (value.length > 0) {
      this.data_sign_type_create = this.sign_type_list_full.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sign_type_create = _.cloneDeep(this.sign_type_list_full);
    } else {
      this.data_sign_type_create = [];
    }
  }
  handleValueSignTypeModalCreate(value) {
    if (value) {
      this.sign_type_create = value;
    } else {
      this.sign_type_create = null;
    }

    this.genTemplate();
    this.select_template = _.cloneDeep(this.template_list[0]);
    this.select_template_chil_id = this.select_template.children[0].id;
    this.select_setting_type = this.mediaSettingType.None;
  }

  genTemplate() {
    this.template_list = [];
    if (this.sign_type_create.width == 100 && this.sign_type_create.height == 100) {
      let temp = new GenMediaTemplate();
      temp = { id: this.sign_type_create.aspect_ratio + '-1', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp.children.push({ id: this.sign_type_create.aspect_ratio + '-1-1', width: this.sign_type_create.width, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp);
    } else if (this.sign_type_create.width / this.sign_type_create.height == 2) {
      let temp = new GenMediaTemplate();
      temp = { id: this.sign_type_create.aspect_ratio + '-1', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp.children.push({ id: this.sign_type_create.aspect_ratio + '-1-1', width: this.sign_type_create.width, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp);
      let temp2 = new GenMediaTemplate();
      temp2 = { id: this.sign_type_create.aspect_ratio + '-2', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp2.children.push({ id: this.sign_type_create.aspect_ratio + '-2-1', width: this.sign_type_create.width / 2, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      temp2.children.push({ id: this.sign_type_create.aspect_ratio + '-2-2', width: this.sign_type_create.width / 2, height: this.sign_type_create.height, top: 0, left: this.sign_type_create.width / 2, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp2);
    } else if (this.sign_type_create.width / this.sign_type_create.height >= 1.3) {
      let temp = new GenMediaTemplate();
      temp = { id: this.sign_type_create.aspect_ratio + '-1', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp.children.push({ id: this.sign_type_create.aspect_ratio + '-1-1', width: this.sign_type_create.width, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp);
      let temp2 = new GenMediaTemplate();
      temp2 = { id: this.sign_type_create.aspect_ratio + '-2', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp2.children.push({ id: this.sign_type_create.aspect_ratio + '-2-1', width: (this.sign_type_create.width * 40) / 100, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      temp2.children.push({ id: this.sign_type_create.aspect_ratio + '-2-2', width: (this.sign_type_create.width * 60) / 100, height: this.sign_type_create.height, top: 0, left: (this.sign_type_create.width * 40) / 100, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp2);
      let temp3 = new GenMediaTemplate();
      temp3 = { id: this.sign_type_create.aspect_ratio + '-3', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp3.children.push({ id: this.sign_type_create.aspect_ratio + '-3-1', width: (this.sign_type_create.width * 60) / 100, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      temp3.children.push({ id: this.sign_type_create.aspect_ratio + '-3-2', width: (this.sign_type_create.width * 40) / 100, height: this.sign_type_create.height, top: 0, left: (this.sign_type_create.width * 60) / 100, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp3);
      let temp4 = new GenMediaTemplate();
      temp4 = { id: this.sign_type_create.aspect_ratio + '-4', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp4.children.push({ id: this.sign_type_create.aspect_ratio + '-4-1', width: this.sign_type_create.width, height: (this.sign_type_create.height * 60) / 100, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      temp4.children.push({ id: this.sign_type_create.aspect_ratio + '-4-2', width: this.sign_type_create.width, height: (this.sign_type_create.height * 40) / 100, top: (this.sign_type_create.height * 60) / 100, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp4);
      let temp5 = new GenMediaTemplate();
      temp5 = { id: this.sign_type_create.aspect_ratio + '-5', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp5.children.push({ id: this.sign_type_create.aspect_ratio + '-5-1', width: this.sign_type_create.width, height: (this.sign_type_create.height * 40) / 100, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      temp5.children.push({ id: this.sign_type_create.aspect_ratio + '-5-2', width: this.sign_type_create.width, height: (this.sign_type_create.height * 60) / 100, top: (this.sign_type_create.height * 40) / 100, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp5);
    } else if (this.sign_type_create.width / this.sign_type_create.height == 0.5) {
      let temp = new GenMediaTemplate();
      temp = { id: this.sign_type_create.aspect_ratio + '-1', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp.children.push({ id: this.sign_type_create.aspect_ratio + '-1-1', width: this.sign_type_create.width, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp);
      let temp2 = new GenMediaTemplate();
      temp2 = { id: this.sign_type_create.aspect_ratio + '-2', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp2.children.push({ id: this.sign_type_create.aspect_ratio + '-2-1', width: this.sign_type_create.width, height: this.sign_type_create.height / 2, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      temp2.children.push({ id: this.sign_type_create.aspect_ratio + '-2-2', width: this.sign_type_create.width, height: this.sign_type_create.height / 2, top: this.sign_type_create.height / 2, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp2);
    } else {
      let temp = new GenMediaTemplate();
      temp = { id: this.sign_type_create.aspect_ratio + '-1', width: this.sign_type_create.width, height: this.sign_type_create.height, children: [] };
      temp.children.push({ id: this.sign_type_create.aspect_ratio + '-1-1', width: this.sign_type_create.width, height: this.sign_type_create.height, top: 0, left: 0, text: null, text_size: this.set_text_size, img_url: null, type: 0 });
      this.template_list.push(temp);
    }
  }
  selectTemplate(temp?: GenMediaTemplate) {
    if (this.select_template.id != temp.id) {
      this.select_template = temp;
      this.select_template.children.forEach(item => {
        item.text = null;
        item.text_size = this.set_text_size;
        item.img_url = null;
        item.type = this.mediaSettingType.None;;
      });
      this.select_template_chil_id = this.select_template.children[0].id;
      this.item_set_text = null;
      this.text_size = null;
      this.select_setting_type = this.mediaSettingType.None;
    }
  }
  selectChildrenTemplate(chil?: GenMediaItem) {
    this.select_template_chil_id = chil.id;
    this.select_template.children.forEach(element => {
      if (element.id == this.select_template_chil_id) {
        this.select_setting_type = element.type;
        this.item_set_text = element.text;
        this.text_size = element.text_size;
      }
    });
  }

  setMediaTypeItem(event, item_id: any, type: number) {
    this.select_template.children.forEach(element => {
      if (element.id == item_id) {
        element.type = type;
        if (element.type == this.mediaSettingType.Text) {
          this.item_set_text = element.text;
          this.text_size = element.text_size;
        }
      }
    });
  }
  setMediaDataItem(item_id: any, type: number, data: string) {
    this.select_template.children.forEach(element => {
      if (element.id == item_id) {
        //element.type = type;
        if (type == this.mediaSettingType.Image) { // Image
          element.img_url = data;
          console.log(element.img_url);
        }
        if (type == this.mediaSettingType.Text) { // Text
          element.text = data;
        }
      }
    });
  }
  genImTextChange(value: string) {
    this.setMediaDataItem(this.select_template_chil_id, this.mediaSettingType.Text, value);
  }
  changeTextSize(item_id: any, type: number) {
    this.select_template.children.forEach(element => {
      if (element.id == item_id) {
        // type - = 1;
        if (type != 1) {
          if (element.text_size <= 20) {
            element.text_size = 20;
          } else {
            element.text_size = element.text_size - 2;
          }
        } else {
          // if (element.text_size >= 200) {
          //   element.text_size = 200;
          // } else {
          //   element.text_size = element.text_size + 2;
          // }
          element.text_size = element.text_size + 2;
        }
        this.text_size = element.text_size;
      }
    });
  }
  closeMediaCreateImModal(e) {
    if (e) {
      this.setMediaDataItem(this.select_template_chil_id, this.mediaSettingType.Image, e.path);
    }
    this.mediaListCreateModal.hide();
    this.toggleOpenMediaModal = false;
  }
  clickSelectMedia = false;
  toggleOpenMediaModal = false;
  openMediaListCreateModal() {
    this.mediaListCreateModal.show();
    this.toggleOpenMediaModal = true;
  }

  media_file: File;
  clickGenerate(sign_type_id?: number) {
    this.is_loading_add = true;
    var node = document.getElementById('preview1');
    domtoimage.toPng(node).then((dataUrl) => {
      this.media_file = this.dataURLtoFile(dataUrl, 'TestIm1.png');
      if (this.media_file) {
        this.addImageAsyn(sign_type_id, true);
      }
    }).catch((error) => {
      console.error('oops, something went wrong!', error);
    })
    this.is_loading_add = false;
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getModalStyles(ratio: string, item_height: number) {
    let w = 0;
    let h = 0;
    if (ratio) {
      let res = ratio.split('x');
      h = item_height;
      w = (+res[1] / +res[0]) * item_height;
      if (w == item_height) {
        w = 230;
      }
      w = w + 40;
    }
    let styles = {
      'min-width': w + 'px'
    };
    return styles;
  }
  //-----------------------------------------------------------//
}
