import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-camera-stream',
  templateUrl: './camera-stream.component.html',
  styleUrls: ['./camera-stream.component.scss']
})
export class CameraStreamComponent implements OnInit {
  cctv_list:any[] = [];
  cctv_list1:any[] = [
    { id:1, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_176.stream/playlist.m3u8"},
    { id:2, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_138.stream/playlist.m3u8"},
    { id:3, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_180.stream/playlist.m3u8"},
    { id:4, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_134.stream/playlist.m3u8"},
    { id:5, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_192.stream/playlist.m3u8"},
    { id:6, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_182.stream/playlist.m3u8"},
    { id:7, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_130.stream/playlist.m3u8"},
    { id:8, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_131.stream/playlist.m3u8"},
    { id:9, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_177.stream/playlist.m3u8"},
    { id:10, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_132.stream/playlist.m3u8"},
    { id:11, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_133.stream/playlist.m3u8"},
    { id:12, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_194.stream/playlist.m3u8"},
    { id:13 ,rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:14, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:15, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:16, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:17, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:18, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:19, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"},
    { id:20, rtsp_link: "http://180.180.242.208:1935/TOC/TOC_175.stream/playlist.m3u8"}
    
  ];
  constructor() { }

  ngOnInit(): void {
    this.cctv_list = this.cctv_list1;
    console.log(this.cctv_list);
    
  }

}
