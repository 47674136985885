import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { StatusEnum, MonitorTypeEnum, MaStatusEnum, RouteDirectionEnum } from 'src/app/models/common-enum';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import * as _ from 'lodash';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from 'src/app/services/common.service';
import { BoardsignInfo, SignInfo, SensorConfig, ReqAddSign, BoardSignBrightness } from 'src/app/models/sign-control/boardsign';
import { SpecialTextItem } from 'src/app/models/sign-control/media';
import { MediaType, SignDirection } from 'src/app/models/sign-control/enum';
import { BoardsignService } from 'src/app//services/sign-control/boardsign.service';
import { SignTypeService } from 'src/app/services/sign-control/sign-type.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-boardsign-management',
  templateUrl: './boardsign-management.component.html',
  styleUrls: ['./boardsign-management.component.scss']
})
export class BoardsignManagementComponent implements OnInit, OnChanges {
  @Input() clickOpen: boolean;
  @Input() isEditModalOpen: boolean;
  @Input() boardsignInfo: BoardsignInfo;
  @Input() getBoard: boolean;
  @Input() isAddSign: boolean;
  @Input() isEditSign: boolean;
  boardInfo: BoardsignInfo;
  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickEditBoardsign: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('infoModal', { static: false }) public infoModal: ModalDirective;
  @ViewChild('editBoardsignModal', { static: false }) public editBoardsignModal: ModalDirective;
  @ViewChild('addModal', { static: false }) public addSignModal: ModalDirective;
  @ViewChild('deleteModal', { static: false }) public deleteSignModal: ModalDirective;
  @ViewChild('boardsignBrightnessModal', { static: false }) public boardsignBrightnessModal: ModalDirective;
  
  @ViewChild('swalSuccess', { static: false }) private swalSuccess: SwalComponent;
  @ViewChild('swalError', { static: false }) private swalError: SwalComponent;
  mediaType = MediaType;
  statusEnum = StatusEnum;
  signs: SignInfo[] = [];
  signItem: SignInfo = {
    position_x: null,
    position_y: null
  };
  res_message: string;
  set_sign_height: number = 100;
  status_list = [
    { id: StatusEnum.Active, name: "Active" },
    { id: StatusEnum.Inactive, name: "Inactive" },
    { id: StatusEnum.Ma, name: "MA" }
  ];
  status_modal: any;
  allowCustom: boolean = false;
  dir_list = [
    { id: SignDirection.Front, name: "ป้ายหน้า" },
    { id: SignDirection.Back, name: "ป้ายหลัง" }
  ];
  dir_modal: any;
  sign_type_list: any[] = [];
  data_sign_type_modal: any[] = [];
  sign_type_modal: any;

  sign_cover_lane_list: any[] = [];
  actionType_sign: number; // 1 = ADD , 2 = EDIT, 3 = DELETE
  is_loading: boolean = false;

  brightness_list: BoardSignBrightness[] = [];
  brightness_req: BoardSignBrightness[] = [];
  constructor(private boardsignService: BoardsignService, private signTypeService: SignTypeService, private datePipe: DatePipe) { }


  ngOnInit(): void {
    this.getSignType();

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.boardsignInfo) {
      if (changes.boardsignInfo.currentValue) {
        this.boardInfo = _.cloneDeep(changes.boardsignInfo.currentValue);
      }
    }
    if (this.clickOpen) {
      //this.boardInfo = _.cloneDeep(this.boardsignInfo);
      if (!changes.isEditModalOpen) {
        // if (changes.boardsignInfo) {
        //   if (changes.boardsignInfo.firstChange) {
        //     this.boardInfo = _.cloneDeep(this.boardsignInfo);
        //   }
        // }
        this.signs = [];
        this.getSigns();
        this.openManageModal();
        this.sign_cover_lane_list = [];
        for (let i = 0; i < this.boardInfo.lane_count; i++) {
          const lane = { id: i + 1, check: false }
          this.sign_cover_lane_list.push(lane);
        }
        this.getBrightness();
      }
    }
    if (changes.getBoard) {
      if (changes.getBoard.currentValue) {
        this.getBoardsignByID(this.boardsignInfo.id);
      }
    }

  }
  async getBoardsignByID(id?: number) {
    this.boardsignService.getBoardsignByID(id).subscribe(
      res => {
        if (res.code == 1) {
          this.boardInfo = _.cloneDeep(res.data);
          this.sign_cover_lane_list = [];
          for (let i = 0; i < this.boardInfo.lane_count; i++) {
            const lane = { id: i + 1, check: false }
            this.sign_cover_lane_list.push(lane);
          }
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  getSignType() {
    this.signTypeService.getSignType(null, null, 1).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.sign_type_list = _.cloneDeep(res.data);
              this.data_sign_type_modal = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }
  handleValueStatusModal(value) {
    if (value) {
      this.status_modal = value;
    } else {
      this.status_modal = null;
    }
  }
  handleValueDirModal(value) {
    if (value) {
      this.dir_modal = value;
    } else {
      this.dir_modal = null;
    }
  }
  openManageModal() {
    this.infoModal.show();
  }
  clickCloseManageModal() {
    this.infoModal.hide();
    this.onClose.emit(null);
  }
  openEditBoardsignModal() {
    this.onClickEditBoardsign.emit(this.boardInfo);
  }
  onHide() {

  }
  clearData() {
    this.getBoardsignByID(this.boardsignInfo.id);
  }

  get_sign_width(ratio: string, set_sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return set_sign_height * r;
    } else {
      return 0;
    }
  }
  async getSigns() {
    this.signs = [];
    this.boardsignService.getSign(this.boardsignInfo.id).subscribe(
      res => {
        if (res.code == 1) {
          this.signs = res.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  handleFilterSignTypeModal(value) {
    if (value.length > 0) {
      this.data_sign_type_modal = this.sign_type_list.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_sign_type_modal = _.cloneDeep(this.sign_type_list);
    } else {
      this.data_sign_type_modal = [];
    }
  }
  handleValueSignTypeModal(value) {
    if (value) {
      this.sign_type_modal = value;
    } else {
      this.sign_type_modal = null;
    }
  }
  clickSignItem(sign_item?: SignInfo, type?: number) {
    this.actionType_sign = type;
    if (type == 1) {
      this.signItem = new SignInfo();
      this.status_modal = this.status_list.find(x => x.id == StatusEnum.Active);
      this.dir_modal = null;
      this.signItem.position_x = null;
      this.signItem.position_y = null;
      this.sign_type_modal = null;
      this.sign_cover_lane_list.forEach(item => {
        item.check = false;
      });
      this.addSignModal.show();
    } else if (type == 2) {
      this.signItem = _.cloneDeep(sign_item);
      this.status_modal = this.status_list.find(x => x.id == this.signItem.status);
      this.sign_type_modal = this.sign_type_list.find(x => x.id == this.signItem.sign_type);
      this.dir_modal = this.dir_list.find(x => x.id == this.signItem.direction);
      let lane_list = [];
      if (this.signItem.cover_lane) {
        lane_list = this.signItem.cover_lane.split(',');
      }
      this.sign_cover_lane_list.forEach(item => {
        const find = lane_list.find(x => +x == item.id);
        if (find) {
          item.check = true;
        } else {
          item.check = false;
        }
      });
      this.addSignModal.show();
    } else if (type == 3) {
      this.signItem = _.cloneDeep(sign_item);
      this.deleteSignModal.show();
    }
  }
  changeLanelist(e: any, sign?: SignInfo) {
    // this.sign_cover_lane_list.forEach(item => {
    //   if (item.id == sign.id) {
    //     if (item.check) {
    //       item.check = false;
    //     }
    //     else {
    //       item.check = true;
    //     }
    //   }
    // });
  }
  addSign() {
    this.is_loading = true;
    let req = new ReqAddSign();
    req.sign_type = this.sign_type_modal.id;
    req.direction = this.dir_modal.id;
    req.position_x = +this.signItem.position_x;
    req.position_y = +this.signItem.position_y;
    let lane_list = this.sign_cover_lane_list.filter(x => x.check == true).map(a => a.id);

    if (lane_list.length > 0) {
      let lane_txt = lane_list.join(',');
      req.cover_lane = lane_txt;
    }
    req.status = this.status_modal.id;
    this.res_message = "";
    this.boardsignService.addSign(req, this.boardsignInfo.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `เพิ่มป้ายแสดงผลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSigns();
            }, 100);
            this.addSignModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });

  }
  editSign() {
    this.is_loading = true;
    let req = new ReqAddSign();
    req.sign_type = this.sign_type_modal.id;
    req.direction = this.dir_modal.id;
    req.position_x = +this.signItem.position_x;
    req.position_y = +this.signItem.position_y;
    let lane_list = this.sign_cover_lane_list.filter(x => x.check == true).map(a => a.id);

    if (lane_list.length > 0) {
      let lane_txt = lane_list.join(',');
      req.cover_lane = lane_txt;
    }
    req.status = this.status_modal.id;
    this.res_message = "";

    this.boardsignService.editSign(req, this.signItem.id, this.boardsignInfo.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `แก้ไขป้ายแสดงผลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSigns();
            }, 100);
            this.addSignModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });

  }
  deleteSign() {
    this.is_loading = true;
    let req = new ReqAddSign();
    req.sign_type = this.signItem.sign_type;
    req.direction = this.signItem.direction;
    req.position_x = +this.signItem.position_x;
    req.position_y = +this.signItem.position_y;
    let lane_list = this.sign_cover_lane_list.filter(x => x.check == true).map(a => a.id);
    if (lane_list.length > 0) {
      let lane_txt = lane_list.join(',');
      req.cover_lane = lane_txt;
    }
    req.status = StatusEnum.Delete;
    this.res_message = "";
    this.boardsignService.deleteSignEditStatus(req, this.signItem.id, this.boardsignInfo.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ลบป้ายแสดงผลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getSigns();
            }, 100);
            this.deleteSignModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });
  }
  getBrightness() {
    this.brightness_list = [];
    this.boardsignService.getBrightness(this.boardInfo.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            if (res.data) {
              this.brightness_list = _.cloneDeep(res.data);
            }
          }
        }
      },
      error => { });
  }
  openBoardsignBrightnessModal(){
    this.brightness_req = _.cloneDeep(this.brightness_list);
    this.brightness_req.forEach(item => {
      const st = item.time_st.split(":");
      const en = item.time_en.split(":");
      item.st = new Date(new Date().setHours(parseInt(st[0]),parseInt(st[1])));
      item.en = new Date(new Date().setHours(parseInt(en[0]),parseInt(en[1])));
    });
    this.boardsignBrightnessModal.show();
  }
  onHideBrightnessModal(){
    this.getBrightness();
  }
  addBrightnessItem(){
    const b = {time_st:"00:00",time_en:"00:00",percent:100,st : new Date(new Date().setHours(0,0)),en : new Date(new Date().setHours(0,0))};
    this.brightness_req.push(b);
  }
  deleteBrightnessItem(item?: any) {
    const index: number = this.brightness_req.indexOf(item);
    if (index !== -1) {
      this.brightness_req.splice(index, 1);
    }
  }
  updateBrightness() {
    this.is_loading = true;
    let req: BoardSignBrightness[] = [];
    this.brightness_req.forEach(item => {
      let b = new BoardSignBrightness();
      b.percent = parseInt(item.percent.toString());
      b.time_st = `${this.datePipe.transform(item.st, 'HH:mm')}`;
      b.time_en = `${this.datePipe.transform(item.en, 'HH:mm')}`;
      req.push(b);
    });
    this.res_message = "";

    this.boardsignService.addBrightness(req, this.boardsignInfo.id).subscribe(
      res => {
        if (res) {
          if (res.code == 1) {
            this.res_message = `ตั้งค่าความสว่างของป้ายแสดงผลสำเร็จ`;
            setTimeout(() => {
              this.swalSuccess.fire();
              this.is_loading = false;
              this.getBrightness();
            }, 100);
            this.boardsignBrightnessModal.hide();
          } else {
            this.res_message = res.message;
            setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
          }
        } else {
          setTimeout(() => { this.swalError.fire(); this.is_loading = false; }, 100);
        }
      },
      error => {
        console.log(error);
        if (error) {
          if (error.status == 403) {

          } else {
            const error_text = error.error.message || error.statusText;
            this.res_message = error_text;
            this.is_loading = false;
            setTimeout(() => { this.swalError.fire() }, 1000);
          }
        } else {
          setTimeout(() => { this.swalError.fire() }, 1000);
        }
        this.is_loading = false;
      });

  }

}