import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { StationService } from 'src/app/services/assets-management/station.service';
import { Station } from 'src/app/models/assets-management/station';
import { BoardsignInfo } from 'src/app/models/sign-control/boardsign';
import { BoardsignService } from 'src/app/services/sign-control/boardsign.service';
import { CorridorControlService } from 'src/app/services/corridor-control/corridor-control.service';
import { environment } from 'src/environments/environment';
import { interval } from 'rxjs';
import { PlotBand } from '@progress/kendo-angular-charts';
import { RouteDirectionEnum, StatusEnum } from 'src/app/models/common-enum';
import { MediaType, SignDirection, SpecialTextType, MediaBehaviorType } from 'src/app/models/sign-control/enum';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ReversibleService } from 'src/app/services/user/reversible.service';

@Component({
  selector: 'app-corridor-control-m7',
  templateUrl: './corridor-control-m7.component.html',
  styleUrls: ['./corridor-control-m7.component.scss'],
  exportAs: 'child'
})
export class CorridorControlM7Component implements OnInit {
  @ViewChild('boardsingInfoModal', { static: false }) public boardsingInfoModal: ModalDirective;

  specialTextType = SpecialTextType;
  mediaType = MediaType;
  signDir = SignDirection;
  directionEnum = RouteDirectionEnum;
  boardsignInfo: any;
  corridor_img = "/assets/img/corridor-2.png";
  road_img = "/assets/img/road.png";
  road_img_height = 500;
  road_img1 = "/assets/img/road-1.png";
  cctv_list : any = [];
  subscription: any;
  search_station: any;
  data_station_search: Station[];
  station_search_list: Station[];
  public allowCustom: boolean = false;

  public showLabels = false;
  public showTicks = false;
  public reverse = false;
  public value = 60;

  public startAngle = -30;
  public endAngle = 210;
  public rangeSize = 12;

  public rangeLineCap = "round";

  public rangePlaceholderColor = "#e6e5e5";
  public ticksColor = "#008000";
  public labelsColor = "#0000ff";

  public style: string = 'smooth';
  public dashType: string = 'dot';
  markers = { size: 3, background: null };
  data_normal_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_normal_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_normal_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_normal_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_reverse_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
  data_categories = [];
  data_categories1 = [];
  data_categories2 = [];
  data = {
    normal_speed_in: 88,
    reverse_speed_in: null,
    normal_flow_in: 758,
    reverse_flow_in: null,
    normal_speed_out: 85,
    reverse_speed_out: null,
    normal_flow_out: 987,
    reverse_flow_out: null,
  }
  public categoryPlotBands: PlotBand[] = [{
    from: null,
    to: null,
    color: null,
    opacity: 0
  }];
  lane_count = 4;
  lane_height = 70;
  sign_height = 40;
  set_sign_height_modal = 80;
  st_km = 45;
  en_km = 52;
  km_list = [];

  is_loading = false;
  boardsign_list = [];
  is_loading_lms = true;
  view = 1;
  devices = [
    // {
    //   device_id: 2847,
    //   link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-PTZ",
    //   view: "กล้องส่องถนน",
    //   name: "CCTV (1-58+000) PTZ ส่องถนน",
    //   rtsp_link: "http://180.180.242.208:1935/TOC/TOC_134.stream/playlist.m3u8"
    // },
    // {
    //   device_id: 2318,
    //   link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-CTV-L",
    //   view: "กล้องส่องถนน",
    //   name: "CCTV (1-58-000) กล่องป้ายซ้าย"
    // },
    // {
    //   device_id: 2324,
    //   link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-CTV-R",
    //   view: "กล้องส่องถนน",
    //   name: "CCTV (1-58-000) ส่องป้ายขวา"
    // },
    // {
    //   device_id: 2323,
    //   link: "http://camera.shw.transcodeglobal.com/?code=0001-0058000-2-LMS-CTV-B",
    //   view: "กล้องส่องถนน",
    //   name: "CCTV (1-58-000) ส่องป้ายหลัง"
    // }
  ]
  sta_selected : number
  padding_m = 500;

  maxIn : number = 1000
  maxOut : number = 1000
  constructor(private stationService: StationService, private boardsignService: BoardsignService, private cS: CorridorControlService,
    private rS:ReversibleService,
  ) { }

  ngOnInit(): void {
    this.getImgEvt()
    for (var i = this.st_km; i <= this.en_km; i++) {
      this.km_list.push(i);
    }
    this.getStationSearch();
    this.getBoardsign();
    setTimeout(() => {
      this.getReversibleTimeseries();
    }, 2000);

    this.subscription = interval(environment.timeInterval * 5).subscribe((x) => {
      this.getReversibleTimeseries();
    });
  }
  async getImgEvt(){
    let res = await this.cS.getCorridorImgEvt()
    if(res) this.cctv_list = res
  }
  changeView(id) {
    this.view = id;
    if (this.view == 1) {
      this.data_categories = _.cloneDeep(this.data_categories2);
    } else {
      this.data_categories = _.cloneDeep(this.data_categories1);
    }

  }
  async getStationSearch() {
    let res : any = await this.rS.getReversible()
    if(res){
      this.station_search_list = _.cloneDeep(res);
      this.data_station_search = _.cloneDeep(res);
      if (this.data_station_search.length > 0) {
        this.search_station = this.data_station_search[0]
        this.sta_selected = this.data_station_search[0].id
      }
    }
    // this.stationService.getStation(null, null, 1).subscribe(
    //   res => {
    //     if (res) {
    //       if (res.code == 1) {
    //         if (res.data) {
    //           this.station_search_list = _.cloneDeep(res.data.data);
    //           this.data_station_search = _.cloneDeep(res.data.data);
    //           if (this.data_station_search.length > 0)
    //             this.search_station = this.data_station_search[0];
    //         }
    //       }
    //     }
    //     //this.is_loading = false;
    //   },
    //   error => {
    //     //this.is_loading = false;
    //   });
  }
  handleFilterStation(value) {
    if (value.length > 0) {
      this.data_station_search = this.station_search_list.filter(x=>x.name != null).filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else if (value.length == 0) {
      this.data_station_search = _.cloneDeep(this.station_search_list);
    } else {
      this.data_station_search = [];
    }
  }
  handleValueStation(value) {

    if (value) {
      this.sta_selected = value.id
      this.search_station = value;
    } else {
      this.search_station = null;
    }
    this.getReversibleTimeseries()
  }

  getData() {
    this.getReversibleTimeseries();
  }

  async getReversibleTimeseries() {
    let sta = this.sta_selected;
    if (sta) {
      let pcu = null;
      this.data_normal_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_speed_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_normal_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_flow_in = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_normal_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_speed_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_normal_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data_reverse_flow_out = { categories: [], current: [], max: [], avg: [], min: [], current_bullet: [] };
      this.data = {
        normal_speed_in: null,
        reverse_speed_in: null,
        normal_flow_in: null,
        reverse_flow_in: null,
        normal_speed_out: null,
        reverse_speed_out: null,
        normal_flow_out: null,
        reverse_flow_out: null,
      }
      this.categoryPlotBands = [{
        from: null,
        to: null,
        color: null,
        opacity: 0
      }];
      this.cS.getReversibleTimeseries(sta, pcu).subscribe(
        res => {
          if (res.code == 1) {
            if (res.data) {
              if (res.data.in) {
                if (res.data.in.normal) {
                  this.data.normal_speed_in = res.data.in.normal.avg_speed;
                  this.data.normal_flow_in = res.data.in.normal.flow;
                  //this.data_normal_speed_in.categories = _.map(res.data.in.normal.avg_speed_graph, 'hour');
                  // let c = [];
                  // res.data.in.normal.avg_speed_graph.forEach(item => {
                  //   let s = item.hour.toString().split(":");
                  //   if(s.length>1){
                  //     if(s[1] == '00'){
                  //       c.push(parseInt(s[1]));
                  //     }else{
                  //       c.push(null);
                  //     }
                  //   }else{
                  //     c.push(item.hour);
                  //   }
                  // });
                  let current_index = res.data.in.normal.avg_speed_graph.findIndex(x => x.is_current == 1);
                  this.categoryPlotBands = [{
                    from: current_index,
                    to: current_index,
                    color: '#28b4c8',
                    opacity: 0.8
                  }];

                  let arr = this.getHour(res.data.in.normal.flow_graph);
                  this.data_categories1 = this.getHour(res.data.in.normal.flow_graph);
                  this.data_categories2 = [];
                  arr.forEach((item, index) => {
                    if (index % 3 == 0) {
                      this.data_categories2.push(item);
                    } else {
                      this.data_categories2.push("");
                    }
                  });
                  if (this.view == 1) {
                    this.data_categories = _.cloneDeep(this.data_categories2);
                  } else {
                    this.data_categories = _.cloneDeep(this.data_categories1);
                  }
                  this.data_normal_speed_in.categories = this.getHour(res.data.in.normal.avg_speed_graph);
                  this.data_normal_speed_in.current_bullet = [this.data_normal_speed_in.current[5], this.data_normal_speed_in.categories[5]];
                  this.data_normal_speed_in.current = _.map(res.data.in.normal.avg_speed_graph, 'current_value');
                  this.data_normal_speed_in.max = _.map(res.data.in.normal.avg_speed_graph, 'max_old_value');
                  this.data_normal_speed_in.avg = _.map(res.data.in.normal.avg_speed_graph, 'avg_old_value');
                  this.data_normal_speed_in.min = _.map(res.data.in.normal.avg_speed_graph, 'min_old_value');

                  this.data_normal_flow_in.categories = this.getHour(res.data.in.normal.flow_graph);
                  console.log()
                  this.data_normal_flow_in.current = _.map(res.data.in.normal.flow_graph, 'current_value');
                  this.data_normal_flow_in.max = _.map(res.data.in.normal.flow_graph, 'max_old_value');
                  this.data_normal_flow_in.avg = _.map(res.data.in.normal.flow_graph, 'avg_old_value');
                  this.data_normal_flow_in.min = _.map(res.data.in.normal.flow_graph, 'min_old_value');
                  for (const i of res.data.in.normal.flow_graph) {
                    if(i.current_value>this.maxIn) this.maxIn = i.current_value 
                    if(i.max_old_value>this.maxIn) this.maxIn = i.max_old_value 
                    if(i.avg_old_value>this.maxIn) this.maxIn = i.avg_old_value 
                    if(i.min_old_value>this.maxIn) this.maxIn = i.min_old_value 
                  }
                  this.maxIn = this.maxIn+1000
                }
                if (res.data.in.reverse) {

                  this.data.reverse_speed_in = res.data.in.reverse.avg_speed;
                  this.data.reverse_flow_in = res.data.in.reverse.flow;

                  this.data_reverse_speed_in.categories = this.getHour(res.data.in.reverse.avg_speed_graph);
                  this.data_reverse_speed_in.current = _.map(res.data.in.reverse.avg_speed_graph, 'current_value');
                  this.data_reverse_speed_in.max = _.map(res.data.in.reverse.avg_speed_graph, 'max_old_value');
                  this.data_reverse_speed_in.avg = _.map(res.data.in.reverse.avg_speed_graph, 'avg_old_value');
                  this.data_reverse_speed_in.min = _.map(res.data.in.reverse.avg_speed_graph, 'min_old_value');

                  this.data_reverse_flow_in.categories = this.getHour(res.data.in.reverse.flow_graph);
                  this.data_reverse_flow_in.current = _.map(res.data.in.reverse.flow_graph, 'current_value');
                  this.data_reverse_flow_in.max = _.map(res.data.in.reverse.flow_graph, 'max_old_value');
                  this.data_reverse_flow_in.avg = _.map(res.data.in.reverse.flow_graph, 'avg_old_value');
                  this.data_reverse_flow_in.min = _.map(res.data.in.reverse.flow_graph, 'min_old_value');
                }
              }
              if (res.data.out) {
                if (res.data.out.normal) {
                  this.data.normal_speed_out = res.data.out.normal.avg_speed;
                  this.data.normal_flow_out = res.data.out.normal.flow;

                  this.data_normal_speed_out.categories = this.getHour(res.data.out.normal.avg_speed_graph);
                  this.data_normal_speed_out.current = _.map(res.data.out.normal.avg_speed_graph, 'current_value');
                  this.data_normal_speed_out.max = _.map(res.data.out.normal.avg_speed_graph, 'max_old_value');
                  this.data_normal_speed_out.avg = _.map(res.data.out.normal.avg_speed_graph, 'avg_old_value');
                  this.data_normal_speed_out.min = _.map(res.data.out.normal.avg_speed_graph, 'min_old_value');

                  this.data_normal_flow_out.categories = this.getHour(res.data.out.normal.flow_graph);
                  this.data_normal_flow_out.current = _.map(res.data.out.normal.flow_graph, 'current_value');
                  this.data_normal_flow_out.max = _.map(res.data.out.normal.flow_graph, 'max_old_value');
                  this.data_normal_flow_out.avg = _.map(res.data.out.normal.flow_graph, 'avg_old_value');
                  this.data_normal_flow_out.min = _.map(res.data.out.normal.flow_graph, 'min_old_value');
                }
                for (const i of res.data.out.normal.flow_graph) {
                  if(i.current_value>this.maxOut) this.maxOut = i.current_value 
                  if(i.max_old_value>this.maxOut) this.maxOut = i.max_old_value 
                  if(i.avg_old_value>this.maxOut) this.maxOut = i.avg_old_value 
                  if(i.min_old_value>this.maxOut) this.maxOut = i.min_old_value 
                }
                this.maxOut = this.maxOut+1000
                if (res.data.out.reverse) {
                  this.data.reverse_speed_out = res.data.out.reverse.avg_speed;
                  this.data.reverse_flow_out = res.data.out.reverse.flow;

                  this.data_reverse_speed_out.categories = this.getHour(res.data.out.reverse.avg_speed_graph);
                  this.data_reverse_speed_out.current = _.map(res.data.out.reverse.avg_speed_graph, 'current_value');
                  this.data_reverse_speed_out.max = _.map(res.data.out.reverse.avg_speed_graph, 'max_old_value');
                  this.data_reverse_speed_out.avg = _.map(res.data.out.reverse.avg_speed_graph, 'avg_old_value');
                  this.data_reverse_speed_out.min = _.map(res.data.out.reverse.avg_speed_graph, 'min_old_value');

                  this.data_reverse_flow_out.categories = this.getHour(res.data.out.reverse.flow_graph);
                  this.data_reverse_flow_out.current = _.map(res.data.out.reverse.flow_graph, 'current_value');
                  this.data_reverse_flow_out.max = _.map(res.data.out.reverse.flow_graph, 'max_old_value');
                  this.data_reverse_flow_out.avg = _.map(res.data.out.reverse.flow_graph, 'avg_old_value');
                  this.data_reverse_flow_out.min = _.map(res.data.out.reverse.flow_graph, 'min_old_value');
                }
              }
            }

          }
        },
        err => {
          console.log(JSON.stringify(err.statusText));
        });
    }
  }

  getHour(list?: any) {
    let c = [];
    list.forEach(item => {
      let s = item.hour.toString().split(":");
      if (s.length > 1) {
        if (s[1] == '00') {
          c.push(parseInt(s[0]));
        } else {
          c.push('');
        }
      } else {
        c.push(item.hour);
      }
    });
    return c;
  }

  //--------------------------------------------------------------------------------//
  async getBoardsign() {
    //this.is_loading = true;
    let skip = null
    let take = null
    let name = null;
    let route = '0007';
    let status = null;
    let st_km = 45;
    let en_km = 52;
    let st_m = null;
    let en_m = null;

    let res = await this.boardsignService.getBoardsign(skip, take, name, route, null, status, st_km, en_km, st_m, en_m).toPromise().catch((err) => {
      this.boardsign_list = [];
      console.log(err);
      //this.is_loading = false;
    });

    if (res) {
      if (res.code == 1) {
        this.boardsign_list = await this.genSignsLayerView(res.data.data, false);
        // this.itemList.forEach(async (item) => {
        //   if(item.sensor_id && item.signs){
        //     const res_config_road = await this.signSettingService.getConfigRoad(item.sensor_id).toPromise();
        //     item.config_road = res_config_road.data;
        //   }
        // });
        //this.totalItems = res.data.total_items;
      } else {
        this.boardsign_list = [];
      }
    } else {
      this.boardsign_list = [];
    }
    //this.is_loading = false;
    this.is_loading_lms = false;

  }

  async genSignsLayerView(boards?: BoardsignInfo[], is_suggest?: boolean) {
    boards.forEach(board => {
      if (board.signs) {
        board.signs = board.signs.filter(x => x.status == StatusEnum.Active);
        if (board.signs.length > 0 && board.station.boardsign_structure) {
          // layers
          //const layers_text = board.station.boardsign_structure.split("|");
          const layer_no_list = _.sortedUniq(board.signs.map(a=>a.layer_no));
          let layers = [];
          let layers_new = [];
          let layers_sug = [];
          let is_dir_back = false;
          const find_sign_back = board.signs.filter(x=>x.direction == this.signDir.Back);
          if(find_sign_back.length>0)
            is_dir_back = true;
          layer_no_list.forEach((l, index) => {
            // signs
            let signs = [];
            let signs_new = [];
            let find_sign = board.signs.filter(x => x.layer_no == l);

            if (find_sign) {
              find_sign.forEach(s => {
                if (s.media) {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: s.media };
                  signs.push(d);
                } else {
                  let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null };
                  signs.push(d);
                }
                if (is_suggest) {
                  signs_new.push({ id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, sign_type_object: s.sign_type_object, dir: s.direction, media: null });
                }
              });
              signs = _.sortBy(signs, ['sign_no']);
              layers.push(signs);
              if (is_suggest) {
                signs_new = _.sortBy(signs_new, ['sign_no']);
                layers_new.push(signs_new);
              }
            }

            if (is_suggest) {
              board.signs_suggest = board.signs_suggest.filter(x => x.status == StatusEnum.Active);
              let find_sign_sug = board.signs_suggest.filter(x => x.layer_no == l);
              let signs_sug = [];
              if (find_sign_sug) {
                find_sign_sug.forEach(s => {
                  if (s.media) {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: s.media };
                    signs_sug.push(d);
                  } else {
                    let d = { id: s.id, sign_no: s.sign_no, sign_type_id: s.sign_type_id, ratio: s.sign_type_object.aspect_ratio, dir: s.direction, media: null };
                    signs_sug.push(d);
                  }
                });
                signs_sug = _.sortBy(signs_sug, ['sign_no']);
                layers_sug.push(signs_sug);
              }
            }
          });
          board.is_dir_back = is_dir_back;
          board.layers = layers;
          if (is_suggest) {
            board.layers_suggest = _.cloneDeep(layers_sug);
            board.layers_new = _.cloneDeep(layers_new);
          }
        } else {
          board.signs = [];
          board.layers = [];
          board.layers_suggest = [];
          board.layers_new = [];
        }
      } else {
        board.signs = [];
        board.layers = [];
        board.layers_suggest = [];
        board.layers_new = [];
      }
    });
    return boards;
  }
  get_sign_width(ratio: string, sign_height) {
    if (ratio) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return sign_height * r;
    } else {
      return 0;
    }
  }
  async clickOpenInfomodal(boardsign) {
    this.is_change_media = false;
    this.boardsignInfo = boardsign;

    let cameraData = await this.boardsignService.getBoardsignCamera([boardsign.id]).toPromise().catch(
      err => {
        console.log(JSON.stringify(err.statusText));
      });
    if(cameraData && cameraData.data && cameraData.data.data && cameraData.data.data[0] && cameraData.data.data[0].camera) {
      this.devices = cameraData.data.data[0].camera.map((x) => {
        return {
          device_id: null,
          name: x.name,
          link: x.url,
          view: ""
        }
      })
    }

    this.boardsingInfoModal.show()
  }
  onHideInfoModal() {

  }
  clickCloseInfoModal() {
    this.boardsingInfoModal.hide();
    if (this.is_change_media) {
      this.getBoardsign();
    }
  }

  clickOpenChangeMedia: boolean = false;
  board_select_list = [];
  openChangeMediaModal(boardsign_id) {
    this.clickOpenChangeMedia = true;
    this.board_select_list = [boardsign_id];
  }
  is_change_media = null;
  async closeChangeMediaModal(e) {
    if (this.is_change_media || e) {
      this.is_change_media = true;
    } else {
      this.is_change_media = false;
    }
    if (e) {
      const res = await this.boardsignService.getBoardsign(null, null, null, null, null, null, null, null, null, null, this.board_select_list).toPromise().catch(
        err => {
          console.log(JSON.stringify(err.statusText));
        }
      );
      if (res) {
        if (res.code == 1) {
          let list = await this.genSignsLayerView(res.data.data, false);
          if (list.length > 0)
            this.boardsignInfo = list[0];

        }
      }
    }
    this.clickOpenChangeMedia = false;
    this.board_select_list = [];
  }

  //------------------------------------------------------------//
  //------------------------------------------------------------------------------//
  is_toggle_camera_rtsp: boolean = false;
  is_toggle_camera_flv: boolean = false;
  is_toggle_camera_img: boolean = false;
  camera_rtsp_item: any;
  camera_flv_item: any;
  camera_img_item: any;
  station_item: any;
  clickCameraRtsp(item, station) {
    this.camera_rtsp_item = item;
    this.station_item = station;
    this.is_toggle_camera_rtsp = true;
  }
  closeCameraRtsp(e) {
    this.is_toggle_camera_rtsp = false;
  }

  clickCameraFlv(item, station) {
    this.camera_flv_item = item;
    this.station_item = station;
    this.is_toggle_camera_flv = true;
  }
  closeCameraFlv(e) {
    this.is_toggle_camera_flv = false;
  }

  clickCameraImg(item, station) {
    this.camera_img_item = item;
    this.station_item = station;
    this.is_toggle_camera_img = true;
  }
  closeCameraImg(e) {
    this.is_toggle_camera_img = false;
  }
}
