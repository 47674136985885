import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import { ScheduleInfo, AddSchedule } from '../../models/sign-control/schedule';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  domain: any;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_sign_control;
  }
  getSchedule(st_date?: Date, en_date?: Date, boardsign_id?: number) {
    const uri = `schedule`;
    const url = `${this.domain}/${uri}`;
    let params = new HttpParams();
    if (!_.isNil(st_date)) {
      params = params.append('st_date', st_date.toISOString());
    }
    if (!_.isNil(en_date)) {
      params = params.append('en_date', en_date.toISOString());
    }
    if (!_.isNil(boardsign_id)) {
      params = params.append('boardsign_id', boardsign_id.toString());
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<ScheduleInfo[]>>(url, httpOptions);
    // const url1 = 'assets/data/schedule.json';
    // return this.http.get<ApiResponse<ScheduleInfo[]>>(url1);
  }
  addSchedule(req: AddSchedule): Observable<ApiResponse<any>> {
    const uri = `schedule`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
  }
  editSchedule(req: AddSchedule, schedule_id?: number): Observable<ApiResponse<any>> {
    const uri = `schedule/${schedule_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
  deleteSchedule(schedule_id: number): Observable<ApiResponse<any>> {
    const uri = `schedule/${schedule_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.delete<ApiResponse<any>>(url, httpOptions);
  }
  deleteScheduleEditStatus(req: AddSchedule, schedule_id: number): Observable<ApiResponse<any>> {
    req.status = StatusEnum.Delete;
    const uri = `schedule/${schedule_id}`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `${this.authService.token_type} ${this.authService.user_token}`
      })
    };
    return this.http.put<ApiResponse<any>>(url, req, httpOptions);
  }
}
