import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginResponse, UserProfile } from '../../models/user';
import { ApiResponse } from '../../models/common';
import { environment } from '../../../environments/environment';
import * as md5 from 'md5';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserProfile>;
  public currentUser: Observable<UserProfile>;
  public user_key: string = 'currentUserWebAppShw';
  public user_name_key: string = 'currentUserNameWebAppShw';
  public user_token_key: string = 'currentUserTokenWebAppShw';
  public user_permission_key: string = 'currentUserPermissionWebAppShw';
  public user_token: string;
  public user_permission: number[] = [];
  public user_info: UserProfile;
  public user_name: string;
  public token_type: string = "Bearer";
  domain: string;
  per1 = [1200, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 300, 301, 302, 303, 304, 400, 401, 402, 500, 501, 600, 601, 602, 700, 701, 800, 801, 802, 803, 804, 805, 806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 817, 818, 819, 820, 821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832, 833, 834, 835, 836, 837, 838, 839, 840, 841, 842, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1100, 1101, 1102, 1103, 1104, 1105, 1106, 100, 101, 102, 103, 120, 900, 901, 902, 903, 904, 905, 906, 907, 908, 909, 910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 921, 922, 923];
  constructor(private http: HttpClient) {
    this.domain = environment.api.domain_auth;
    //this.currentUserSubject = new BehaviorSubject<UserProfile>(JSON.parse(localStorage.getItem(this.user_key)));
    this.currentUserSubject = new BehaviorSubject<UserProfile>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    this.getUserInfo();
    this.getUserToken();
    this.getUserPermission();
    this.getUserName();

    const u = localStorage.getItem(this.user_key);
    const u_session = sessionStorage.getItem(this.user_key);
    let user = null;
    let user_session = null;
    if (u) {
      user = JSON.parse(u.toString());
    }
    if (u_session) {
      user_session = JSON.parse(u_session.toString());
    }
    if (user != null && user_session != null) {
      this.currentUserSubject.next(user);

    } else {
      this.currentUserSubject.next(null);
    }
  }

  public get currentUserValue(): UserProfile {
    return this.currentUserSubject.value;
  }
  getUserName(): void {
    const user_n = localStorage.getItem(this.user_name_key);
    //const user1 = sessionStorage.getItem(this.user_key);
    if (user_n) {
      //this.currentUser = JSON.parse(user);
      //console.log(JSON.parse(user));
      this.user_name = user_n;
    } else {
      this.user_name = null;
    }
  }
  getUserInfo(): void {
    const user = localStorage.getItem(this.user_key);
    //const user1 = sessionStorage.getItem(this.user_key);
    if (user) {
      //this.currentUser = JSON.parse(user);
      //console.log(JSON.parse(user));
      this.user_info = JSON.parse(user);
    } else {
      this.user_info = null;
    }
  }
  getUserToken(): void {
    const token = localStorage.getItem(this.user_token_key);
    if (token) {
      this.user_token = token;
    } else {
      this.user_token = null;
    }
  }
  getUserPermission(): void {
    const permission = localStorage.getItem(this.user_permission_key);
    if (permission) {
      this.user_permission = JSON.parse(permission);
    } else {
      this.user_permission = [];
    }
  }

  login(username: string, password: string) {
    const uri = `login`;
    const url = `${this.domain}/${uri}`;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let req = { username: username, password: md5(password) };

    return this.http.post<ApiResponse<LoginResponse>>(url, req, httpOptions)
      .pipe(map(res => {
        if (res) {
          if (res.code == 1) {
            // login successful if there's a jwt token in the response
            if (res.data.access_token && res.data.profile) {
              //res.data.permission = this.per1;
              res.data.profile.permission = res.data.permission;
              this.user_info = res.data.profile;
              this.user_token = res.data.access_token;
              this.user_permission = res.data.permission;
              this.user_name = username;
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem(this.user_name_key, username);
              localStorage.setItem(this.user_key, JSON.stringify(res.data.profile));
              localStorage.setItem(this.user_token_key, res.data.access_token);
              localStorage.setItem(this.user_permission_key, JSON.stringify(res.data.permission));
              sessionStorage.setItem(this.user_key, JSON.stringify(res.data.profile));
              sessionStorage.setItem(this.user_name_key, username);
              this.currentUserSubject.next(res.data.profile);
            } else {
              this.removeLocalStorage();
            }
          } else {
            this.removeLocalStorage();
          }
        } else {
          this.removeLocalStorage();
        }
        return res;
      }));
    // if (username == 'test' && password == '1234') {
    //   let user = new User();
    //   user.id = 1;
    //   user.firstName = 'Test1';
    //   user.lastName = 'Test2';
    //   user.username = 'test';
    //   user.password = '1234';
    //   user.token = this.generate_token(100);
    //   user.token_type = 'bearer';
    //   let res = new ApiResponse();
    //   res.code = 1;
    //   res.message = 'OK';
    //   res.data = user;
    //   // store user details and jwt token in local storage to keep user logged in between page refreshes
    //   localStorage.setItem(this.user_key, JSON.stringify(user));
    //   sessionStorage.setItem(this.user_key, JSON.stringify(user));
    //   this.currentUserSubject.next(user);
    //   return of(res);
    // } else {
    //   let res = new ApiResponse();
    //   res.code = 0;
    //   res.message = 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง';
    //   res.data = null;
    //   localStorage.removeItem(this.user_key);
    //   sessionStorage.removeItem(this.user_key);
    //   this.currentUserSubject.next(null);
    //   return of(res);
    // }

  }

  logout() {
    const uri = `logout`;
    const url = `${this.domain}/${uri}`;
    let req = {
      id: 0
    };
    if (this.user_info) {
      req = {
        id: this.user_info.id
      };
    }

    this.removeLocalStorage();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.user_token = null;
    this.user_info = null;
    this.user_permission = [];
    this.currentUserSubject.next(null);
    return this.http.post<ApiResponse<any>>(url, req, httpOptions);
    // // remove user from local storage to log user out
    // localStorage.removeItem(this.user_key);
    // sessionStorage.removeItem(this.user_key);

    // this.currentUserSubject.next(null);
    // let res = new ApiResponse();
    // res.code = 1;
    // res.message = 'OK';
    // res.data = null;
    // return of(res);
  }

  removeLocalStorage() {
    localStorage.removeItem(this.user_key);
    localStorage.removeItem(this.user_token_key);
    localStorage.removeItem(this.user_permission_key);
    sessionStorage.removeItem(this.user_key);
    localStorage.removeItem(this.user_name_key);
    sessionStorage.removeItem(this.user_name_key);
  }

  generate_token(length: number) {
    //edit the token allowed characters
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }
}
