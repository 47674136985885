import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { MediaType, ImageFilterType, SpecialTextType } from 'src/app//models/sign-control/enum';
import { MediaInfo } from 'src/app//models/sign-control/media';
import { MediaService } from 'src/app//services/sign-control/media.service';

@Component({
  selector: 'app-media-selector',
  templateUrl: './media-selector.component.html',
  styleUrls: ['./media-selector.component.scss']
})
export class MediaSelectorComponent implements OnInit, OnChanges {

  public mediaType = MediaType;
  specialTextType =SpecialTextType;
  isLoading = false;
  mediaList: MediaInfo[];
  selectedMedia: MediaInfo;
  totalItems: number;
  countItems: number;
  @Input() sign_height: number;
  @Input() sign_type_id: any;
  @Input() ratio: any;
  @Input() boardsign_id: any;
  @Input() sign_id: any;
  @Input() toggle: boolean;
  @Input() media_type: number; // 0=ภาพนิ่ง, 1=ภาพเคลื่อนไหว
  @Input() select_type: any; //select_type:null => media page, select_type:1 => change media, select_type:2 => playlist
  @Input() config_road: any;
  @Input() rain: any;
  @Input() is_board: any;
  @Output() onComponentClosed: EventEmitter<MediaInfo> = new EventEmitter<MediaInfo>();
  media_sign_width(ratio?: string, heigh?: number) {
    if (heigh) {
      const wh = ratio.split(':');
      const w = +wh[0];
      const h = +wh[1];
      const r = w / h;
      return heigh * r;
    } else {
      return 100;
    }
  }

  constructor(private mediaService: MediaService) {
  }

  ngOnInit() {
  }

  async load() {
    // const res = await this.mediaService.getMedia(0, 1).toPromise();
    // if (res.code == 1) {
    //   this.totalItems = res.data.totalItems;
    // }
    // const data = await this.mediaService.getMedia(0, this.totalItems).toPromise();
    // if (data.code == 1) {
    //   if (this._ratioType == "ALL") {
    //     this.mediaList = data.data.data.filter(x => x.ratio_type == this._ratioType);
    //   } else {
    //     this.mediaList = data.data.data;
    //   }
    //   this.countItems = this.mediaList.length;
    // }
  }
  async getMedia(skip?: number, take?: number, click?: number) {
    this.mediaList = [];

    let sign_type_id1 = null;
    if (this.sign_type_id != null && this.sign_type_id != 'null') {
      sign_type_id1 = this.sign_type_id;
    }
    let ratio1 = null
    if (this.ratio) {
      ratio1 = this.ratio;
    }
    let media_type1 = null
    if (this.media_type != null) {
      media_type1 = this.media_type;
    }
    let boardsign_id1 = null
    if (this.boardsign_id) {
      boardsign_id1 = this.boardsign_id;
    }
    let sign_id1 = null
    if (this.sign_id) {
      sign_id1 = this.sign_id;
    }
    let select_type1 = null
    if (this.select_type) {
      select_type1 = this.select_type;
    }
    this.mediaService.getImage(skip, take, null, ratio1, media_type1, null, sign_type_id1, 1, boardsign_id1, sign_id1, null, select_type1).subscribe(
      res => {
        if (res.code == 1) {
          this.mediaList = res.data.data;
        }
      },
      err => {
        console.log(JSON.stringify(err.statusText));
      });
  }
  select(media: MediaInfo) {
    this.selectedMedia = media;
  }

  closeComponent(media: MediaInfo) {
    if (media) {
      this.onComponentClosed.emit(media);
    }
    else {
      this.onComponentClosed.emit(null);
    }
    this.selectedMedia = null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.toggle){
      this.selectedMedia = null;
      this.getMedia();
    }
  }

  getSignNameTextStyles(w?: number) {
    let styles = {
      'width': w + 'px',
      'max-width': w + 'px',
      'white-space': 'nowrap',
      'overflow': 'hidden',
      'text-overflow': 'ellipsis'
    };
    return styles;
  }

}
