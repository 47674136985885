<div class="modal-header">
  <h4 class="modal-title pull-left">กลุ่มผู้ใช้งาน</h4>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body text-center">
  <div class="row">
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <p class="input-group-text">ชื่อกลุ่มผู้ใช้งาน <span class="red-txt"> *</span></p>
        </div>
        <input type="text" class="form-control" [(ngModel)]="name">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">หน่วยงาน</span>
        </div>
        <input [typeahead]="ins_list" [(ngModel)]="ins_name" typeaheadOptionField="name" (focusout)="blur($event)"
          (typeaheadOnSelect)="onSelect($event)" class="form-control">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">เบอร์โทร</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="tel">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Email</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="email">
      </div>
    </div>
    <div class="col-6">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">Hashtag</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="hashtag">
      </div>
    </div>
    <div *ngIf="values" class="col-12 text-left mb-3 pt-2 pb-2 d-flex justify-content-between dgray-BG">
      <p class="m-0" style="line-height: 2.5;">
        <i class="fa fa-bars" aria-hidden="true"></i> ผู้ใช้งาน
      </p>
      <button *ngIf="isAddUser" class="btn btn-info" (click)="addUser()">เพิ่มผู้ใช้งาน</button>
    </div>
    <div *ngIf="values" class="col-12">
      <table class="table-main table mb-0">
        <thead class="thead-dark" style="background-color: black;">
          <tr>
            <th class="text-center text-light table-header"><i class="fa fa-hashtag"></i></th>
            <th class="text-center text-light table-header">ชื่อผู้ใช้งาน</th>
            <th class="text-center text-light table-header">หน่วยงาน</th>
            <th class="text-center text-light table-header">Create date</th>
            <th class="text-center text-light table-header">Action</th>
          </tr>
        </thead>
        <tbody>

          <tr *ngIf="!list || list?.length  < 1">
            <td colspan="10" style="height: 50px;">
              ไม่พบข้อมูล
            </td>
          </tr>

          <tr *ngFor="let i of list | slice: (currentPage-1) * itemsPerPage : (currentPage-1) * itemsPerPage + itemsPerPage;index as idx">
            <td>
                {{(idx+1)+(itemsPerPage*(currentPage-1))}}
            </td>
            <td>{{i.firstname}} {{i.lastname}}</td>
            <td>{{i.ins_name}}</td>
            <td>{{i.create_datetime | date:'dd/MM/yyyy HH:mm:ss':'+0700'}}</td>
            <td>
                <button class="ml-1 mr-1 btn btn-danger" (click)="rmUserGroup(i.user_id)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </button>
            </td>
          </tr>
          
          <tr>
            <td colspan="12" class="table-footer">
              <div class="table-footer-inside d-flex align-items-center justify-content-between">
                <div class="ml-3 pt-3">

                    <pagination [(ngModel)]="currentPage" [totalItems]="list.length" [itemsPerPage]="itemsPerPage" (pageChanged)="pageChanged($event)"
                    previousText="&lsaquo;" nextText="&rsaquo;" [boundaryLinks]="true" firstText="&laquo;"
                    lastText="&raquo;">></pagination>

                </div>
                <div></div>
                <div class="mr-3 ml-2">total: {{list.length||0}}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
  </div>
  
  <div *ngIf="values" class="col-12 text-left mt-3 pt-2 pb-2 d-flex justify-content-between dgray-BG">
    <p class="m-0" style="line-height: 2.5;">
      <i class="fa fa-bars" aria-hidden="true"></i> Permission
    </p>
  </div>
  <div *ngIf="values" class="col-12 mt-3">
    <div class="box-BG">
      <ngx-treeview [config]="config" [items]="items_menu" (filterChange)="onFilterChange($event)" (selectedChange)="onSelectedChange($event);values_tree = $event">
      </ngx-treeview>
      <button class="btn btn-info mt-3" (click)="save_per()">
        ยืนยัน Permission
      </button>
      
    </div>
  </div>
  <div *ngIf="values && false" class="col-6 mt-3">
    <div class="box-BG">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">ทางหลวง</span>
        </div>
        
        <input [typeahead]="route_list" [(ngModel)]="route_name" typeaheadOptionField="name" (focusout)="blur($event)"
          (typeaheadOnSelect)="onSelectR($event)" class="form-control">
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text">หลักกิโลเมตร</span>
        </div>
        <input type="text" class="form-control" [(ngModel)]="s_km">
        <!-- <div class="input-group-prepend"> -->
          <span class="input-group-text br-0"> - </span>
        <!-- </div> -->
        <input type="text" class="form-control" [(ngModel)]="e_km">
        
        <div class="input-group-append">
          <button class="btn btn-info" (click)="s_route()">
            ค้นหา
          </button>
        </div>
      </div>
      <ngx-treeview [config]="configDevice" [items]="items_device" (filterChange)="onFilterChange($event)" (selectedChange)="onSelectedChange($event);values_device = $event">
      </ngx-treeview>
      <button class="btn btn-info mt-3" (click)="save_device()">
        ยืนยัน Device
      </button>
      
    </div>
  </div>
</div>
</div>
<div class="modal-footer">
  <!-- <button type="button" class="btn btn-primary btn-sm" (click)="config.animated = !config.animated">{{config.animated ? 'Disable' : 'Enable'}} animation</button> -->
  <button type="button" class="btn btn-danger" (click)="close()">ปิด</button>
  <!-- <button type="button" class="btn btn-danger" (click)="cancel()">No</button> -->
  <button type="button" class="btn btn-info" (click)="save()" [disabled]="(!name) ? 'disabled': null">ยืนยัน</button>
</div>