import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse, PagableData } from '../../models/common';
import { BoardsignInfo, AddBoardsign, ReqAddSign, Structure, ReqAutoSuggestion } from '../../models/sign-control/boardsign';
import { AtisInfo, ReqAddAtis } from '../../models/sign-control/atis';
import { ReversibleInfo } from '../../models/corridor-control/graph';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { StatusEnum } from '../../models/common-enum';

@Injectable({
  providedIn: 'root'
})
export class CorridorControlService {

  domain: any;
  t = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjIjoiNWIwZTQzODM0NTc3OTQxOTAxMWU2Yzk1IiwicyI6WyJUTUMiXSwiciI6Ikh3SnVpNkRmdUxEZ05TM1lyUWZtNnZIV2hqWU1qeE02VjFtTDFCV0JvbWVTIiwiaWF0IjoxNjA3OTM0MDgwfQ.9MhE1LIdqTx1xiG6lspi_QOWF6YP7EiDhQ6v3y9KOu8`;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.domain = environment.api.domain_sign_control;
  }
  getReversibleTimeseries(reversible_id?: number, pcu?: number): Observable<ApiResponse<ReversibleInfo>> {
    let do_main = "http://api.lms-m7.transcode.co.th/report"
    let uri = `dashboard/reversible/timeline`;
    const url = `${do_main}/${uri}`;
    let para = [];
    let params = new HttpParams();

    if (!_.isNil(reversible_id)) {
      params = params.append('reversible_id', reversible_id.toString());
      para.push('reversible_id=' + reversible_id.toString())
    }
    if (pcu && !_.isNil(pcu)) {
      params = params.append('pcu', pcu.toString());
      para.push('pcu=' + pcu.toString())
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.t}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<ReversibleInfo>>(url, httpOptions);
  }
  getImgEvt(): Observable<ApiResponse<any>> {
    let do_main = "http://api.lms-m7.transcode.co.th/report/"
    let uri = `smc/enforcement/image/top`;
    const url = `${do_main}/${uri}`;
    let para = [];
    let params = new HttpParams();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.t}`
      }),
      params: params
    };
    return this.http.get<ApiResponse<any>>(url, httpOptions);
  }
  
  async getCorridorImgEvt(){
    return new Promise((reslove,reject)=>{
      this.getImgEvt().subscribe(
        res => {
          if(res.code==1) {
            const result = res.data.map(x => x.replace('https','http'));
            return reslove(result);
          }
          else reslove(false)
        },
        err => {
          console.log(JSON.stringify(err.statusText));
          return reject(err.statusText);
      });
    })
  }
}
