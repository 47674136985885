export const mainRouteUrl = "assets-management";
export const id = 800;
export const menuItems = [
    {
        id: 801,
        name: 'Monitor',
        url: `/${mainRouteUrl}/monitor`,
        icon: 'icon-speedometer',
        parent_id: id
    },
    {
        id: 817,
        name: 'Station',
        url: `/${mainRouteUrl}/station`,
        icon: 'icon-home',
        parent_id: id
    },
    {
        id: 820,
        name: 'กลุ่มอุปกรณ์',
        url: `/${mainRouteUrl}/device-group`,
        icon: 'fa fa-cubes',
        parent_id: id
    },
    {
        id: 823,
        name: 'ประเภทอุปกรณ์',
        url: `/${mainRouteUrl}/device-type`,
        icon: 'fa fa-cube',
        parent_id: id
    },
    {
        id: 826,
        name: 'Vendor',
        url: `/${mainRouteUrl}/vendor`,
        icon: 'fa fa-building',
        parent_id: id
    },
    {
        id: 831,
        name: 'Owner',
        url: `/${mainRouteUrl}/owner`,
        icon: 'fa fa-building',
        parent_id: id
    },
    {
        id: 834,
        name: 'อุปกรณ์',
        url: `/${mainRouteUrl}/device`,
        icon: 'fa fa-cog',
        parent_id: id
    },
    {
        id: 837,
        name: 'ยี่ห้อ/รุ่น',
        url: `/${mainRouteUrl}/brand`,
        icon: 'icon-shield',
        parent_id: id
    },
    {
        id: 840,
        name: 'แจ้งซ่อม',
        url: `/${mainRouteUrl}/ma`,
        icon: 'icon-wrench',
        parent_id: id
    }
];

export const permission = {
    monitor: {
        hardware_id: 802,
        hardware: {
            station_id: 803,
            desc_id: 804,
            desc: {
                desc_devcie_id: 805,
                desc_ma_id: 806,
                add_ma_id: 807,
                ma_log_id: 808,
                add_ma_log_id: 809
            }
        },
        software_id: 810,
        software: {
            desc_id: 811,
            desc: {
                desc_devcie_id: 812,
                desc_ma_id: 813,
                add_ma_id: 814,
                ma_log_id: 815,
                add_ma_log_id: 816
            }
        }
    },
    station: {
        add_id: 818,
        edit_id: 819
    },
    device_group: {
        add_id: 821,
        edit_id: 822
    },
    device_type: {
        add_id: 824,
        edit_id: 825
    },
    vendor: {
        add_id: 827,
        edit_id: 828,
    },
    vendor_contact: {
        add_id: 829,
        edit_id: 830,
    },
    owner: {
        add_id: 832,
        edit_id: 833,
    },
    device: {
        add_id: 835,
        edit_id: 836,
    },
    brand: {
        add_id: 838,
        edit_id: 839,
    },
    ma: {
        add_id: 841,
        edit_id: 842,
    }
};